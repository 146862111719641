import React from 'react';
import { useSelector } from 'react-redux';
import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './pages/HomePage';
import { Outlet } from 'react-router-dom';
import CustomModal from './components/CustomModal';
import Login from './pages/Login';

const Layout = () => {
  const loggedIn = useSelector((state) => state);
  console.log(loggedIn, 'abhishek');

  return (
    <div className="h-auto w-screen relative z-0">
      <Header />
      <div className="mx-auto w-full py-4">
        {/* Conditional rendering of Login component */}
        {!loggedIn?.user?.loggedIn && <Login />}
        <Outlet />
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
