import React, { useEffect, useState } from 'react';
import { FaStar } from 'react-icons/fa';
import { AddReview, Reviews } from '../../apis/api';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { setLoginPopup } from '../../redux/slice/userSlice';

const RatingData = ({ item }) => {
  const [reviewData, setReviewData] = useState([]);
  const [selectedRating, setSelectedRating] = useState(0);
  const [hoverRating, setHoverRating] = useState(0);
  const [reviewDescription, setReviewDescription] = useState('');
  const [reviewPhotos, setReviewPhotos] = useState([]);
  const [showReviewForm, setShowReviewForm] = useState(false);
  const loggedIn = useSelector((state) => state.user.loggedIn);
  const dispatch = useDispatch();

  const handleReviewData = async () => {
    const body = {
      ID: item?.ID,
      USER_ID: item?.USER_ID,
    };
    const res = await Reviews(body);
    console.log(res);
    setReviewData(res);
  };

  useEffect(() => {
    handleReviewData();
  }, []);

  const handleStarClick = (rating) => {
    setSelectedRating(rating);
    setShowReviewForm(true);
  };

  const handleStarHover = (rating) => {
    setHoverRating(rating);
  };

  const handleStarHoverOut = () => {
    setHoverRating(0);
  };

  const handleReviewDescriptionChange = (event) => {
    setReviewDescription(event.target.value);
  };

  const handleReviewPhotosChange = (event) => {
    setReviewPhotos((pre) => pre.concat(event.target.files));
  };
  console.log(reviewPhotos);
  const handleSendReview = async () => {
    if (!loggedIn) {
      dispatch(setLoginPopup(true));
      return;
    }
    const form = new FormData();
    form.append('ID', item?.ID);
    form.append('RATING', selectedRating);
    form.append('COMMENT', reviewDescription);
   
      reviewPhotos.forEach((photo) => {
        form.append('PHOTOS', photo);
      });
    

    const res = await AddReview(form);
    if (res.success) {
      toast.success(res.message);
    } else {
      toast.error(res.errors[0]);
    }
    await handleReviewData();
    setSelectedRating(0);
    setReviewDescription('');
    setReviewPhotos([]);
    setShowReviewForm(false);
  };

  return (
    <>
      {true && (
        <div className="border-t p-4 py-6">
          <p className="text-xl font-[500]">Ratings & Reviews </p>
          <div className="mt-4 flex items-center gap-4">
            <div className="antialiased tracking-tight font-poppins tap-highlight-transparent leading-tight text-white font-medium text-[24px] inline-flex justify-center items-center w-[52px] h-[52px] rounded-[15px] bg-[#339d3a] flex-none">
              {reviewData?.TOTAL_RATING_AVERAGE
                ? reviewData?.TOTAL_RATING_AVERAGE
                : '0'}
            </div>
            <div>
              <div className="antialiased tracking-tight font-poppins tap-highlight-transparent leading-tight text-gray-900 font-semibold text-[22px]">
                {reviewData?.REVIEW_INFO?.length
                  ? reviewData?.REVIEW_INFO?.length
                  : '0'}{' '}
                Reviews
              </div>
              <div className="antialiased tracking-tight font-poppins tap-highlight-transparent leading-tight text-gray-600 text-[15px] mt-2">
                My Call Book Reviews index based on{' '}
                {reviewData?.REVIEW_INFO?.length
                  ? reviewData?.REVIEW_INFO?.length
                  : '0'}{' '}
                review across the web
              </div>
            </div>
          </div>
          <div className="mt-[100px]">
            <div className="antialiased tracking-tight font-poppins tap-highlight-transparent text-gray-900 font-medium text-[20px]">
              Start your Review
            </div>
            <div className="antialiased tracking-tight font-poppins tap-highlight-transparent text-11 outline-none box-border mt-2 inline-flex items-center w-full">
              <div className="relative cursor-pointer flex gap-2">
                {[...Array(5)].map((_, index) => (
                  <FaStar
                    key={index}
                    size={40}
                    color={
                      (hoverRating || selectedRating) > index
                        ? '#FFD700'
                        : '#D3D3D3'
                    }
                    onClick={() => handleStarClick(index + 1)}
                    onMouseEnter={() => handleStarHover(index + 1)}
                    onMouseLeave={handleStarHoverOut}
                  />
                ))}
              </div>
            </div>
            {showReviewForm && (
              <div className="mt-4">
                <textarea
                  className="w-full p-2 border rounded-md"
                  rows="4"
                  placeholder="Write your review here..."
                  value={reviewDescription}
                  onChange={handleReviewDescriptionChange}
                ></textarea>
                <input
                  type="file"
                  multiple
                  className="mt-2 w-full p-2 border rounded-md"
                  onChange={handleReviewPhotosChange}
                />
                <button
                  className="mt-2 px-4 py-2 bg-blue-500 text-white rounded-md"
                  onClick={handleSendReview}
                >
                  Send Review
                </button>
              </div>
            )}
          </div>
          {/* <div className="mt-10">
            <div className="antialiased tracking-tight font-poppins tap-highlight-transparent text-gray-900 font-medium text-[20px]">
              User Reviews
            </div>
            <div className="flex gap-2 mt-3">
              <button className="antialiased tracking-tight font-poppins tap-highlight-transparent list-none m-0 p-0 bg-transparent font-normal text-[14px] inline-flex justify-center items-center h-[35px] rounded-[4px] px-4 cursor-pointer mr-2 transition-all duration-800 bg-[#e5f1fb] text-[#006ac1] border border-[rgba(188,213,235,0.5)] active">
                Relevant
              </button>
              <button className="antialiased tracking-tight font-poppins tap-highlight-transparent list-none m-0 p-0 bg-transparent font-normal text-[14px] inline-flex justify-center items-center h-[35px] rounded-[4px] px-4 cursor-pointer mr-2 transition-all duration-800 bg-[#e5f1fb] text-[#006ac1] border border-[rgba(188,213,235,0.5)] active">
                Latest
              </button>
              <button className="antialiased tracking-tight font-poppins tap-highlight-transparent list-none m-0 p-0 bg-transparent font-normal text-[14px] inline-flex justify-center items-center h-[35px] rounded-[4px] px-4 cursor-pointer mr-2 transition-all duration-800 bg-[#e5f1fb] text-[#006ac1] border border-[rgba(188,213,235,0.5)] active">
                High to Low
              </button>
            </div>
          </div> */}
        </div>
      )}
    </>
  );
};

export default RatingData;
