// components/Provider/ImageGalleryModal.js
import React, { useState } from 'react';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';

const ImageGalleryModal = ({ open, onClose, images }) => {
  const [selectedImage, setSelectedImage] = useState(images[0]?.path || '');

  return (
    <Modal
      open={open}
      onClose={onClose}
      center
      classNames={{
        overlay:
          'fixed inset-0 bg-black bg-opacity-70 flex justify-center items-center',
        modal:
          'bg-white max-w-[90%] sm:max-w-[300px] md:max-w-[500px] rounded-lg p-4 md:p-6 w-full shadow-xl',
      }}
      showCloseIcon={false}
    >
      <div className="flex flex-col md:flex-row max-w-full max-h-[70vh] md:max-h-[500px] gap-2 md:gap-4">
        {selectedImage && (
          <div className="relative flex-1 md:flex-[2]">
            <img
              className="max-h-[40vh] md:max-h-[500px] w-full rounded-lg object-cover"
              src={`${process.env.REACT_APP_IMAGE_URL}${selectedImage}`}
              alt="Selected"
            />
            <button
              className="absolute top-2 right-2 bg-white bg-opacity-70 p-2 rounded-full hover:bg-opacity-100"
              onClick={onClose}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="currentColor"
                className="w-6 h-6 text-gray-700"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        )}
        <div className="flex min-w-[100px] md:min-w-[200px] max-h-[20vh] md:max-h-full flex-col gap-2 overflow-x-hidden no_scroll overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300">
          {images.map((img, index) => (
            <img
              key={index}
              className={`h-auto md:h-24 max-w-[200px] object-cover cursor-pointer rounded-lg transition-transform duration-200 transform hover:scale-105 ${
                selectedImage === img.path ? 'border-2 border-blue-500' : ''
              }`}
              src={`${process.env.REACT_APP_IMAGE_URL}${img.path}`}
              alt={`Thumbnail ${index}`}
              onClick={() => setSelectedImage(img.path)}
            />
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default ImageGalleryModal;
