import React, { useEffect, useState } from 'react';
import HomeCategory from '../components/HomeCategory';
import HomeSubCatWithCat from '../components/HomeSubCatWithCat';
import Sliders from '../components/Sliders';
import { store } from '../redux/store';
import { fetchSliderImg } from '../redux/slice/dataSlice';
import { useSelector } from 'react-redux';
import CategoryCard from '../components/CategoryCard';
import { setLoginPopup } from '../redux/slice/userSlice';
import AuthPopup from '../components/AuthPopup';
import { Searcher } from '../apis/api';
import useLoader from '../hooks/useLoader';

const HomePage = () => {
  const [showPopup, setShowPopup] = useState(false);
  const { slider_img_list, cat_list } = useSelector((state) => state.alldata);
  const loggedIn = useSelector((state) => state.user.loggedIn);
  const userData = useSelector((state) => state.user.userData);
  const [trending,setTrending]=useState([]);
  const setLoading = useLoader();
  console.log(localStorage.getItem('token'));

  const handleSearch = async (query = '') => {
    const results = await Searcher(query, loggedIn, userData?.USER_ID);
    console.log(results)
    setTrending(results?.trending_searches);
  };

  useEffect(() => {
    setLoading(false);
    if (!loggedIn) {
      const timer = setTimeout(() => {
        setShowPopup(true);
        if (!loggedIn) store.dispatch(setLoginPopup(true));
      }, 15000); // 15 seconds
      return () => clearTimeout(timer); // Cleanup the timer on unmount
    }
    handleSearch();
  }, [loggedIn]);

  const banner_top = slider_img_list?.filter(
    (v) => v.PAGE_NAME === 'Home Top'
  )[0]?.DATA_BASED_ON_PAGE_NAME;

  const banner_bottom = slider_img_list?.filter(
    (v) => v.PAGE_NAME === 'Home Bottom'
  )[0]?.DATA_BASED_ON_PAGE_NAME;

  useEffect(() => {
    store.dispatch(fetchSliderImg());
  }, []);

  return (
    <div>
      {showPopup && !loggedIn && <AuthPopup />}
      <p className="font-sans text-2xl font-semibold pb-3 text-[#0474ED] px-4 md:px-10">
        Popular Categories
      </p>
      <div className="w-[calc(100vw-20px)] md:w-[calc(100vw-40px)] items-center flex px-4 pr-0 md:pl-10 min-h-fit overflow-hidden">
        <div className="flex gap-4 no_scroll overflow-x-auto overflow-y-hidden w-full pt-4 flex-nowrap px-4 !pl-0 md:px-10">
          {cat_list?.map((item, index) => (
            <CategoryCard key={index} item={item} />
          ))}
        </div>
      </div>
      <div className="px-4 md:px-10">
        <Sliders list={banner_top} list2={banner_bottom} />
        <p className="font-sans text-2xl font-semibold py-3 text-[#0474ED]">
          All Categories
        </p>
        <HomeCategory />
      </div>
      <div className="bg-[#57A7FF] w-full px-4 md:px-16 py-6 mt-10">
        <HomeSubCatWithCat />
      </div>
    </div>
  );
};

export default HomePage;
