import { useState } from 'react';
import {
  FaPhone,
  FaEnvelope,
  FaUser,
  FaCommentDots,
  FaFileUpload,
} from 'react-icons/fa';
import { Contact } from '../apis/api';
import Button from '../components/Button';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    mobile: '',
    query: '',
    file: null,
  });
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData({ ...formData, [name]: files ? files[0] : value });
  };

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const validateMobile = (mobile) => {
    const re = /^[0-9]{10,15}$/;
    return re.test(mobile);
  };

  const handleSubmit = async (e) => {
    console.log('abhisehk');
    e.preventDefault();
    console.log('Form submitted');

    if (!validateEmail(formData.email)) {
      setError('Please enter a valid email address.');
      return;
    }

    if (!validateMobile(formData.mobile)) {
      setError('Please enter a valid mobile number.');
      return;
    }

    const body = new FormData();
    body.append('FULL_NAME', formData.fullName);
    body.append('EMAIL_ADDRESS', formData.email);
    body.append('MOBILE', formData.mobile);
    body.append('DESCRIPTION', formData.query);
    body.append('FILE', formData.file);

    setIsLoading(true);
    setError(null);
    setSuccess(null);

    try {
      console.log('Sending API request');
      const res = await Contact(body);
      console.log('API response received', res);

      if (res.success) {
        setSuccess('Your query has been submitted successfully!');
      } else {
        setError('There was an issue submitting your query.');
      }
    } catch (error) {
      console.error('Error:', error);
      setError(
        'An error occurred while submitting your query. Please try again later.'
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="max-w-lg mx-auto p-8 bg-white min-w-[350px] w-[40%] shadow-lg rounded-lg">
      <h2 className="text-3xl font-bold mb-6 text-center text-gray-800">
        Contact Us
      </h2>
      {error && <div className="text-red-500 text-center mb-4">{error}</div>}
      {success && (
        <div className="text-green-500 text-center mb-4">{success}</div>
      )}
      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="relative flex items-center">
          <FaUser className="absolute top-4 left-3 text-gray-400" />
          <input
            type="text"
            id="fullName"
            name="fullName"
            value={formData.fullName}
            onChange={handleChange}
            className="w-full pl-10 pr-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            placeholder="Full Name"
            required
          />
        </div>
        <div className="relative flex items-center">
          <FaEnvelope className="absolute top-4 left-3 text-gray-400" />
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="w-full pl-10 pr-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            placeholder="Email"
            required
          />
        </div>
        <div className="relative flex items-center">
          <FaPhone className="absolute top-4 left-3 text-gray-400" />
          <input
            type="tel"
            id="mobile"
            name="mobile"
            value={formData.mobile + 'sdkf'}
            onChange={handleChange}
            className="w-full pl-10 pr-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            placeholder="Mobile"
            required
          />
        </div>
        <div className="relative flex items-center">
          <FaCommentDots className="absolute top-4 left-3 text-gray-400" />
          <textarea
            id="query"
            name="query"
            value={formData.query}
            onChange={handleChange}
            className="w-full pl-10 pr-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            rows="4"
            placeholder="Your Query"
            required
          ></textarea>
        </div>
        <div className="relative flex items-center">
          <FaFileUpload className="absolute top-4 left-3 text-gray-400" />
          <input
            type="file"
            id="file"
            name="file"
            onChange={handleChange}
            accept=".jpg,.jpeg,.png,.pdf"
            className="w-full pl-10 pr-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          />
        </div>
        <Button
          onClick={() => {
            handleSubmit();
            console.log('abhisehk');
          }}
          title={isLoading ? 'Submitting...' : 'Submit'}
        />
      </form>
    </div>
  );
};

export default ContactForm;
