import { Image } from 'antd';
import React from 'react';

const ProfileCard = ({ data, setIsModalOpen }) => {
  return (
    <div className="bg-white rounded-lg shadow p-5">
      <div className="text-center">
        <Image
          src={
            data?.IMAGE_URL
              ? `${process.env.REACT_APP_IMAGE_URL}${data?.IMAGE_URL}`
              : 'https://bootdey.com/img/Content/avatar/avatar7.png'
          }
          alt="Admin"
          height={128}
          width={128}
          className="rounded-[50%] w-32 h-32 mx-auto"
        />
        <div className="mt-3">
          <h4>{data?.FULL_NAME}</h4>
          <p className="text-gray-600 mb-1">Full Stack Developer</p>
          <p className="text-gray-500 text-sm">Bay Area, San Francisco, CA</p>
        </div>
        <button
          onClick={() => setIsModalOpen(true)}
          className="btn btn-primary bg-blue-500 hover:bg-blue-700 text-white px-4 py-2 rounded mt-2"
        >
          Update Profile Image
        </button>
      </div>
    </div>
  );
};

export default ProfileCard;
