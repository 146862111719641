import React, { useState } from 'react';
import logo from '../assets/images/logo.png';
import InputBox from '../components/InputBox';
import Button from '../components/Button';
import { IoClose } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import {
  login,
  signup,
  forgotPassword,
  setLoginPopup,
} from '../redux/slice/userSlice';
import { OtpOnEmail } from '../apis/api';
import toast from 'react-hot-toast';

const Login = () => {
  const { login_popup } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const [isSignup, setIsSignup] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [signupActivated, setSignupActivated] = useState(false);
  const [otpDetails, setOtpDetails] = useState({ OTP_ID: '', OTP: '' });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({ ...prev, [name]: value }));
  };

  const handleLogin =  () => {
     dispatch(login({ PHONE_NUMBER: data.user, PASSWORD: data.pass }));
  };

  const handleSignup = () => {
    dispatch(
      signup({
        FULL_NAME: data.name,
        PHONE_NUMBER: data.mobile,
        EMAIL_ADDRESS: data.email,
        PASSWORD: data.pass,
        OTP_ID: otpDetails?.OTP_ID,
        OTP: otpDetails?.OTP,
        GENDER: 'MALE',
      })
    );
  };

  const verifyEmail = async () => {
    const res = await OtpOnEmail({
      FULL_NAME: data.name,
      PHONE_NUMBER: data.mobile,
      EMAIL_ADDRESS: data.email,
      PASSWORD: data.pass,
      OTP_ID: otpDetails?.OTP_ID,
      OTP: otpDetails?.OTP,
      GENDER: 'MALE',
    });
    console.log(res);
    if (res.success) {
      setSignupActivated(true);
      setOtpDetails({
        ...otpDetails,
        OTP_ID: res.OTP_ID,
      });
    } else toast.error(res.message);
    // setOtpDetails({ OTP_ID: res.OTP_ID });
  };

  const handleForgotPassword = () => {
    toast.success(
      'reset password has been sent to your email if it is a valid email.'
    );
    dispatch(forgotPassword(data.email));
  };

  const toggleSignup = () => {
    setIsSignup((prev) => !prev);
    setIsForgotPassword(false);
    setData({});
    setSignupActivated(false);
  };

  const toggleForgotPassword = () => {
    setIsForgotPassword((prev) => !prev);
    setIsSignup(false);
  };

  const renderSignupForm = () => (
    <div className="w-full flex flex-col gap-1">
      <InputBox
        value={data.name}
        name={'name'}
        placeholder={'Enter Your Full Name'}
        label={'Full Name'}
        onChange={handleChange}
      />
      <InputBox
        value={data.email}
        name={'email'}
        placeholder={'Enter Your Email Address'}
        label={'Email Address'}
        onChange={handleChange}
      />
      <InputBox
        value={data.mobile}
        name={'mobile'}
        placeholder={'Enter Your Mobile Number'}
        label={'Mobile Number'}
        onChange={handleChange}
      />
      <InputBox
        isPassword
        value={data.pass}
        name={'pass'}
        placeholder={'Enter Your Password'}
        label={'Password'}
        onChange={handleChange}
      />
      <InputBox
        isPassword
        value={data.c_pass}
        name={'c_pass'}
        placeholder={'Enter Your Confirm Password'}
        label={'Confirm Password'}
        onChange={handleChange}
      />
      <div className="flex justify-center">
        <Button title={'Sign Up'} onClick={verifyEmail} />
      </div>
      <p className="text-center">
        Already have an account?{' '}
        <span className="cursor-pointer text-[#0474ED]" onClick={toggleSignup}>
          Sign In
        </span>
      </p>
    </div>
  );

  const renderForgotPasswordForm = () => (
    <div className="w-full flex flex-col gap-3">
      <InputBox
        value={data.email}
        name={'email'}
        placeholder={'Enter Your Email Address'}
        label={'Email Address'}
        onChange={handleChange}
      />
      <div className="flex justify-center">
        <Button title={'Reset Password'} onClick={handleForgotPassword} />
      </div>
      <p className="text-center">
        Remember your password?{' '}
        <span
          className="cursor-pointer text-[#0474ED]"
          onClick={toggleForgotPassword}
        >
          Sign In
        </span>
      </p>
    </div>
  );

  const renderOtpVerificationForm = () => (
    <div className="w-full flex flex-col gap-3">
      <InputBox
        value={otpDetails.OTP}
        name={'otp'}
        placeholder={'Enter OTP'}
        label={'OTP'}
        onChange={(e) =>
          setOtpDetails({
            ...otpDetails,
            OTP: e.target.value,
          })
        }
      />
      <div className="flex justify-center mt-2">
        <Button title={'Verify Email'} onClick={handleSignup} />
      </div>
      <p className="text-center">
        Already have an account?{' '}
        <span className="cursor-pointer text-[#0474ED]" onClick={toggleSignup}>
          Login
        </span>
      </p>
    </div>
  );

  const renderLoginForm = () => (
    <div className="w-full flex flex-col gap-3">
      <InputBox
        value={data.user}
        name={'user'}
        placeholder={'Enter Your Email/Mobile Number'}
        label={'Email/Mobile Number'}
        onChange={handleChange}
      />
      <InputBox
        isPassword
        value={data.pass}
        name={'pass'}
        placeholder={'Enter Your Password'}
        label={'Password'}
        onChange={handleChange}
      />
      <div
        className="cursor-pointer text-[#0474ED] self-end"
        onClick={toggleForgotPassword}
      >
        Forgot Password?
      </div>
      <div className="flex justify-center">
        <Button title={'Login'} onClick={handleLogin} />
      </div>
      <p className="text-center">
        Don`t have an account?{' '}
        <span className="cursor-pointer text-[#0474ED]" onClick={toggleSignup}>
          Sign Up
        </span>
      </p>
    </div>
  );

  return (
    login_popup && (
      <div className="fixed inset-0 w-full h-full z-20 bg-black bg-opacity-50 overflow-y-auto backdrop-blur-[15px] flex items-center justify-center">
        <div className="text-black bg-white p-4 rounded-2xl max-w-[90vw] w-[350px] flex flex-col items-center justify-center relative">
          <IoClose
            size={30}
            onClick={() => dispatch(setLoginPopup(false))}
            className="absolute top-5 right-5 cursor-pointer hover:scale-[1.1] transform transition-transform duration-300"
          />
          <img
            src={logo}
            className="h-[30%] mb-4 rounded-lg w-[30%] object-contain"
          />
          <h1 className="text-2xl font-semibold text-center">
            {isSignup
              ? 'Create New Account'
              : isForgotPassword
                ? 'Forgot Password'
                : 'Welcome Back'}
          </h1>
          <p className="mt-4 text-center">
            {isSignup
              ? 'Sign up'
              : isForgotPassword
                ? 'Reset Password'
                : 'Sign in'}
          </p>
          {isSignup && !signupActivated && renderSignupForm()}
          {isForgotPassword && renderForgotPasswordForm()}
          {isSignup && signupActivated && renderOtpVerificationForm()}
          {!isSignup && !isForgotPassword && renderLoginForm()}
        </div>
      </div>
    )
  );
};

export default Login;
