import React, { useEffect, useState } from 'react';
import { Reviews } from '../../apis/api';
import user from '../../assets/images/user1.png';
import useLoader from '../../hooks/useLoader';
import { useDispatch, useSelector } from 'react-redux';
import { Image } from 'antd';

const ReviewData = ({ item }) => {
  const [reviewData, setReviewData] = useState([]);
  const setLoading = useLoader();

  console.log(item)

  const handleReviewData = async () => {
    const body = {
      ID: item?.ID,
      USER_ID: item?.USER_ID,
    };
    setLoading(true);
    const res = await Reviews(body);
    console.log(res);
    setLoading(false);
    setReviewData(res);
  };

  useEffect(() => {
    handleReviewData();
  }, []);

  return (
    <div>
      <div className="mt-10 flex flex-col gap-6 ">
        {reviewData?.REVIEW_INFO?.map((review, i) => (
          <div key={i} className="border-t flex justify-between px-4 pt-6">
            <div>
              <div className="flex">
                <div className="">
                  <div className="flex gap-4 items-center">
                    <div
                      className="antialiased tracking-tight font-poppins tap-highlight-transparent cursor-pointer w-[46px] h-[46px] rounded-[3px]"
                      role="button"
                    >
                      <img
                        width="46"
                        height="46"
                        alt={`${review.USER_INFO.FULL_NAME} photo`}
                        src={
                          review.USER_INFO.IMAGE_URL
                            ? process.env.REACT_APP_IMAGE_URL +
                              review.USER_INFO.IMAGE_URL
                            : user // Placeholder for users without a profile image
                        }
                        className="w-full h-full rounded-[50%]"
                      />
                    </div>
                    <div>
                      <span
                        aria-label={review.USER_INFO.FULL_NAME}
                        role="button"
                        tabIndex="0"
                        className="antialiased tracking-tight font-poppins tap-highlight-transparent cursor-pointer text-gray-900 font-medium text-[18px] capitalize"
                      >
                        {review.USER_INFO.FULL_NAME}
                      </span>
                      <div className="antialiased tracking-tight font-poppins tap-highlight-transparent cursor-pointer text-gray-500 font-normal text-[14px]">
                        {review.USER_INFO.EMAIL_ADDRESS}
                      </div>
                    </div>
                  </div>
                  <div className="antialiased text-[16px] mt-4 tracking-tight font-poppins tap-highlight-transparent inline-flex flex-1 items-center justify-start">
                    <svg
                      className="revw_star hl_star"
                      width="100%"
                      height={20}
                      viewBox="0 0 1000 200"
                      role="img"
                      title={`Rated ${review.RATING}`}
                    >
                      <polygon
                        id={`svg_id_${i}`}
                        points="100,10 40,198 190,78 10,78 160,198"
                        fill="#FFE372"
                      />
                      <defs>
                        <clipPath id={`svg_id_${i}_cp`}>
                          <use xlinkHref={`#svg_id_${i}`} />
                          <use xlinkHref={`#svg_id_${i}`} x="20%" />
                          <use xlinkHref={`#svg_id_${i}`} x="40%" />
                          <use xlinkHref={`#svg_id_${i}`} x="60%" />
                          <use xlinkHref={`#svg_id_${i}`} x="80%" />
                        </clipPath>
                      </defs>
                      <rect
                        width="100%"
                        height="100%"
                        clipPath={`url(#svg_id_${i}_cp)`}
                        style={{
                          fill: 'rgb(215, 215, 215)',
                          stroke: 'red',
                          strokeWidth: 1,
                        }}
                      />
                      <rect
                        width={`${(review.RATING / 5) * 100}%`}
                        height="100%"
                        clipPath={`url(#svg_id_${i}_cp)`}
                        style={{ fill: 'rgb(255, 110, 0)' }}
                      />
                    </svg>
                  </div>
                </div>
              </div>
              <div>
                <span
                  role="none"
                  className="antialiased tracking-tight font-poppins tap-highlight-transparent text-gray-900 font-normal text-[14px] leading-[1.38] break-words"
                >
                  {review.COMMENT}
                </span>
              </div>
            </div>
            <div className="ml-auto flex flex-col gap-4">
              <div className="antialiased tracking-tight font-poppins tap-highlight-transparent cursor-pointer text-gray-500 font-normal text-[16px]">
                {new Date(review.CREATED_AT).toLocaleDateString()}
              </div>
              {review?.PHOTOS && (
                <div>
                  {review?.PHOTOS?.map((e) => (
                    <Image
                      src={process.env.REACT_APP_IMAGE_URL + e}
                      height={100}
                      className="h-20 rounded-[12px]"
                      alt="rev_img"
                    />
                  ))}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ReviewData;
