import React, { useState } from 'react';
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa';

const InputBox = ({
  isPassword = false,
  value,
  onChange,
  name,
  label,
  placeholder,
}) => {
  const [show, setShow] = useState(true);
  return (
    <label
      htmlFor={'Username' + name}
      className="relative mt-3 block rounded-md border border-gray-200 shadow-sm "
    >
      <input
        type={isPassword ? (show ? 'password' : 'text') : 'text'}
        value={value}
        name={name}
        onChange={onChange}
        id={'Username' + name}
        className="py-2 pl-3 pr-8 bg-transparent peer border-none w-full placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0"
        placeholder={placeholder}
      />

      <span className="pointer-events-none absolute start-2.5 top-0 -translate-y-1/2 bg-white p-0.5 text-xs text-gray-700 transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-0 peer-focus:text-xs">
        {label}
      </span>
      {isPassword && (
        <div
          className="absolute inset-y-0 end-0 grid w-10 place-content-center text-gray-500 cursor-pointer"
          onClick={() => setShow(!show)}
        >
          {show ? (
            <FaRegEyeSlash size={20} />
          ) : (
            <FaRegEye size={20} className="" />
          )}
        </div>
      )}
    </label>
  );
};

export default InputBox;
