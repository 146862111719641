export const getApi = async (endpoint) => {
  try {
    const responce = await fetch(process.env.REACT_APP_API_URL + endpoint, {
      method: 'GET',
    });
    const res = await responce.json();
    return res;
  } catch (error) {
    return { success: false, message: 'Network error' };
  }
};

export const postApi = async (endpoint, body) => {
  try {
    const responce = await fetch(process.env.REACT_APP_API_URL + endpoint, {
      method: 'POST',
      body: body,
    });
    const res = await responce.json();
    return res;
  } catch (error) {
    return { success: false, message: 'Network error' };
  }
};
