// import React, { useState } from "react";
// import { IoClose } from "react-icons/io5";
// import InputBox from "../components/InputBox";
// import Button from "../components/Button";
// import LocationDetector from "./LocationDetector";

// const AddressModal = ({ setAddressModal }) => {
//   const [addressData, setAddressData] = useState({
//     street: "",
//     city: "",
//     state: "",
//     zip: "",
//     country: ""
//   });

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setAddressData((prev) => ({
//       ...prev,
//       [name]: value
//     }));
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     localStorage.setItem('pincode',addressData.zip)
//     console.log("Address data: ", addressData);
//     setAddressModal(false);
//   };

//   return (
//     <div className="fixed inset-0 w-full h-full z-20 bg-black bg-opacity-50 overflow-y-auto backdrop-blur-[15px] flex items-center justify-center">
//       <div className="text-black bg-white p-8 rounded-2xl flex flex-col items-center justify-center relative">
//         <IoClose
//           size={30}
//           onClick={() => setAddressModal(false)}
//           className="absolute top-5 right-5 cursor-pointer hover:scale-[1.1] transform transition-transform duration-300"
//         />
//         <h1 className="text-2xl font-semibold text-center">Add or Select Address</h1>
//         <form onSubmit={handleSubmit} className="w-full flex flex-col gap-3 mt-4">
//           {/* <InputBox
//             value={addressData.street}
//             name="street"
//             placeholder="Enter Street"
//             label="Street"
//             onChange={handleChange}
//           />
//           <InputBox
//             value={addressData.city}
//             name="city"
//             placeholder="Enter City"
//             label="City"
//             onChange={handleChange}
//           />
//           <InputBox
//             value={addressData.state}
//             name="state"
//             placeholder="Enter State"
//             label="State"
//             onChange={handleChange}
//           />
//           <InputBox
//             value={addressData.zip}
//             name="zip"
//             placeholder="Enter ZIP Code"
//             label="ZIP Code"
//             onChange={handleChange}
//           />
//           <InputBox
//             value={addressData.country}
//             name="country"
//             placeholder="Enter Country"
//             label="Country"
//             onChange={handleChange}
//           /> */}
//           <InputBox
//             value={addressData.search}
//             name="search"
//             placeholder="Enter place"
//             label="Search"
//             onChange={handleChange}
//           />
//           <LocationDetector setZip={setAddressData} address={addressData}/>
//           <div className="flex justify-center mt-4">
//             <Button type="submit" title="Submit" />
//           </div>
//         </form>
//       </div>
//     </div>
//   );
// };

// export default AddressModal;

import React, { useState } from 'react';
import { FaMapMarkerAlt, FaTimes } from 'react-icons/fa';
import LocationDetector from './LocationDetector';

const DetectLocation = ({
  isOpen,
  setIsOpen,
  selectedLocation,
  setSelectedLocation,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  const [addressData, setAddressData] = useState({
    street: '',
    city: '',
    state: '',
    zip: '',
    country: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAddressData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    // e.preventDefault();
    localStorage.setItem('pincode', e);
    console.log('Address data: ', addressData);
  };

  const handleSearchChange = async (e) => {
    const query = e.target.value;
    setSearchTerm(query);

    if (query.trim() === '') {
      setSearchResults([]);
      return;
    }

    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/search?q=${query}&format=json`
      );
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      console.log(data);
      setSearchResults(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleContentClick = (e) => {
    e.stopPropagation();
  };

  const handleLocationClick = (location) => {
    setSelectedLocation({
      ...selectedLocation,
      zip: location,
    });
    setIsOpen(false); // Close the modal after selecting a location
  };

  return (
    <>
      {isOpen && (
        <div
          className="fixed inset-0 !z-[52] bg-gray-900 bg-opacity-50 flex items-start justify-start pt-[120px] pl-[20px] md:pl-[150px]"
          // onClick={handleClose}
        >
          <div
            className="bg-white rounded-lg shadow-lg p-6 relative w-[90vw] md:w-[30vw]"
            onClick={handleContentClick}
          >
            <button
              className="absolute top-2 right-2 text-gray-600 hover:text-gray-900"
              onClick={handleClose}
            >
              <FaTimes />
            </button>
            <h2 className="text-xl font-semibold mb-4">Change Location</h2>
            <div className="flex flex-col md:flex-row gap-[20px] w-full">
              <div>
                <LocationDetector
                  setAddressData={setAddressData}
                  addressData={addressData}
                />
              </div>
              <div className="flex flex-col flex-[1] items-center gap-[12px] border border-gray-600 px-[10px] py-[4px] rounded-[8px] focus-within:border-blue-500">
                <div className="flex items-center">
                  <FaMapMarkerAlt className="text-blue-500" />
                  <input
                    type="text"
                    className="px-[20px] py-[6px] outline-none w-full"
                    placeholder="Search for a place..."
                    value={searchTerm}
                    onChange={handleSearchChange}
                  />
                </div>
              </div>
            </div>
            <ul className="flex mt-3 flex-col gap-[15px] ">
              {searchResults.map((result) => (
                <li
                  key={result.place_id}
                  onClick={() => {
                    handleSubmit(result.display_name);
                    setIsOpen(false);
                  }}
                >
                  <p className="font-[600] cursor-pointer flex gap-[15px]">
                    <FaMapMarkerAlt className="mt-[6px]" />
                    {result.display_name}
                  </p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

export default DetectLocation;
