import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import 'swiper/swiper-bundle.css';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { useSelector } from 'react-redux';
import { store } from '../redux/store';
import { fetchSliderImg } from '../redux/slice/dataSlice';
import { useNavigate } from 'react-router-dom';

const Sliders = ({ list = [], list2 = [] }) => {
  const { slider_img_list, cat_list, cat_sub_list } = useSelector(
    (state) => state.alldata
  );
  const [Cat, setCat] = useState('');
  const navigate = useNavigate();
  let image = '';
  let image2 = '';
  const banner_top = slider_img_list?.filter(
    (v) => v.PAGE_NAME == 'Home Top'
  )[0]?.DATA_BASED_ON_PAGE_NAME;
  const banner_bottom = slider_img_list?.filter(
    (v) => v.PAGE_NAME == 'Home Bottom'
  )[0]?.DATA_BASED_ON_PAGE_NAME;

  console.log(cat_sub_list);

  const findLinks = (name) => {
    if (name === 'PROVIDER') return 'service-providers';
    else if (name === 'SUBCATEGORYTWO') return 'name/sub-categories2';
    else if (name === 'SUBCATEGORYONE') return 'sub-categories';
    return '/';
  };

  const handleResultClick = async (result) => {
    console.log(result);

    const type =
      result?.REDIRECT === 'CATEGORY'
        ? 'maincategory'
        : result?.REDIRECT === 'SUBCATEGORYONE'
        ? 'subcategory1'
        : result?.REDIRECT === 'SUBCATEGORYTWO'
        ? 'subcategory2'
        : 'serviceProvider';

    const name =
      result?.REDIRECT === 'CATEGORY'
        ? cat_list?.find((e) => {
            console.log(e);
            return e?.ID == result?.CATEGORY_ID;
          })?.C_NAME
        : result?.REDIRECT === 'SUBCATEGORYONE'
        ? cat_sub_list?.SERVICE_INFO?.find((e) => {
            console.log(e);
            return e?.ID == result?.SUB_CATEGORY_ONE_ID;
          })?.S_NAME || 'Sub-category'
        : result?.REDIRECT === 'SUBCATEGORYTWO'
        ? 'subcategory2'
        : 'serviceProvider';

    console.log(name);

    const url = `/${name}/service-providers`;

    const state = {
      id: result?.NEW_ID,
      name: type,
    };

    navigate(url, { state });
  };

  useEffect(() => {
    store.dispatch(fetchSliderImg());
  }, []);

  return (
    list.length > 0 && (
      <div className="flex h-[170px] md:h-[250px] gap-4 mt-8 mb-4">
        <div className="flex w-[100%] md:w-[50%]">
          <Swiper
            pagination={{ dynamicBullets: true }}
            // navigation={true}
            modules={[Navigation, Pagination, Autoplay]}
            className="mySwiper"
            autoplay={{ delay: 2000, disableOnInteraction: false }}
            loop={true}
          >
            {list?.map((item, index) => {
              if (index === 0)
                image = process.env.REACT_APP_IMAGE_URL + item.IMAGE_URL;
              if (index === 1)
                image2 = process.env.REACT_APP_IMAGE_URL + item?.IMAGE_URL;
              return index !== 0 ? (
                <SwiperSlide key={index}>
                  <img
                    src={process.env.REACT_APP_IMAGE_URL + item.IMAGE_URL}
                    className="h-full cursor-pointer w-full object-cover rounded-[6px] overflow-hidden"
                    onClick={() => {
                      console.log('abhisehk');
                      handleResultClick(item);
                    }}
                  />
                </SwiperSlide>
              ) : (
                <></>
              );
            })}
          </Swiper>
        </div>
        {/* {list2?.length > 0 && ( */}
        <div className="hidden md:flex w-[50%] gap-4">
          <div className="relative cursor-pointer w-[50%] h-full group">
            <img
              src={image}
              className="h-full w-full object-cover rounded-[6px] transition-transform duration-300 group-hover:scale-105"
            />
            <div className="absolute inset-0 bg-black bg-opacity-30 opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-[15px]"></div>
          </div>
          <div className="relative cursor-pointer w-[50%] h-full group">
            <img
              src={image2}
              className="h-full w-full object-cover rounded-[6px] transition-transform duration-300 group-hover:scale-105"
            />
            <div className="absolute inset-0 bg-black bg-opacity-30 opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-[15px]"></div>
          </div>
        </div>

        {/* )} */}
      </div>
    )
  );
};

export default Sliders;
