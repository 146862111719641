import React, { useEffect, useState } from 'react';
import { ConnectList, Reviews } from '../apis/api';
import { Breadcrumb } from 'antd';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  FaMapMarkerAlt,
  FaPhoneAlt,
  FaRegThumbsUp,
  FaWhatsapp,
} from 'react-icons/fa';
import { Navigation, Pagination } from 'swiper/modules';
import { useNavigate } from 'react-router-dom';
import Nodata from '../components/Nodata';
import useLoader from '../hooks/useLoader';
import { useDispatch, useSelector } from 'react-redux';
import { setLoginPopup } from '../redux/slice/userSlice';

const Connects = () => {
  const [listData, setListData] = useState([]);
  const [show, setShow] = useState(-1);
  const setLoading = useLoader();
  const navigate = useNavigate();
  const [reviewData, setReviewData] = useState({});
  const loggedIn = useSelector((state) => state.user.loggedIn);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!loggedIn) {
      dispatch(setLoginPopup(true));
      navigate('/');
    } else {
      handleConnectList();
    }
  }, [loggedIn]);

  const handleReviewData = async (item) => {
    setLoading(true);
    const body = {
      ID: item?.INFO?.ID,
      USER_ID: item?.INFO?.USER_ID,
    };
    const res = await Reviews(body);
    console.log(res);
    setLoading(false);
    return res;
  };

  const handleConnectList = async () => {
    setLoading(true);
    const res = await ConnectList();
    console.log(res);
    setLoading(false);

    if (res && res[0]?.CONNECTS) {
      setListData(res[0].CONNECTS);
      const reviews = await Promise.all(
        res[0].CONNECTS.map((item) => handleReviewData(item))
      );
      const reviewData = res[0].CONNECTS.reduce((acc, item, index) => {
        acc[item?.INFO?.ID] = reviews[index];
        return acc;
      }, {});
      console.log(reviewData);
      setReviewData(reviewData);
    } else {
      setListData([]);
      setReviewData({});
    }
  };

  if (!loggedIn) {
    return null;
  }

  return (
    <>
      {listData?.length > 0 ? (
        <div className="mx-4 md:mx-10 max-w-full">
          <Breadcrumb current_page={'Sub Category'} />
          <div className="mb-5">
            <h1 className="text-2xl font-sans font-semibold capitalize">
              Connects
            </h1>
          </div>
          <div className="grid grid-cols-1 max-w-[96vw] gap-4 lg:grid-cols-1">
            {listData.map((v, i) => (
              <div
                key={i}
                onClick={() =>
                  navigate(
                    `/name/service-providers/${v?.INFO?.BUSINESS_NAME}`,
                    {
                      state: { item: v?.INFO },
                    }
                  )
                }
                className="p-4 cursor-pointer flex-wrap md:flex-nowrap border md:max-w-[70%] min-w-fit max-w-[100%] items-center rounded-[8px] flex gap-8"
              >
                <div className="flex w-[350px] items-center  md:w-[250px]">
                  <Swiper
                    pagination={{
                      dynamicBullets: true,
                    }}
                    navigation={false}
                    modules={[Navigation, Pagination]}
                    className="mySwiper"
                    autoplay={true}
                    loop
                  >
                    {v?.INFO?.WORK_GALLERY.map((item, index) => (
                      <SwiperSlide
                        key={index}
                        onClick={(e) => e.stopPropagation()}
                      >
                        <img
                          src={process.env.REACT_APP_IMAGE_URL + item?.path}
                          className="w-full object-cover rounded-[6px] h-[160px] overflow-hidden"
                          alt="Gallery"
                        />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
                <div className="flex w-full flex-col gap-2">
                  <div className="flex items-center gap-2">
                    <div
                      className="h-[26px] w-[32px] rounded-[8px] flex justify-center items-center bg-black text-white"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <FaRegThumbsUp />
                    </div>
                    <div
                      className="text-[22px] font-medium text-[#111] cursor-pointer overflow-hidden max-w-[calc(100%-40px)] leading-tight"
                      style={{
                        WebkitLineClamp: 1,
                        WebkitBoxOrient: 'vertical',
                        display: '-webkit-box',
                      }}
                    >
                      {v?.INFO?.BUSINESS_NAME}
                    </div>
                  </div>
                  <div className="flex items-center gap-4 text-[18px]">
                    <div
                      className="mr-1.5 text-[14px] font-semibold text-white bg-[#058a07] rounded-md w-8 h-6 flex items-center justify-center leading-none cursor-pointer"
                      onClick={(e) => e.stopPropagation()}
                    >
                      {reviewData[v?.INFO?.ID]?.TOTAL_RATING_AVERAGE
                        ? reviewData[v?.INFO?.ID]?.TOTAL_RATING_AVERAGE
                        : '0'}
                    </div>
                    <div>
                      <div
                        title="Ratings for Nri Group Of Institutions Opposite Patel Nagar Raisen Road, Bhopal"
                        className="relative w-[110px] h-[21px] top-[-2px] cursor-pointer"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <svg
                          role="none"
                          width="100%"
                          height="100%"
                          viewBox="0 0 1000 200"
                          className="fill-gray-300"
                        >
                          <polygon
                            id="0_star"
                            points="100,10 40,198 190,78 10,78 160,198"
                            fill="#FFE372"
                          />
                          <defs>
                            <clipPath id="stars0">
                              <use xlinkHref="#0_star" />
                              <use xlinkHref="#0_star" x="20%" />
                              <use xlinkHref="#0_star" x="40%" />
                              <use xlinkHref="#0_star" x="60%" />
                              <use xlinkHref="#0_star" x="80%" />
                            </clipPath>
                          </defs>
                          <rect
                            width="100%"
                            height="100%"
                            clipPath="url(#stars0)"
                            className="fill-gray-300"
                          />
                          <rect
                            width={`${
                              (reviewData[v?.INFO?.ID]?.TOTAL_RATING_AVERAGE /
                                5) *
                              100
                            }%`}
                            height="100%"
                            clipPath="url(#stars0)"
                            className="fill-orange-500"
                          />
                        </svg>
                      </div>
                    </div>
                    <div
                      className="ml-3 mr-3 text-[14px] font-normal text-[#717171] min-h-[21px] cursor-pointer"
                      onClick={(e) => e.stopPropagation()}
                    >
                      {reviewData[v?.INFO?.ID]?.TOTAL_RATINGS_COUNT
                        ? reviewData[v?.INFO?.ID]?.TOTAL_RATINGS_COUNT
                        : '0'}{' '}
                      Rating
                    </div>
                  </div>
                  <div className="flex items-center gap-4">
                    <FaMapMarkerAlt onClick={(e) => e.stopPropagation()} />
                    <div
                      className="text-[15px] font-normal text-[#111] cursor-pointer min-h-[24px] leading-[1.55]"
                      onClick={(e) => e.stopPropagation()}
                    >
                      {v?.INFO?.BUSINESS_ADDRESS}
                    </div>
                  </div>
                  <div className="flex items-center gap-4">
                    {v?.INFO?.CATEGORY_INFO?.map((e, i) => (
                      <div
                        key={i}
                        className="inline-flex items-center justify-start text-[12px] font-medium text-[#111] border border-[#eaeaea] bg-[#f7f7f7] rounded-md h-[22px] min-h-[23px] px-[7px] mr-2 cursor-pointer"
                        onClick={(e) => e.stopPropagation()}
                      >
                        {e?.C_NAME}
                      </div>
                    ))}
                  </div>
                  <div className="flex flex-wrap items-center gap-4">
                    <div
                      className="inline-flex items-center justify-center text-white font-medium text-[15px] bg-[#058a07] border border-[#027c0a] rounded-md h-[35px] min-w-[125px] px-[20px] cursor-pointer"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <div className="callNowAnchor">
                        <FaPhoneAlt size={16} color="#fff" />
                      </div>
                      <span
                        onClick={(e) => {
                          if (show === -1) setShow(i);
                          else {
                            if (i !== show) setShow(i);
                            else setShow(-1);
                          }
                          e.stopPropagation();
                        }}
                        className="min-h-[23px] flex items-center"
                      >
                        <span className="whitecall_icon min-w-fit jdicon mr-2"></span>
                        {show === i ? v?.INFO?.BUSINESS_NUMBER : 'Show Number'}
                      </span>
                    </div>

                    <button
                      id="bestdeal_btn_cta_0755P7480.7480.120225134202.F3E8"
                      className="inline-flex items-center justify-center text-white font-medium text-[15px] bg-[#0076d7] border border-[#0076d7] rounded-md h-[35px] min-w-[125px] px-[15px] mr-4 cursor-pointer min-h-[35px]"
                      onClick={async (e) => {
                        e.stopPropagation();
                      }}
                    >
                      {true ? 'Connected' : 'Connect'}
                    </button>
                    <a
                      href={`https://wa.me/${v?.INFO?.BUSINESS_NUMBER}`} // Replace with the actual phone number, including the country code, without any + or dashes
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <div
                        role="button"
                        tabIndex="0"
                        className="inline-flex items-center justify-center text-[#111] gap-2 font-medium text-[15px] bg-white border border-[#c3c3c3] rounded-md h-[35px] min-w-[80px] px-[10px] mr-4 cursor-pointer min-h-[35px]"
                      >
                        <span className="chat_icon jdicon">
                          <FaWhatsapp size={16} color="#25D366" />
                        </span>
                        <span id="chat_txt">Chat</span>
                      </div>
                    </a>

                    <div
                      className="font-medium ml-auto text-[#111] text-[12px] antialiased box-border text-right cursor-pointer"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <span
                        role="none"
                        className="response_icon jdicon mr-2"
                      ></span>
                      {Math.floor(Math.random() * 1000)} people recently
                      enquired
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <Nodata />
      )}
    </>
  );
};

export default Connects;
