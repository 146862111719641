// src/pages/NotificationsPage.jsx

import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Button from '../../components/Button';
import { GetAllNotifications, ReadNotifications } from '../../apis/api';
import toast from 'react-hot-toast';
import Nodata from '../../components/Nodata';
import { Image } from 'antd';
import { useDispatch } from 'react-redux';
import { fetchNotifications } from '../../redux/slice/userSlice';

const NotificationsPage = () => {
  const [notifications, setNotifications] = useState([]);
  const location = useLocation();
  const dispatch = useDispatch();
  let id = 'djfkdj';

  const handleNotifications = async () => {
    const res = await GetAllNotifications();
    console.log(res);
    setNotifications(res?.data);
  };

  useEffect(() => {
    const handleRead = async () => {
      await ReadNotifications(id);
    };
    handleRead();
    dispatch(fetchNotifications());
  }, [id]);

  useEffect(() => {
    dispatch(fetchNotifications());
    handleNotifications();
  }, []);

  return (
    <div className="min-h-screen bg-white p-0 md:p-4">
      <div className="max-w-full mx-auto bg-white p-6 pt-0 rounded-lg shadow-md">
        <div className="flex justify-between pb-4">
          <h1 className="text-2xl font-semibold mb-4">Notifications</h1>
          {notifications?.length > 0 && (
            <button
              className="flex self-center justify-center items-center gap-2 rounded-full px-4 py-1 text-sm text-[#0474ED]  hover:bg-[#f1f1f1]"
              onClick={async () => {
                const res = await ReadNotifications('lsdkfjlsdfjlsd');
                if (res.success) {
                  toast.success(res.message, {
                    id: 1,
                  });
                  await handleNotifications();
                } else {
                  toast.error(res.message, {
                    id: 1,
                  });
                }
              }}
            >
              Mark All as read
            </button>

            //   <Button
            //     onClick={async () => {
            //       const res = await ReadNotifications('lsdkfjlsdfjlsd');
            //       if (res.success) {
            //         toast.success(res.message);
            //         await handleNotifications();
            //       } else {
            //         toast.error(res.message);
            //       }
            //     }}
            //     title={'Mark All as read'}
            //   />
          )}
        </div>
        {notifications?.length > 0 ? (
          <div>
            <ul className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {notifications?.map((notification, index) => (
                <div key={index} className={`flex  p-4 border rounded-lg `}>
                  <div className="flex-1 ">
                    <h2 className="text-lg font-bold">{notification.TITLE}</h2>
                    <p className="mb-3 break-words text-wrap line-clamp-3">
                      {notification.MESSAGE}
                    </p>
                  </div>
                  {notification.FILE_PATH && (
                    <Image
                      onClick={(e) => e.stopPropagation()}
                      src={notification.FILE_PATH}
                      alt="Notification"
                      width={100}
                      //   className="w-[100px] h-auto rounded-md"
                    />
                  )}
                </div>
              ))}
            </ul>
          </div>
        ) : (
          <Nodata />
        )}
      </div>
    </div>
  );
};

const getNotificationStyles = (isRead) => {
  return isRead
    ? 'bg-gray-100 border-gray-500'
    : 'bg-green-100 border-blue-500';
};

export default NotificationsPage;

{
  /* <li
                  key={index}
                  className={`flex  w-full flex-1 p-4 border rounded-lg ${getNotificationStyles(
                    notification.IS_READ
                  )} ${
                    notification?.NOTIFICATION === id
                      ? 'bg-green-200 border-green-400'
                      : ''
                  }`}
                >
                  <div className="flex-1 w-full">
                    <h2 className="text-lg font-bold">{notification.TITLE}</h2>
                    <p className="mb-3">{notification.MESSAGE}</p>
                  </div>
                  {notification.FILE_PATH && (
                    <Image
                      onClick={(e) => e.stopPropagation()}
                      src={notification.FILE_PATH}
                      alt="Notification"
                      width={100}
                      //   className="w-[100px] h-auto rounded-md"
                    />
                  )}
                </li> */
}
