import React, { useState } from 'react';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import toast from 'react-hot-toast';

const AddImageModal = ({ isOpen, onClose, onAddImage }) => {
  const [selectedFiles, setSelectedFiles] = useState([]);

  const handleFileChange = (event) => {
    setSelectedFiles(Array.from(event.target.files));
  };

  const handleAddImage = () => {
    if (selectedFiles.length > 0) {
      onAddImage(selectedFiles);
      onClose();
    } else {
      toast.error('Please select image files');
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      center
      classNames={{
        overlay:
          'fixed inset-0 bg-black bg-opacity-70 flex justify-center items-center',
        modal:
          'bg-white max-w-[90%] sm:max-w-[300px] md:max-w-[500px] rounded-lg p-4 md:p-6 w-full shadow-xl',
      }}
      showCloseIcon={false}
    >
      <div className="flex flex-col items-center gap-4">
        <h2 className="text-xl font-semibold">Add New Images</h2>
        <input type="file" accept="image/*" multiple onChange={handleFileChange} />
        <button
          className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition-colors duration-200"
          onClick={handleAddImage}
        >
          Add Images
        </button>
      </div>
    </Modal>
  );
};

export default AddImageModal;
