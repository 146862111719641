// import React, { useMemo } from "react";
// import { Link, useLocation, useNavigate } from "react-router-dom";

// const Breadcrumb = ({ breadcrumbState }) => {
//   const location = useLocation();
//   const navigate = useNavigate();

//   const pathnames = useMemo(() => location.pathname.split("/").filter((x) => x), [location]);
//   console.log(pathnames)

//   const getColorClass = (index) => {
//     const colors = [
//       "bg-gray-100",
//       "bg-gray-200",
//       "bg-gray-300",
//       "bg-gray-400",
//       "bg-gray-500",
//       "bg-gray-600",
//       "bg-gray-700",
//       "bg-gray-800",
//       "bg-gray-900",
//     ];
//     return colors[index % colors.length];
//   };

//   return (
//     <nav aria-label="Breadcrumb" className="flex" role="navigation">
//       <ol className="flex overflow-hidden rounded-lg border border-gray-200 text-gray-600">
//         <li className="flex items-center">
//           <Link
//             to="/"
//             className="flex h-10 items-center gap-1.5 bg-gray-100 px-4 transition hover:text-gray-900"
//           >
//             <svg
//               xmlns="http://www.w3.org/2000/svg"
//               className="h-4 w-4"
//               fill="none"
//               viewBox="0 0 24 24"
//               stroke="currentColor"
//             >
//               <path
//                 strokeLinecap="round"
//                 strokeLinejoin="round"
//                 strokeWidth="2"
//                 d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
//               />
//             </svg>
//             <span className="ms-1.5 text-xs font-medium">Home</span>
//           </Link>
//         </li>
//         {pathnames.map((value, index) => {
//           const isLast = index === pathnames.length - 1;
//           const colorClass = getColorClass(index + 1);
//           const hoverClass = getColorClass(index + 2);

//           const state = breadcrumbState?.[index] || {};

//           return (
//             <li key={index} className="relative flex items-center cursor-pointer">
//               <span
//                 className={`absolute inset-y-0 -start-px h-10 w-4 ${colorClass} [clip-path:_polygon(0_0,_0%_100%,_100%_50%)] rtl:rotate-180`}
//               ></span>
//               {isLast ? (
//                 <span
//                   className="flex h-10 items-center bg-white pe-4 ps-8 text-xs font-medium"
//                   aria-current="page"
//                 >
//                   {decodeURIComponent(value)
//                     .replace(/-/g, " ")
//                     .charAt(0)
//                     .toUpperCase() +
//                     decodeURIComponent(value).replace(/-/g, " ").slice(1)}
//                 </span>
//               ) : (
//                 <div
//                   onClick={() => navigate(-(pathnames.length - index - 2), { state })}
//                   className={`flex h-10 items-center pe-4 ps-8 text-xs font-medium transition hover:text-gray-900 ${hoverClass}`}
//                 >
//                   {decodeURIComponent(value)
//                     .replace(/-/g, " ")
//                     .charAt(0)
//                     .toUpperCase() +
//                     decodeURIComponent(value).replace(/-/g, " ").slice(1)}
//                 </div>
//               )}
//             </li>
//           );
//         })}
//       </ol>
//     </nav>
//   );
// };

// export default Breadcrumb;
import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

const Breadcrumb = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const pathnames = location.pathname.split('/').filter((x) => x);
  console.log(pathnames);

  function transformString(value) {
    let modifiedString = value.replace(/%20/g, ' ');
    return modifiedString.charAt(0).toUpperCase() + modifiedString.slice(1);
  }

  return (
    <nav aria-label="Breadcrumb" className="flex">
      <ol className="flex overflow-hidden rounded-lg border border-gray-200 text-gray-600">
        <li className="flex items-center">
          <Link
            to="/"
            className="flex h-10 items-center gap-1.5 bg-gray-100 px-4 transition hover:text-gray-900"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
              />
            </svg>

            <span className="ms-1.5 text-xs font-medium">Home</span>
          </Link>
        </li>
        {pathnames.map((value, index) => {
          const isLast = index === pathnames.length - 1;
          const isLast2 = index === pathnames.length - 2;
          // const to = /${pathnames.slice(0, index + 1).join("/")};
          const to = () => (isLast2 ? '' : navigate(-(index + 1)));

          return (
            <li key={to} className="relative flex items-center cursor-pointer">
              <span
                className={`${`bg-gray-${
                  index + 1
                }00`} absolute inset-y-0 -start-px h-10 w-4 [clip-path:_polygon(0_0,_0%_100%,_100%_50%)] rtl:rotate-180`}
              ></span>
              {isLast ? (
                <span className="flex h-10 items-center bg-white pe-4 ps-8 text-xs font-medium">
                  {value.charAt(0).toUpperCase() + value.slice(1)}
                </span>
              ) : (
                <div
                  to={''}
                  onClick={to}
                  className={`flex h-10 items-center  pe-4 ps-8 text-xs font-medium transition hover:text-gray-900 ${`bg-gray-${
                    index + 2
                  }00`}`}
                >
                  {transformString(
                    value.charAt(0).toUpperCase() + value.slice(1)
                  )}
                </div>
              )}
            </li>
          );
        })}
      </ol>
    </nav>
  );
};

export default Breadcrumb;
