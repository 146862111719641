import React, { useEffect, useState } from 'react';
import { postApi } from '../utils/apis';
import Breadcrumb from '../components/Breadcrumb';
import { useLocation, useNavigate } from 'react-router-dom';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  FaMapMarkerAlt,
  FaRegThumbsUp,
  FaWhatsapp,
  FaPhoneAlt,
} from 'react-icons/fa';
import {
  AddConnection,
  ConnectList,
  GetBusinessInfo,
  Reviews,
  listBasedOnMainSubcategory,
  listBasedOnProvider,
  listBasedOnSubSubcategoryOne,
  listBasedOnSubSubcategoryTwo,
} from '../apis/api';
import toast from 'react-hot-toast';
import useLoader from '../hooks/useLoader';
import Nodata from '../components/Nodata';
import { useDispatch, useSelector } from 'react-redux';
import { setLoginPopup } from '../redux/slice/userSlice';

const ProviderList = () => {
  const [pro_list, setPro_list] = useState([]);
  const [show, setShow] = useState(-1);
  const [data, setData] = useState([]);
  const [connectedList, setConnectedList] = useState(new Set());
  const { state } = useLocation();
  const navigate = useNavigate();
  const [reviewData, setReviewData] = useState({});
  const setLoading = useLoader(); 
  const dispatch = useDispatch();
  const loggedIn = useSelector((state) => state.user?.loggedIn);
  console.log(state);

  const handleReviewData = async (item) => {
    setLoading(true);
    const body = {
      ID: item?.ID,
      USER_ID: item?.USER_ID,
    };
    const res = await Reviews(body);
    setLoading(false);
    return res;
  };

  const handleConnectedList = async () => {
    try {
      setLoading(true);
      const res = await ConnectList();
      const connects = res[0]?.CONNECTS || [];
      const newConnectedSet = new Set(
        connects.map((connect) => connect?.INFO?.ID)
      );
      setConnectedList(newConnectedSet);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching connected list:', error);
      setLoading(false);
    }
  };

  const get_data = async () => {
    const raw = JSON.stringify({
      SUB_CATEGORY_ID: 'e87549cc-2efb-4a8f-810f-e45247399fa5',
    });
    const res = await postApi(
      `service-info/list-based-on-sub-subcategory`,
      raw
    );

    if (res.success) {
      setPro_list(res.data);
    } else {
      setPro_list([]);
    }
  };

  const handleData = async () => {
    let res;
    setLoading(true);
    if (state.name === 'subcategory1') {
      res = await listBasedOnSubSubcategoryOne({
        SUB_CATEGORY_ONE_ID: state?.id,
      });
    } else if (state.name === 'subcategory2') {
      res = await listBasedOnSubSubcategoryTwo({
        SUB_CATEGORY_ID: state?.id,
      });
    } else if (state.name === 'serviceProvider') {
      res = await listBasedOnProvider({
        USER_ID: state?.id,
      });
    } else if (state.name === 'Business') {
      res = await GetBusinessInfo(state?.id);
      console.log(res);
      navigate(`/name/service-providers/${res?.data[0]?.BUSINESS_NAME}`, {
        state: { item: res?.data[0] },
      });
      return;
    } else {
      res = await listBasedOnMainSubcategory({
        MAIN_CATEGORY_ID: state?.id,
      });
    }
    console.log(res);
    setData(res);
    setLoading(false);

    const reviews = await Promise.all(
      res?.map((item) => handleReviewData(item))
    );
    const reviewData = res.reduce((acc, item, index) => {
      acc[item.ID] = reviews[index];
      return acc;
    }, {});
    setReviewData(reviewData);
  };

  useEffect(() => {
    get_data();
    handleData();
    handleConnectedList();
  }, [state]);

  return (
    <div className="mx-4 md:mx-10">
      <Breadcrumb current_page={'Sub Category'} />
      <div className="mb-5">
        <h1 className="text-2xl font-sans font-semibold capitalize">
          {state?.S_NAME}
        </h1>
      </div>
      {data?.length > 0 ? (
        <div className="grid grid-cols-1 max-w-[100vw] gap-4 lg:grid-cols-1">
          {data?.map((v, i) => (
            <div
              key={i}
              onClick={() =>
                navigate(
                  `/name/service-providers/${v?.BUSINESS_NAME?.replace(/\//g, '-')}`,
                  {
                    state: { item: v },
                  }
                )
              }
              className="p-4 cursor-pointer flex-wrap md:flex-nowrap border max-w-[70%] min-w-fit items-center rounded-[8px] flex gap-8"
            >
              <div className="flex  items-center w-[85vw] md:w-[250px]">
                <Swiper
                  pagination={{ dynamicBullets: true }}
                  // navigation={true}
                  modules={[Navigation, Pagination, Autoplay]}
                  className="mySwiper"
                  autoplay={{ delay: 2000, disableOnInteraction: false }}
                  loop={true}
                >
                  {v?.WORK_GALLERY.map((item, index) => (
                    <SwiperSlide
                      key={index}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <img
                        src={process.env.REACT_APP_IMAGE_URL + item?.path}
                        className="w-full object-cover rounded-[6px] h-[160px] overflow-hidden"
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
              <div className="flex w-full flex-col gap-2">
                <div className="flex items-center gap-2">
                  <div
                    className="h-[26px] w-[32px] rounded-[8px] flex justify-center items-center bg-black text-white"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <FaRegThumbsUp />
                  </div>
                  <div
                    className="text-[22px] font-medium text-[#111] cursor-pointer overflow-hidden max-w-[calc(100%-40px)] leading-tight"
                    style={{
                      WebkitLineClamp: 1,
                      WebkitBoxOrient: 'vertical',
                      display: '-webkit-box',
                    }}
                  >
                    {v?.BUSINESS_NAME}
                  </div>
                </div>
                <div className="flex items-center gap-4 text-[18px]">
                  <div
                    className="mr-1.5 text-[14px] font-semibold text-white bg-[#058a07] rounded-md w-8 h-6 flex items-center justify-center leading-none cursor-pointer"
                    onClick={(e) => e.stopPropagation()}
                  >
                    {reviewData[v?.ID]?.TOTAL_RATING_AVERAGE
                      ? reviewData[v?.ID]?.TOTAL_RATING_AVERAGE
                      : '0'}
                  </div>
                  <div>
                    <div
                      title="Ratings for Nri Group Of Institutions Opposite Patel Nagar Raisen Road, Bhopal"
                      className="relative w-[110px] h-[21px] top-[-2px] cursor-pointer"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <svg
                        role="none"
                        width="100%"
                        height="100%"
                        viewBox="0 0 1000 200"
                        className="fill-gray-300"
                      >
                        <polygon
                          id="0_star"
                          points="100,10 40,198 190,78 10,78 160,198"
                          fill="#FFE372"
                        />
                        <defs>
                          <clipPath id="stars0">
                            <use xlinkHref="#0_star" />
                            <use xlinkHref="#0_star" x="20%" />
                            <use xlinkHref="#0_star" x="40%" />
                            <use xlinkHref="#0_star" x="60%" />
                            <use xlinkHref="#0_star" x="80%" />
                          </clipPath>
                        </defs>
                        <rect
                          width="100%"
                          height="100%"
                          clipPath="url(#stars0)"
                          className="fill-gray-300"
                        />
                        <rect
                          width={`${
                            (reviewData[v?.ID]?.TOTAL_RATING_AVERAGE / 5) * 100
                          }%`}
                          height="100%"
                          clipPath="url(#stars0)"
                          className="fill-orange-500"
                        />
                      </svg>
                    </div>
                  </div>
                  <div
                    className="ml-3 mr-3 text-[14px] font-normal text-[#717171] min-h-[21px] cursor-pointer"
                    onClick={(e) => e.stopPropagation()}
                  >
                    {reviewData[v?.ID]?.TOTAL_RATINGS_COUNT
                      ? reviewData[v?.ID]?.TOTAL_RATINGS_COUNT
                      : '0'}{' '}
                    Rating
                  </div>
                </div>
                <div className="flex items-center gap-4">
                  <FaMapMarkerAlt onClick={(e) => e.stopPropagation()} />
                  <div
                    className="text-[15px] font-normal text-[#111] cursor-pointer min-h-[24px] leading-[1.55]"
                    onClick={(e) => e.stopPropagation()}
                  >
                    {v?.BUSINESS_ADDRESS}
                  </div>
                </div>
                <div className="flex items-center gap-4">
                  {v?.CATEGORY_INFO?.map((e, i) => (
                    <div
                      key={i}
                      className="inline-flex items-center justify-start text-[12px] font-medium text-[#111] border border-[#eaeaea] bg-[#f7f7f7] rounded-md h-[22px] min-h-[23px] px-[7px] mr-2 cursor-pointer"
                      onClick={(e) => e.stopPropagation()}
                    >
                      {e?.C_NAME}
                    </div>
                  ))}
                </div>
                <div className="flex flex-wrap items-center gap-4">
                  <div
                    className="inline-flex items-center justify-center text-white font-medium text-[15px] bg-[#058a07] border border-[#027c0a] rounded-md h-[35px] min-w-[180px] px-[20px] cursor-pointer"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <div className="callNowAnchor">
                      <FaPhoneAlt size={16} color="#fff" />
                    </div>
                    <span
                      onClick={(e) => {
                        if (show === -1) setShow(i);
                        else {
                          if (i !== show) setShow(i);
                          else setShow(-1);
                        }
                        e.stopPropagation();
                      }}
                      className="min-h-[23px] flex items-center"
                    >
                      <span className="whitecall_icon jdicon mr-2"></span>
                      {show === i ? v?.BUSINESS_NUMBER : 'Show Number'}
                    </span>
                  </div>
                  <button
                    id="bestdeal_btn_cta_0755P7480.7480.120225134202.F3E8"
                    className="inline-flex items-center justify-center text-white font-medium text-[15px] bg-[#0076d7] border border-[#0076d7] rounded-md h-[35px] min-w-[125px] px-[15px] mr-4 cursor-pointer min-h-[35px]"
                    onClick={async (e) => {
                      e.stopPropagation();
                      if (!loggedIn) {
                        dispatch(setLoginPopup(true));
                        return;
                      }
                      if (!connectedList.has(v?.ID)) {
                        const res = await AddConnection({
                          SERVICE_PROVIDER_ID: v?.ID,
                        });
                        handleData();
                        handleConnectedList();
                        if (res.success)
                          toast.success('connection added successfully');
                      }
                    }}
                  >
                    {connectedList.has(v?.ID) ? 'Connected' : 'Connect'}
                  </button>
                  <a
                    href={`https://wa.me/${v?.BUSINESS_NUMBER}`} // Replace with the actual phone number, including the country code, without any + or dashes
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <div
                      role="button"
                      tabIndex="0"
                      className="inline-flex items-center justify-center text-[#111] gap-2 font-medium text-[15px] bg-white border border-[#c3c3c3] rounded-md h-[35px] min-w-[80px] px-[10px] mr-4 cursor-pointer min-h-[35px]"
                    >
                      <span className="chat_icon jdicon">
                        <FaWhatsapp size={16} color="#25D366" />
                      </span>
                      <span id="chat_txt">Chat</span>
                    </div>
                  </a>
                  <div
                    className="font-medium ml-auto text-[#111] text-[12px] antialiased box-border text-right cursor-pointer"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <span
                      role="none"
                      className="response_icon jdicon mr-2"
                    ></span>
                    {Math.floor(Math.random() * 1000)} people recently enquired
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <Nodata />
      )}
    </div>
  );
};

export default ProviderList;
