import toast from 'react-hot-toast';

const BASE_URL = 'https://api.mycallbook.com/api/';
export const domain = 'https://api.mycallbook.com/';

const token = localStorage.getItem('token');
console.log(token)
export const getSliderList = async () => {
  try {
    const response = await fetch(`${BASE_URL}slider-info/all-list`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer your_token_here', // replace with your actual token if needed
      },
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const resJson = await response.json();
    return resJson?.data[0]?.DATA_BASED_ON_PAGE_NAME;
  } catch (error) {
    console.error('Fetch error:', error);
    // You can also return a default value or rethrow the error based on your requirement
    return [];
  }
};

export const getCategoryList = async () => {
  try {
    const response = await fetch(`${BASE_URL}categories/list`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token, // replace with your actual token if needed
      },
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const resJson = await response.json();
    console.log(resJson);
    return resJson?.data;
  } catch (error) {
    console.error('Fetch error:', error);
    // You can also return a default value or rethrow the error based on your requirement
    return [];
  }
};

export const getSubCategoryListByID = async (id) => {
  try {
    const response = await fetch(`${BASE_URL}services/list/cat_id/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`, // replace with your actual token if needed
      },
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const resJson = await response.json();
    console.log(resJson);
    return resJson?.data;
  } catch (error) {
    console.error('Fetch error:', error);
    // You can also return a default value or rethrow the error based on your requirement
    return [];
  }
};

export const getSubCategoryTwoListByID = async (id) => {
  try {
    const response = await fetch(`${BASE_URL}services1/list/cat_id/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`, // replace with your actual token if needed
      },
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const resJson = await response.json();
    console.log(resJson);
    return resJson?.data;
  } catch (error) {
    console.error('Fetch error:', error);
    // You can also return a default value or rethrow the error based on your requirement
    return [];
  }
};

export const getSubCategoryList = async (id) => {
  try {
    const response = await fetch(`${BASE_URL}services/list`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token, // replace with your actual token if needed
      },
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const resJson = await response.json();
    console.log(resJson);
    return resJson?.data;
  } catch (error) {
    console.error('Fetch error:', error);
    // You can also return a default value or rethrow the error based on your requirement
    return [];
  }
};

export const LoginUser = async (body) => {
  try {
    const response = await fetch(`${BASE_URL}login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token, // replace with your actual token if needed
      },
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const resJson = await response.json();
    console.log(resJson);
    return resJson?.data;
  } catch (error) {
    console.error('Fetch error:', error);
    // You can also return a default value or rethrow the error based on your requirement
    return [];
  }
};

export const UpdateProfile = async (body, id) => {
  try {
    console.log('abhishek');
    const response = await fetch(`${BASE_URL}user/profile-update/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`, // replace with your actual token if needed
      },
      body: body,
    });
    console.log('abhishek');

    const resJson = await response.json();
    console.log(resJson);
    return resJson;
  } catch (error) {
    console.error('Fetch error:', error);
    // You can also return a default value or rethrow the error based on your requirement
    return [];
  }
};

export const Profile = async () => {
  try {
    const response = await fetch(`${BASE_URL}user/profile`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`, // replace with your actual token if needed
      },
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    console.log('abhisehk');
    const resJson = await response.json();
    console.log(resJson);
    return resJson?.data;
  } catch (error) {
    console.error('Fetch error:', error.message);
    // You can also return a default value or rethrow the error based on your requirement
    return [];
  }
};

export const SignupUser = async (body) => {
  try {
    const response = await fetch(`${BASE_URL}user/register`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token, // replace with your actual token if needed
      },
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const resJson = await response.json();
    console.log(resJson);
    return resJson?.data;
  } catch (error) {
    console.error('Fetch error:', error);
    // You can also return a default value or rethrow the error based on your requirement
    return [];
  }
};

export const UpdateProfileImage = async (body) => {
  try {
    const response = await fetch(`${BASE_URL}user/upload-image`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`, // replace with your actual token if needed
      },
      body: body,
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const resJson = await response.json();
    console.log(resJson);
    return resJson;
  } catch (error) {
    console.error('Fetch error:', error);
    // You can also return a default value or rethrow the error based on your requirement
    return [];
  }
};

export const OtpOnEmail = async (body) => {
  try {
    console.log(body);
    const response = await fetch(`${BASE_URL}user/otp-verification`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token, // replace with your actual token if needed
      },
      body: JSON.stringify(body),
    });

    // if (!response.ok) {
    //   throw new Error("Network response was not ok");
    // }

    const resJson = await response.json();
    console.log(resJson);
    return resJson;
  } catch (error) {
    console.error('Fetch error:', error);
    // You can also return a default value or rethrow the error based on your requirement
    return [];
  }
};

export const UpdatePassword = async (body) => {
  try {
    const response = await fetch(`${BASE_URL}update/update-password`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`, // replace with your actual token if needed
      },
      body: JSON.stringify(body),
    });

    // if (!response.ok) {
    //   throw new Error('Network response was not ok');
    // }

    const resJson = await response.json();
    console.log(resJson);
    return resJson;
  } catch (error) {
    console.error('Fetch error:', error);
    // You can also return a default value or rethrow the error based on your requirement
    return [];
  }
};

export const ForgotPassword = async (email) => {
  try {
    const response = await fetch(`${BASE_URL}update/forgot-password/${email}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token, // replace with your actual token if needed
      },
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const resJson = await response.json();
    console.log(resJson);
    return resJson?.data;
  } catch (error) {
    console.error('Fetch error:', error);
    // You can also return a default value or rethrow the error based on your requirement
    return [];
  }
};

export const AccountActiveStatus = async (body) => {
  try {
    const response = await fetch(
      `${BASE_URL}update/check-active-account-status`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token, // replace with your actual token if needed
        },
        body: JSON.stringify(body),
      }
    );

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const resJson = await response.json();
    console.log(resJson);
    return resJson?.data;
  } catch (error) {
    console.error('Fetch error:', error);
    // You can also return a default value or rethrow the error based on your requirement
    return [];
  }
};

export const Searcher = async (query, loggedIn, userId) => {
  try {
    const url = !loggedIn
      ? `search?value=${query?.length === 0 ? '12' : query}`
      : `search?value=${
          query?.length === 0 ? '12' : query
        }&IS_HISTORY_ENTRY=${0}&USER_ID=${userId}`;
    const response = await fetch(`${BASE_URL}${url}`, {
      method: 'GET',
      headers: {
        Authorization: token, // replace with your actual token if needed
      },
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const resJson = await response.json();
    console.log(resJson);
    return resJson;
  } catch (error) {
    console.error('Fetch error:', error);
    // You can also return a default value or rethrow the error based on your requirement
    return [];
  }
};

const API_URL = 'https://api.mycallbook.com/api/';

export const loginApi = async ({ PHONE_NUMBER, PASSWORD }) => {
  const response = await fetch(`${API_URL}login/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ PHONE_NUMBER, PASSWORD }),
  });
  return await response.json();
};

export const signupApi = async ({
  FULL_NAME,
  PHONE_NUMBER,
  EMAIL_ADDRESS,
  PASSWORD,
  OTP_ID,
  OTP,
  GENDER,
}) => {
  const response = await fetch(`${API_URL}user/register`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      FULL_NAME,
      PHONE_NUMBER,
      EMAIL_ADDRESS,
      PASSWORD,
      OTP_ID,
      OTP,
      GENDER,
    }),
  });
  return await response.json();
};

export const forgotPasswordApi = async (EMAIL_ADDRESS) => {
  const response = await fetch(
    `${API_URL}update/forgot-password/${EMAIL_ADDRESS}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
  return await response.json();
};

export const listBasedOnMainSubcategory = async (body) => {
  try {
    const response = await fetch(
      `${BASE_URL}service-info/list-based-on-main-subcategory`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token, // Replace with your actual token if needed
        },
        body: JSON.stringify(body),
      }
    );

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const resJson = await response.json();
    console.log(resJson);
    return resJson?.data;
  } catch (error) {
    console.error('Fetch error:', error);
    return [];
  }
};

export const listBasedOnSubSubcategoryOne = async (body) => {
  try {
    const response = await fetch(
      `${BASE_URL}service-info/list-based-on-sub-subcategory-one`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token, // Replace with your actual token if needed
        },
        body: JSON.stringify(body),
      }
    );

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const resJson = await response.json();
    console.log(resJson);
    return resJson?.data;
  } catch (error) {
    console.error('Fetch error:', error);
    return [];
  }
};

export const listBasedOnSubSubcategoryTwo = async (body) => {
  try {
    const response = await fetch(
      `${BASE_URL}service-info/list-based-on-sub-subcategory-two`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token, // Replace with your actual token if needed
        },
        body: JSON.stringify(body),
      }
    );

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const resJson = await response.json();
    console.log(resJson);
    return resJson?.data;
  } catch (error) {
    console.error('Fetch error:', error);
    return [];
  }
};

export const Contact = async (body) => {
  try {
    const response = await fetch(`${BASE_URL}questions-direct/add`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`, // Replace with your actual token if needed
      },
      body: body,
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const resJson = await response.json();
    console.log(resJson);
    return resJson;
  } catch (error) {
    console.error('Fetch error:', error);
    return [];
  }
};

export const ConnectList = async () => {
  try {
    const response = await fetch(`${BASE_URL}connects/list`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`, // Replace with your actual token if needed
      },
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const resJson = await response.json();
    console.log(resJson);
    return resJson?.data;
  } catch (error) {
    console.error('Fetch error:', error);
    return [];
  }
};

export const AddConnection = async (body) => {
  try {
    console.log(body);
    const response = await fetch(`${BASE_URL}connects/add`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json', // Replace with your actual token if needed
      },
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const resJson = await response.json();
    console.log(resJson);
    return resJson;
  } catch (error) {
    console.error('Fetch error:', error);
    return [];
  }
};

export const DeleteConnection = async (body) => {
  try {
    const response = await fetch(`${BASE_URL}connects/add`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        // Replace with your actual token if needed
      },
      body: body,
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const resJson = await response.json();
    console.log(resJson);
    return resJson?.data;
  } catch (error) {
    console.error('Fetch error:', error);
    return [];
  }
};

export const ConnectListForProvider = async (body) => {
  try {
    const response = await fetch(`${BASE_URL}connects/add`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`, // Replace with your actual token if needed
      },
      body: body,
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const resJson = await response.json();
    console.log(resJson);
    return resJson?.data;
  } catch (error) {
    console.error('Fetch error:', error);
    return [];
  }
};

export const Reviews = async (body) => {
  try {
    const response = await fetch(`${BASE_URL}review/service_provider-list`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json', // Replace with your actual token if needed
      },
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const resJson = await response.json();
    console.log(resJson);
    return resJson?.data[0];
  } catch (error) {
    console.error('Fetch error:', error);
    return [];
  }
};

export const CreateBusiness = async (body) => {
  try {
    const response = await fetch(`${BASE_URL}service-info/create`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: body,
    });

    // if (!response.ok) {
    //   throw new Error("Network response was not ok");
    // }

    const resJson = await response.json();
    console.log(resJson);
    return resJson;
  } catch (error) {
    console.error('Fetch error:', error);
    return [];
  }
};

export const GetBusinessList = async (body) => {
  try {
    console.log(body);
    const response = await fetch(`${BASE_URL}service-info/info_details`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });

    // if (!response.ok) {
    //   throw new Error("Network response was not ok");
    // }

    const resJson = await response.json();
    return resJson?.data ? resJson?.data : [];
  } catch (error) {
    console.error('Fetch error:', error);
    return [];
  }
};

export const AddReview = async (body) => {
  try {
    console.log(body);
    const response = await fetch(`${BASE_URL}review/add`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: body,
    });

    // if (!response.ok) {
    //   throw new Error("Network response was not ok");
    // }

    const resJson = await response.json();
    console.log(resJson);
    return resJson;
  } catch (error) {
    console.error('Fetch error:', error);
    return [];
  }
};

export const DeleteBusiness = async (ID) => {
  try {
    console.log(ID);
    const response = await fetch(
      `${BASE_URL}service-info/business-delete?ID=${ID}`,
      {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    // if (!response.ok) {
    //   throw new Error("Network response was not ok");
    // }

    const resJson = await response.json();
    console.log(resJson);
    return resJson;
  } catch (error) {
    console.error('Fetch error:', error);
    return [];
  }
};

export const get_searching = async (term, userId) => {
  try {
    const response = await fetch(
      `https://api.mycallbook.com/api/search?value=${term}&USER_ID=${userId}&IS_HISTORY_ENTRY=1`,
      { method: 'GET' }
    );
    const res = await response.json();
    console.log('search', res);
  } catch (error) {}
};

export const listBasedOnProvider = async (body) => {
  try {
    console.log(body);
    const response = await fetch(`${BASE_URL}service-info/info_details`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
    const res = await response.json();
    console.log(res);
    return res?.data ? res?.data : [];
  } catch (error) {}
};

export const removeHistory = async (prompt, userId, clearAll) => {
  try {
    console.log(prompt, userId, clearAll);
    const response = await fetch(
      `${BASE_URL}search/delete-history?PROMPT=${prompt}&ALL_CLEAR=${clearAll}&USER_ID=${userId}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
    const res = await response.json();
    console.log(res);
    return res;
  } catch (error) {}
};

export const GetAllNotifications = async () => {
  try {
    const response = await fetch(`${BASE_URL}notification/list`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    const res = await response.json();
    console.log(res);
    return res;
  } catch (error) {}
};

export const ReadNotifications = async (id) => {
  try {
    console.log(id);
    const response = await fetch(
      `${BASE_URL}notification/seen/notification_ID/${id}?ALL_SEEN=${
        id?.length > 10 ? false : true
      }`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
    const res = await response.json();
    console.log(res);
    return res;
  } catch (error) {}
};

export const GetBusinessInfo = async (id) => {
  try {
    const response = await fetch(
      `${BASE_URL}service-info/single-business-info?ID=${id}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
    const res = await response.json();
    console.log(res);
    return res;
  } catch (error) {}
};

export const UpdateBusiness = async (body) => {
  try {
    const response = await fetch(`${BASE_URL}service-info/update`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: body,
    });

    // if (!response.ok) {
    //   throw new Error("Network response was not ok");
    // }

    const resJson = await response.json();
    console.log(resJson);
    return resJson;
  } catch (error) {
    console.error('Fetch error:', error);
    return [];
  }
};

export const GetPlanDetails = async () => {
  const response = await fetch(`${API_URL}subcription-plan/list`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const resJson = await response.json();
  return resJson?.data;
};

export const DeleteBusinessImage = async (body) => {
  try {
    const response = await fetch(`${BASE_URL}service-info/image-delete`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: body,
    });

    // if (!response.ok) {
    //   throw new Error("Network response was not ok");
    // }

    const resJson = await response.json();
    console.log(resJson);
    return resJson;
  } catch (error) {
    console.error('Fetch error:', error);
    return [];
  }
};

export const AddBusinessImage = async (body) => {
  try {
    const response = await fetch(`${BASE_URL}service-info/add-images`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: body,
    });

    // if (!response.ok) {
    //   throw new Error("Network response was not ok");
    // }

    const resJson = await response.json();
    console.log(resJson);
    return resJson;
  } catch (error) {
    console.error('Fetch error:', error);
    return [];
  }
};
