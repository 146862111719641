// src/hooks/useLoader.js
import { useDispatch } from 'react-redux';
import { showLoader, hideLoader } from '../redux/slice/loaderSlice';

const useLoader = () => {
  const dispatch = useDispatch();

  const setLoading = (isLoading) => {
    if (isLoading) {
      dispatch(showLoader());
    } else {
      dispatch(hideLoader());
    }
  };

  return setLoading;
};

export default useLoader;
