import React from 'react';

const Button = ({ title, className, onClick }) => {
  return (
    <button
      className={`font-sans inline-block !min-w-fit rounded bg-[#0474ED] px-4 py-2 text-sm font-medium text-white transition hover:scale-[1.03] hover:shadow-xl ${className}`}
      href="#"
      onClick={onClick}
    >
      {title}
    </button>
    // <button className="p-2 max-w-[200px] w-full mt-4 bg-[#0474ED] text-white rounded-md transform transition-transform duration-300 hover:scale-[1.02]">
    //   {title}
    // </button>
  );
};

export default Button;
