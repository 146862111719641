// src/components/Loader.js
import { useSelector } from 'react-redux';
import { Audio } from 'react-loader-spinner';

const Loader = () => {
  const isLoading = useSelector((state) => state.loader.isLoading);

  if (!isLoading) {
    return null;
  }

  return (
    <div className="h-screen hi inset-0 bg-white bg-opacity-50 w-screen flex justify-center items-center fixed z-[100] backdrop-blur-lg">
      <Audio
        height="80"
        width="80"
        radius="9"
        color="#0474ED"
        ariaLabel="three-dots-loading"
        wrapperStyle
        wrapperClass
      />
    </div>
  );
};

export default Loader;
