import React, { useEffect, useRef, useState } from 'react';
import { MdLocationPin, MdMyLocation } from 'react-icons/md';
import Button from './Button';

const useOutsideClick = (ref, callback) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, callback]);
};
const LocationDetector = ({ addressData, setAddressData }) => {
  const [isFocused, setIsFocused] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [selected, setSelected] = useState(localStorage.getItem('city'));
  const [loading, setLoading] = useState(false);
  const inputRef = useRef();

  useOutsideClick(inputRef, () => setIsFocused(false));
  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    // setIsFocused(false);
  };

  const handleSearchChange = async (e) => {
    const query = e.target.value;
    setSearchTerm(query);

    if (query.trim() === '') {
      setSearchResults([]);
      return;
    }

    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/search?q=${query}&format=json`
      );
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setSearchResults(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const [location, setLocation] = useState(null);
  const [error, setError] = useState(null);

  const getLocation = () => {
    setLoading(true);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          fetchAddress(position.coords.latitude, position.coords.longitude);
          console.log({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        },
        (error) => {
          setLoading(false);
          setError(error.message);
          console.log(error.message);
          if (error.code === error.PERMISSION_DENIED) {
            alert(
              'Geolocation permission denied. Please enable it in your browser settings.'
            );
          } else {
            alert('Geolocation error: ' + error.message);
          }
        }
      );
    } else {
      setLoading(false);
      setError('Geolocation is not supported by this browser.');
      console.log('Geolocation is not supported by this browser.');
    }
  };
  const fetchAddress = async (latitude, longitude) => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`
      );
      const data = await response.json();
      localStorage.setItem('pincode', data?.display_name);
      window.location.reload();
      console.log(data);
      setLoading(false);
      if (data && data.display_name) {
        setSelected(data.display_name);
      } else {
        setError('No address found for the given coordinates.');
      }
    } catch (error) {
      setLoading(false);
      setError('Error fetching address: ' + error.message);
    }
  };

  return (
    <div
      className="relative flex group justify-center items-center max-w-[500px]"
      ref={inputRef}
    >
      <div className="border-2 max-w-[330px] rounded-md bg-[#0474ED] flex items-center pl-2 gap-2 cursor-pointer">
        {/* <MdLocationPin size={25} color="#000" /> */}
        {isFocused ? (
          <input
            // ref={inputRef}
            autoFocus
            type="text"
            onFocus={handleFocus}
            onBlur={handleBlur}
            className={`w-full  p-3 rounded-md focus:outline-none`}
            placeholder="Search Location"
            value={searchTerm}
            onChange={handleSearchChange}
          />
        ) : (
          <div
            className={`w-full p-3 truncate`}
            onClick={() => {
              getLocation();
              setIsFocused(false);
            }}
          >
            <p className="text-[12px] text-white"> Detect Location</p>
          </div>
        )}
      </div>
      <div className="ml-[20px]">or</div>
      <div
        className={`absolute end-0 z-10 w-full max-h-[50vh] overflow-auto rounded-md border border-gray-100 bg-white shadow-lg  ${
          isFocused ? 'block' : 'hidden'
        }`}
        role="menu"
      >
        <div className="p-2 ">
          <div
            className={`flex gap-2 items-center w-full  p-2 bg-slate-200 rounded-md cursor-pointer`}
            onClick={() => {
              getLocation();
              setIsFocused(false);
            }}
            // onClick={getLocation}
          >
            <MdMyLocation size={20} /> Detect Location
          </div>
          <ul className="gap-2 p-2 ">
            {searchResults.map((result) => (
              <li
                className="cursor-pointer"
                onClick={() => {
                  setSelected(result.display_name);
                  setIsFocused(false);
                  setSearchTerm('');
                  setSearchResults([]);
                }}
                key={result.place_id}
              >
                {result.display_name}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default LocationDetector;
