import React, { useState, useEffect } from 'react';
import {
  CreateBusiness,
  getSubCategoryListByID,
  getSubCategoryTwoListByID,
} from '../apis/api';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { FaXmark } from 'react-icons/fa6';
import useLoader from '../hooks/useLoader';

const AddBusinessModal = ({ isOpen, onClose, addBusiness }) => {
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    address: '',
    latitude: '',
    longitude: '',
    businessNumber: '',
    businessEmail: '',
    serviceInfo: [],
    category: '',
    pincode: '',
    locationInfo: '',
    experience: '',
    socialMediaLinks: [
      { NAME: 'YouTube', LINK: '' },
      { NAME: 'Facebook', LINK: '' },
      { NAME: 'Instagram', LINK: '' },
    ],
    webURL: '',
    userId: '',
    idCard: null,
    addressProof: null,
    photos: [],
  });

  const userData = useSelector((state) => state.user.userData);
  const { sub_cat_list, cat_list } = useSelector((state) => state.alldata);
  const [subCategories, setSubCategories] = useState([]);
  const [subCategoriesTwo, setSubCategoriesTwo] = useState({});
  const setLoading = useLoader();

  useEffect(() => {
    const handleFunc = async () => {
      if (formData.category) {
        await getSubCategoryListByID(formData.category).then(setSubCategories);
      }
    };
    handleFunc();
  }, [formData.category]);

  useEffect(() => {
    const fetchSubCategoryTwo = async () => {
      const newSubCategoriesTwo = {};
      for (let service of formData.serviceInfo) {
        const res = await getSubCategoryTwoListByID(formData?.category);
        newSubCategoriesTwo[service.MAIN_SERVICE_ID] = res;
      }
      setSubCategoriesTwo(newSubCategoriesTwo);
    };

    if (formData.serviceInfo?.length > 0) {
      fetchSubCategoryTwo();
    }
  }, [formData.serviceInfo]);

  if (!isOpen) return null;

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: files ? files : value,
    }));
  };

  const handleServiceChange = (e, index) => {
    const { value } = e.target;
    setFormData((prevData) => {
      const newServiceInfo = [...prevData.serviceInfo];
      newServiceInfo[index] = {
        MAIN_SERVICE_ID: value,
        SERVICE_IDS: [],
      };
      return {
        ...prevData,
        serviceInfo: newServiceInfo,
      };
    });
  };

  const handleAddService = () => {
    if (formData?.serviceInfo?.length >= subCategories.length) return;
    setFormData((prevData) => ({
      ...prevData,
      serviceInfo: [
        ...prevData.serviceInfo,
        { MAIN_SERVICE_ID: '', SERVICE_IDS: [] },
      ],
    }));
  };

  const handleRemoveService = (index) => {
    setFormData((prevData) => {
      const newServiceInfo = prevData.serviceInfo.filter((_, i) => i !== index);
      return {
        ...prevData,
        serviceInfo: newServiceInfo,
      };
    });
  };

  const handleCheckboxChange = (serviceId, MAIN_SERVICE_ID) => {
    setFormData((prevData) => {
      const newServiceInfo = prevData.serviceInfo.map((service) => {
        if (service.MAIN_SERVICE_ID === MAIN_SERVICE_ID) {
          const newServiceIds = service.SERVICE_IDS.includes(serviceId)
            ? service.SERVICE_IDS.filter((id) => id !== serviceId)
            : [...service.SERVICE_IDS, serviceId];
          return {
            ...service,
            SERVICE_IDS: newServiceIds,
          };
        }
        return service;
      });
      return {
        ...prevData,
        serviceInfo: newServiceInfo,
      };
    });
  };

  const handleAddSocialMediaLink = () => {
    setFormData((prevData) => ({
      ...prevData,
      socialMediaLinks: [...prevData.socialMediaLinks, { NAME: '', LINK: '' }],
    }));
  };

  const handleRemoveSocialMediaLink = (index) => {
    setFormData((prevData) => {
      const newSocialMediaLinks = prevData.socialMediaLinks.filter(
        (_, i) => i !== index
      );
      return {
        ...prevData,
        socialMediaLinks: newSocialMediaLinks,
      };
    });
  };

  const handleSocialMediaChange = (index, field, value) => {
    setFormData((prevData) => {
      const newSocialMediaLinks = [...prevData.socialMediaLinks];
      newSocialMediaLinks[index] = {
        ...newSocialMediaLinks[index],
        [field]: value,
      };
      return {
        ...prevData,
        socialMediaLinks: newSocialMediaLinks,
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const form = new FormData();
    form.append('BUSINESS_NUMBER', formData.businessNumber);
    form.append('BUSINESS_EMAIL', formData.businessEmail);
    form.append('LATTITUDE', 0);
    form.append('LONGITUDE', 0);
    form.append('SERVICE_INFO', JSON.stringify(formData.serviceInfo));
    form.append('CATEGORY', formData.category);
    form.append('PINCODE', formData.pincode);
    form.append('DESCRIPTION', formData.description);
    form.append('LOCATION_INFO', JSON.stringify(formData.locationInfo));
    form.append('EXPERIENCE', formData.experience);
    form.append(
      'SOCIAL_MEDIA_LINKS',
      JSON.stringify(formData.socialMediaLinks)
    );
    form.append('BUSINESS_NAME', formData.name);
    form.append('WEB_URL', formData.webURL);
    form.append('BUSINESS_ADDRESS', formData.address);
    form.append('USER_ID', userData?.USER_ID);

    if (formData.idCard) {
      form.append('IDCARD', formData.idCard[0]);
    }

    if (formData.addressProof) {
      form.append('ADDRPROOF', formData.addressProof[0]);
    }

    if (formData.photos) {
      Array.from(formData.photos).forEach((photo) => {
        form.append('PHOTOS', photo);
      });
    }

    try {
      const res = await CreateBusiness(form);
      console.log(res);
      if (res.success) {
        addBusiness(res.data);
        setFormData({
          name: '',
          description: '',
          address: '',
          latitude: '',
          longitude: '',
          businessNumber: '',
          businessEmail: '',
          serviceInfo: [],
          category: '',
          pincode: '',
          locationInfo: '',
          experience: '',
          socialMediaLinks: [
            { NAME: 'YouTube', LINK: '' },
            { NAME: 'Facebook', LINK: '' },
            { NAME: 'Instagram', LINK: '' },
          ],
          webURL: '',
          userId: '',
          idCard: null,
          addressProof: null,
          photos: [],
        });
        onClose();
        toast.success(res.message);
      } else {
        toast.error(res.message);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error adding business:', error);
      toast.error('An error occurred while adding the business.');
    }
  };
  return (
    <div className="fixed inset-0 z-[53] bg-gray-800 bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-6 rounded-md shadow-md w-full max-w-[80vw] max-h-[80vh] overflow-auto">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-semibold ">Add Business</h2>
          <FaXmark
            className="text-[24px] cursor-pointer"
            onClick={() => {
              setFormData({
                name: '',
                description: '',
                address: '',
                latitude: '',
                longitude: '',
                businessNumber: '',
                businessEmail: '',
                serviceInfo: [],
                category: '',
                pincode: '',
                locationInfo: '',
                experience: '',
                socialMediaLinks: [
                  { NAME: 'YouTube', LINK: '' },
                  { NAME: 'Facebook', LINK: '' },
                  { NAME: 'Instagram', LINK: '' },
                ],
                webURL: '',
                userId: '',
                idCard: null,
                addressProof: null,
                photos: [],
              });
              onClose();
            }}
          />
        </div>
        <form
          className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4"
          onSubmit={handleSubmit}
        >
          {[
            { label: 'Business Name', name: 'name', type: 'text' },
            { label: 'Business Number', name: 'businessNumber', type: 'text' },
            { label: 'Business Email', name: 'businessEmail', type: 'email' },
            { label: 'Address', name: 'address', type: 'text' },
            {
              label: 'Pincode',
              name: 'pincode',
              type: 'text',
            },
            // {
            //   label: 'Location Info',
            //   name: 'locationInfo',
            //   type: 'select',
            //   options: [{ id: '23', name: 'akfjs' }].map((location) => ({
            //     value: location.id,
            //     label: location.name,
            //   })),
            // },
            { label: 'Experience', name: 'experience', type: 'text' },
            { label: 'Web URL', name: 'webURL', type: 'url' },
            { label: 'ID Card', name: 'idCard', type: 'file' },
            { label: 'Address Proof', name: 'addressProof', type: 'file' },
            { label: 'Photos', name: 'photos', type: 'file', multiple: true },
            { label: 'Description', name: 'description', type: 'textarea' },
            {
              label: 'Category',
              name: 'category',
              type: 'select',
              options: cat_list?.map((category) => ({
                value: category?.ID,
                label: category?.C_NAME,
              })),
            },
          ].map(({ label, name, type, options, multiple }) => (
            <div key={name} className="mb-4">
              <label className="block text-gray-700">{label}</label>
              {type === 'textarea' ? (
                <textarea
                  name={name}
                  className="w-full p-2 border border-gray-300 rounded-md"
                  value={formData[name]}
                  onChange={handleChange}
                />
              ) : type === 'select' ? (
                <select
                  name={name}
                  className="w-full p-2 border border-gray-300 rounded-md"
                  value={formData[name]}
                  onChange={handleChange}
                >
                  <option value="">Select {label}</option>
                  {options.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              ) : (
                <input
                  type={type}
                  name={name}
                  className="w-full p-2 border border-gray-300 rounded-md"
                  value={type !== 'file' ? formData[name] : undefined}
                  onChange={handleChange}
                  multiple={multiple}
                />
              )}
              {type == 'file' && formData[name]?.length > 0 && !multiple && (
                <img
                  src={URL.createObjectURL(formData[name][0])}
                  className="h-20 w-20"
                  alt=""
                />
              )}
            </div>
          ))}
          <div className="mb-4 col-span-1 sm:col-span-2 md:col-span-3">
            <label className="block text-gray-700">Main Services</label>
            {formData?.serviceInfo?.map((service, indexx) => (
              <div key={indexx} className="mb-2">
                <div className="flex items-center">
                  <select
                    className="w-full p-2 border border-gray-300 rounded-md"
                    value={service.MAIN_SERVICE_ID}
                    onChange={(e) => handleServiceChange(e, indexx)}
                  >
                    <option value="">Select Main Service</option>
                    {subCategories.map((servicee, index) => {
                      let flag = false;
                      console.log(index);
                      for (let i = 0; i < indexx; i++) {
                        console.log(formData?.serviceInfo, i);
                        console.log(servicee?.S_NAME);
                        if (
                          formData?.serviceInfo[i]?.MAIN_SERVICE_ID ==
                          servicee?.ID
                        ) {
                          flag = true;
                          break;
                        }
                      }
                      return (
                        <>
                          {!flag ? (
                            <option key={servicee.ID} value={servicee.ID}>
                              {servicee.S_NAME}
                            </option>
                          ) : (
                            <></>
                          )}
                        </>
                      );
                    })}
                  </select>
                  <button
                    type="button"
                    className="ml-2 bg-red-500 text-white px-4 py-2 rounded-md"
                    onClick={() => handleRemoveService(indexx)}
                  >
                    Remove
                  </button>
                </div>
                {subCategoriesTwo[service.MAIN_SERVICE_ID] && (
                  <div className="flex flex-wrap mt-2">
                    {subCategoriesTwo[service.MAIN_SERVICE_ID].map(
                      (subService) => {
                        console.log(subService);
                        let flag = false;
                        for (
                          let i = 0;
                          i < subService?.SERVICE_INFO?.length;
                          i++
                        ) {
                          if (
                            subService?.SERVICE_INFO[i]?.ID ===
                            service?.MAIN_SERVICE_ID
                          ) {
                            flag = true;
                            break;
                          }
                        }
                        return (
                          <>
                            {flag ? (
                              <div key={subService.ID} className="mr-4 mb-2">
                                <input
                                  type="checkbox"
                                  id={`service-${subService.ID}`}
                                  value={subService.ID}
                                  checked={service.SERVICE_IDS.includes(
                                    subService.ID
                                  )}
                                  onChange={() =>
                                    handleCheckboxChange(
                                      subService.ID,
                                      service.MAIN_SERVICE_ID
                                    )
                                  }
                                />
                                <label
                                  htmlFor={`service-${subService.ID}`}
                                  className="ml-2"
                                >
                                  {subService.S_NAME}
                                </label>
                              </div>
                            ) : (
                              <></>
                            )}
                          </>
                        );
                      }
                    )}
                  </div>
                )}
              </div>
            ))}
            {formData?.serviceInfo?.length < subCategories.length && (
              <button
                type="button"
                className="bg-blue-500 text-white px-4 py-2 rounded-md mt-2"
                onClick={handleAddService}
              >
                Add Service
              </button>
            )}
          </div>
          <div className="mb-4 col-span-1 sm:col-span-2 md:col-span-3">
            <label className="block text-gray-700">Social Media Links</label>
            {formData.socialMediaLinks.map((link, index) => (
              <div key={index} className="flex items-center mb-2">
                <input
                  type="text"
                  placeholder="Name"
                  value={link.NAME}
                  onChange={(e) =>
                    handleSocialMediaChange(index, 'NAME', e.target.value)
                  }
                  className="w-1/3 p-2 border border-gray-300 rounded-md mr-2"
                />
                <input
                  type="url"
                  placeholder="Link"
                  value={link.LINK}
                  onChange={(e) =>
                    handleSocialMediaChange(index, 'LINK', e.target.value)
                  }
                  className="w-2/3 p-2 border border-gray-300 rounded-md"
                />
                <button
                  type="button"
                  className="ml-2 bg-red-500 text-white px-4 py-2 rounded-md"
                  onClick={() => handleRemoveSocialMediaLink(index)}
                >
                  Remove
                </button>
              </div>
            ))}
            <button
              type="button"
              className="bg-blue-500 text-white px-4 py-2 rounded-md mt-2"
              onClick={handleAddSocialMediaLink}
            >
              Add Social Media Link
            </button>
          </div>
          <div className="col-span-1 sm:col-span-2 md:col-span-3 flex justify-end">
            <button
              type="button"
              className="bg-red-500 text-white px-4 py-2 rounded-md mr-2"
              onClick={() => {
                setFormData({
                  name: '',
                  description: '',
                  address: '',
                  latitude: '',
                  longitude: '',
                  businessNumber: '',
                  businessEmail: '',
                  serviceInfo: [],
                  category: '',
                  pincode: '',
                  locationInfo: '',
                  experience: '',
                  socialMediaLinks: [
                    { NAME: 'YouTube', LINK: '' },
                    { NAME: 'Facebook', LINK: '' },
                    { NAME: 'Instagram', LINK: '' },
                  ],
                  webURL: '',
                  userId: '',
                  idCard: null,
                  addressProof: null,
                  photos: [],
                });
                onClose();
              }}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-blue-500 text-white px-4 py-2 rounded-md"
            >
              Add Business
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddBusinessModal;
