import React, { useState, useEffect } from 'react';
import {
  CreateBusiness,
  getSubCategoryListByID,
  getSubCategoryTwoListByID,
  UpdateBusiness,
} from '../apis/api';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { FaXmark } from 'react-icons/fa6';

const UpdateBusinessModal = ({
  isOpen,
  onClose,
  updateBusiness,
  type,
  business,
}) => {
  const initialFormData = {
    name: '',
    description: '',
    address: '',
    latitude: '',
    longitude: '',
    businessNumber: '',
    businessEmail: '',
    serviceInfo: [],
    category: '',
    pincode: '',
    locationInfo: '',
    experience: '',
    socialMediaLinks: [{ NAME: '', LINK: '' }],
    webURL: '',
    userId: '',
    idCard: null,
    addressProof: null,
    photos: [],
  };

  const [formData, setFormData] = useState(initialFormData);
  const [subCategories, setSubCategories] = useState([]);
  const [subCategoriesTwo, setSubCategoriesTwo] = useState({});

  const userData = useSelector((state) => state.user.userData);
  const { sub_cat_list, cat_list } = useSelector((state) => state.alldata);
  console.log(business)

  useEffect(() => {
    if (business?.BUSINESS_NAME) {
      console.log(business)
      setFormData({
        name: business.BUSINESS_NAME || '',
        description: business.DESCRIPTION || '',
        address: business.BUSINESS_ADDRESS || '',
        latitude: business.LATTITUDE || '',
        longitude: business.LONGITUDE || '',
        businessNumber: business.BUSINESS_NUMBER || '',
        businessEmail: business.BUSINESS_EMAIL || '',
        serviceInfo:
          business.SERVICE_INFO?.map((service) => ({
            MAIN_SERVICE_ID: service?.MAIN_SERVICE?.ID,
            SERVICE_IDS: service.SERVICES.map((subService) => subService.ID),
          })) || [],
        category: business.CATEGORY || '',
        pincode: business.PINCODE || '',
        locationInfo: business.LOCATION_INFO || '',
        experience: business.EXPERIENCE || '',
        socialMediaLinks: business.SOCIAL_MEDIA_LINKS?.map((link) => ({
          NAME: link.NAME,
          LINK: link.LINK,
        })) || [{ NAME: '', LINK: '' }],
        webURL: business.WEB_URL || '',
        userId: business.USER_ID || '',
        idCard: business.ID_CARD || null,
        addressProof: business.ADDR_PROOF || null,
        photos: business.WORK_GALLERY?.map((photo) => photo.path) || [],
      });
    }
  }, [business]);

  useEffect(() => {
    const fetchSubCategories = async () => {
      if (formData.category) {
        const res = await getSubCategoryListByID(formData.category);
        setSubCategories(res);
      }
    };
    fetchSubCategories();
  }, [formData.category]);

  useEffect(() => {
    const fetchSubCategoryTwo = async () => {
      const newSubCategoriesTwo = {};
      for (let service of formData.serviceInfo) {
        const res = await getSubCategoryTwoListByID(service.MAIN_SERVICE_ID);
        newSubCategoriesTwo[service.MAIN_SERVICE_ID] = res;
      }
      setSubCategoriesTwo(newSubCategoriesTwo);
    };

    if (formData.serviceInfo.length > 0) {
      fetchSubCategoryTwo();
    }
  }, [formData.serviceInfo]);

  if (!isOpen) return null;

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    console.log(console.log(formData[name]))
    setFormData((prevData) => ({
      ...prevData,
      [name]: files ? files : value,
    }));
  };

  const handleServiceChange = (e, index) => {
    const { value } = e.target;
    setFormData((prevData) => {
      const newServiceInfo = [...prevData.serviceInfo];
      newServiceInfo[index] = { MAIN_SERVICE_ID: value, SERVICE_IDS: [] };
      return { ...prevData, serviceInfo: newServiceInfo };
    });
  };

  const handleAddService = () => {
    setFormData((prevData) => ({
      ...prevData,
      serviceInfo: [
        ...prevData.serviceInfo,
        { MAIN_SERVICE_ID: '', SERVICE_IDS: [] },
      ],
    }));
  };

  const handleRemoveService = (index) => {
    setFormData((prevData) => ({
      ...prevData,
      serviceInfo: prevData.serviceInfo.filter((_, i) => i !== index),
    }));
  };

  const handleCheckboxChange = (serviceId, MAIN_SERVICE_ID) => {
    setFormData((prevData) => {
      const newServiceInfo = prevData.serviceInfo.map((service) => {
        if (service.MAIN_SERVICE_ID === MAIN_SERVICE_ID) {
          const newServiceIds = service.SERVICE_IDS.includes(serviceId)
            ? service.SERVICE_IDS.filter((id) => id !== serviceId)
            : [...service.SERVICE_IDS, serviceId];
          return { ...service, SERVICE_IDS: newServiceIds };
        }
        return service;
      });
      return { ...prevData, serviceInfo: newServiceInfo };
    });
  };

  const handleSocialMediaChange = (index, field, value) => {
    setFormData((prevData) => {
      const newSocialMediaLinks = [...prevData.socialMediaLinks];
      newSocialMediaLinks[index] = {
        ...newSocialMediaLinks[index],
        [field]: value,
      };
      return { ...prevData, socialMediaLinks: newSocialMediaLinks };
    });
  };

  const handleAddSocialMediaLink = () => {
    setFormData((prevData) => ({
      ...prevData,
      socialMediaLinks: [...prevData.socialMediaLinks, { NAME: '', LINK: '' }],
    }));
  };

  const handleRemoveSocialMediaLink = (index) => {
    setFormData((prevData) => ({
      ...prevData,
      socialMediaLinks: prevData.socialMediaLinks.filter((_, i) => i !== index),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = new FormData();
    form.append('ID', business?.ID);
    form.append('BUSINESS_NUMBER', formData.businessNumber);
    form.append('BUSINESS_EMAIL', formData.businessEmail);
    form.append('LATTITUDE', 0);
    form.append('LONGITUDE', 0);
    form.append('SERVICE_INFO', JSON.stringify(formData.serviceInfo));
    form.append('CATEGORY', formData.category);
    form.append('PINCODE', formData.pincode);
    form.append('DESCRIPTION', formData.description);
    form.append('LOCATION_INFO', JSON.stringify(formData.locationInfo));
    form.append('EXPERIENCE', formData.experience);
    form.append(
      'SOCIAL_MEDIA_LINKS',
      JSON.stringify(formData.socialMediaLinks)
    );
    form.append('BUSINESS_NAME', formData.name);
    form.append('WEB_URL', formData.webURL);
    form.append('BUSINESS_ADDRESS', formData.address);
    form.append('USER_ID', userData?.USER_ID);

    if (formData.idCard) {
      form.append('IDCARD', formData.idCard[0]);
    }

    if (formData.addressProof) {
      form.append('ADDRPROOF', formData.addressProof[0]);
    }

    try {
      const res = await UpdateBusiness(form);
      if (res.success) {
        updateBusiness(res.data);
        onClose();
        toast.success(res.message);
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      console.error('Error updating business:', error);
      toast.error('An error occurred while updating the business.');
    }
  };

  return (
    <div className="fixed inset-0 z-[53] bg-gray-800 bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-6 rounded-md shadow-md w-full max-w-[80vw] max-h-[80vh] overflow-auto">
        <div className="flex justify-between items-center">
          <h2 className="text-2xl font-semibold mb-4">Update Business</h2>
          <FaXmark className="text-[24px] cursor-pointer" onClick={onClose} />
        </div>
        <form
          className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4"
          onSubmit={handleSubmit}
        >
          {[
            { label: 'Business Name', name: 'name', type: 'text' },
            { label: 'Business Number', name: 'businessNumber', type: 'text' },
            { label: 'Business Email', name: 'businessEmail', type: 'email' },
            { label: 'Address', name: 'address', type: 'text' },
            {
              label: 'Category',
              name: 'category',
              type: 'select',
              options: cat_list?.map((category) => ({
                value: category?.ID,
                label: category?.C_NAME,
              })),
            },
            { label: 'Pincode', name: 'pincode', type: 'text' },
            { label: 'Experience', name: 'experience', type: 'text' },
            { label: 'Web URL', name: 'webURL', type: 'url' },
            { label: 'ID Card', name: 'idCard', type: 'file' },
            { label: 'Address Proof', name: 'addressProof', type: 'file' },
            { label: 'Photos', name: 'photos', type: 'file', multiple: true },
            {
              label: 'Description',
              name: 'description',
              type: 'textarea',
              className: 'sm:col-span-2 md:col-span-3',
            },
            {
              label: 'Location Info',
              name: 'locationInfo',
              type: 'textarea',
              className: 'sm:col-span-2 md:col-span-3',
              rows: 3,
            },
          ].map(({ label, name, type, options, className = '', ...rest }) => (
            <div key={name} className={`flex flex-col ${className}`}>
              <label htmlFor={name} className="font-medium mb-1">
                {label}
              </label>
              {type === 'textarea' ? (
                <textarea
                  id={name}
                  name={name}
                  value={formData[name]}
                  onChange={handleChange}
                  className="border rounded-md p-2"
                  {...rest}
                />
              ) : type === 'select' ? (
                <select
                  id={name}
                  name={name}
                  value={formData[name]}
                  onChange={handleChange}
                  className="border rounded-md p-2"
                >
                  <option value="">Select {label}</option>
                  {options.map(({ value, label }) => (
                    <option key={value} value={value}>
                      {label}
                    </option>
                  ))}
                </select>
              ) : (
                <input
                  id={name}
                  name={name}
                  type={type}
                  value={type!=='file'?formData[name]:undefined}
                  onChange={handleChange}
                  className="border rounded-md p-2"
                  {...rest}
                />
              )}
            </div>
          ))}
          <div className="sm:col-span-2 md:col-span-3">
            <h3 className="font-medium mb-2">Services</h3>
            {formData?.serviceInfo?.map((service, indexx) => (
              <div key={indexx} className="mb-2">
                <div className="flex items-center">
                  <select
                    className="w-full p-2 border border-gray-300 rounded-md"
                    value={service.MAIN_SERVICE_ID}
                    onChange={(e) => handleServiceChange(e, indexx)}
                  >
                    <option value="">Select Main Service</option>
                    {subCategories.map((servicee, index) => {
                      let flag = false;
                      console.log(index);
                      for (let i = 0; i < indexx; i++) {
                        console.log(formData?.serviceInfo, i);
                        console.log(servicee?.S_NAME);
                        if (
                          formData?.serviceInfo[i]?.MAIN_SERVICE_ID ==
                          servicee?.ID
                        ) {
                          flag = true;
                          break;
                        }
                      }
                      return (
                        <>
                          {!flag ? (
                            <option key={servicee.ID} value={servicee.ID}>
                              {servicee.S_NAME}
                            </option>
                          ) : (
                            <></>
                          )}
                        </>
                      );
                    })}
                  </select>
                  <button
                    type="button"
                    className="ml-2 bg-red-500 text-white px-4 py-2 rounded-md"
                    onClick={() => handleRemoveService(indexx)}
                  >
                    Remove
                  </button>
                </div>
                {subCategoriesTwo[service.MAIN_SERVICE_ID] && (
                  <div className="flex flex-wrap mt-2">
                    {subCategoriesTwo[service.MAIN_SERVICE_ID].map(
                      (subService) => {
                        console.log(subService);
                        let flag = false;
                        for (
                          let i = 0;
                          i < subService?.SERVICE_INFO?.length;
                          i++
                        ) {
                          if (
                            subService?.SERVICE_INFO[i]?.ID ===
                            service?.MAIN_SERVICE_ID
                          ) {
                            flag = true;
                            break;
                          }
                        }
                        return (
                          <>
                            {flag ? (
                              <div key={subService.ID} className="mr-4 mb-2">
                                <input
                                  type="checkbox"
                                  id={`service-${subService.ID}`}
                                  value={subService.ID}
                                  checked={service.SERVICE_IDS.includes(
                                    subService.ID
                                  )}
                                  onChange={() =>
                                    handleCheckboxChange(
                                      subService.ID,
                                      service.MAIN_SERVICE_ID
                                    )
                                  }
                                />
                                <label
                                  htmlFor={`service-${subService.ID}`}
                                  className="ml-2"
                                >
                                  {subService.S_NAME}
                                </label>
                              </div>
                            ) : (
                              <></>
                            )}
                          </>
                        );
                      }
                    )}
                  </div>
                )}
              </div>
            ))}
            {formData?.serviceInfo?.length < subCategories.length && (
              <button
                type="button"
                className="bg-blue-500 text-white px-4 py-2 rounded-md mt-2"
                onClick={handleAddService}
              >
                Add Service
              </button>
            )}
          </div>
          <div className="sm:col-span-2 md:col-span-3">
            <h3 className="font-medium mb-2">Social Media Links</h3>
            {formData.socialMediaLinks.map((link, index) => (
              <div key={index} className="flex mb-2">
                <input
                  type="text"
                  placeholder="Name"
                  value={link.NAME}
                  onChange={(e) =>
                    handleSocialMediaChange(index, 'NAME', e.target.value)
                  }
                  className="border rounded-md p-2 flex-1 mr-2"
                />
                <input
                  type="text"
                  placeholder="Link"
                  value={link.LINK}
                  onChange={(e) =>
                    handleSocialMediaChange(index, 'LINK', e.target.value)
                  }
                  className="border rounded-md p-2 flex-2"
                />
                <button
                  type="button"
                  onClick={() => handleRemoveSocialMediaLink(index)}
                  className="text-red-500 ml-2"
                >
                  Remove
                </button>
              </div>
            ))}
            <button
              type="button"
              onClick={handleAddSocialMediaLink}
              className="text-blue-500"
            >
              Add Social Media Link
            </button>
          </div>
          <button
            type="submit"
            className="bg-blue-500 text-white py-2 px-4 rounded-md col-span-full mt-4"
          >
            Update Business
          </button>
        </form>
      </div>
    </div>
  );
};

export default UpdateBusinessModal;
