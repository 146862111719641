import React, { useState } from 'react';
import {
  IoIosArrowDropleftCircle,
  IoIosArrowDroprightCircle,
} from 'react-icons/io';
import { MdPreview } from 'react-icons/md';

const MAX_VISIBILITY = 2;

const Carousel = ({ children }) => {
  const [active, setActive] = useState(2);
  const count = React.Children.count(children);

  const updateActive = (newActive) => {
    setActive((newActive + count) % count);
  };

  return (
    <div className="carousel h-[800px]">
      <button
        className="nav left"
        onClick={() => updateActive(active + 1)}
        aria-label="Previous"
      >
        <IoIosArrowDropleftCircle className="text-[40px]" color="#0474ED" />
      </button>
      {React.Children.map(children, (child, i) => {
        const index = (i - active + count) % count; // Handle circular indexing
        return (
          <div
            className="card-container"
            style={{
              '--active': index === 0 ? 1 : 0,
              '--offset': (index - Math.floor(count / 2)) / 2,
              '--direction': Math.sign(index - Math.floor(count / 2)),
              '--abs-offset': Math.abs(index - Math.floor(count / 2)) / 3,
              // 'pointer-events': index === 0 ? 'auto' : 'none',
              opacity:
                Math.abs(index - Math.floor(count / 2)) >= MAX_VISIBILITY
                  ? '0'
                  : '1',
              display:
                Math.abs(index - Math.floor(count / 2)) > MAX_VISIBILITY
                  ? 'none'
                  : 'block',
            }}
          >
            {child}
          </div>
        );
      })}
      <button
        className="nav right"
        onClick={() => updateActive(active - 1)}
        aria-label="Next"
      >
        <IoIosArrowDroprightCircle className="text-[40px]" color="#0474ED" />
      </button>
    </div>
  );
};

export default Carousel;
