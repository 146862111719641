import React from 'react';
import Button from '../Button';
import Modal from '../Modall';
const ConfirmDeleteModal = ({ isOpen, onClose, onConfirm, businessName }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="p-4">
        <h2 className="text-lg font-semibold text-gray-800 mb-4">
          Confirm Delete
        </h2>
        <p>Are you sure you want to delete {businessName}?</p>
        <div className="mt-4 flex justify-end space-x-2">
          <Button onClick={onClose} title="Cancel" />
          <Button
            onClick={onConfirm}
            title="Delete"
            className="bg-red-500 text-white"
          />
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmDeleteModal;
