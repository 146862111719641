import React, { useState } from 'react';
import { AiOutlineMail, AiOutlineShareAlt } from 'react-icons/ai';
import { BiInfoCircle } from 'react-icons/bi';
import { FaFacebook, FaInstagram, FaLinkedin, FaYoutube } from 'react-icons/fa';
import { HiOutlineGlobeAlt } from 'react-icons/hi';
import { useLocation } from 'react-router-dom';
import ProviderTop from '../components/Provider/ProviderTop';
import ProviderAddress from '../components/Provider/ProviderAddress';
import RatingData from '../components/Provider/RatingData';
import ReviewData from '../components/Provider/ReviewData';
import ImageGalleryModal from '../components/Provider/ImageGalleryModal';

const ProviderDetail = () => {
  const { state } = useLocation();
  const { item } = state;
  const [isModalOpen, setModalOpen] = useState(false);
  console.log(item);

  const options = [
    // { icon: <AiOutlineMail />, label: "Send Enquiry by Email" },
    // { icon: <BiInfoCircle />, label: "Get info via SMS/Email" },
    { icon: <AiOutlineShareAlt />, label: 'Share this' },
    { icon: <HiOutlineGlobeAlt />, label: 'Visit our Website' },
    {
      icon: <FaYoutube />,
      label: 'Youtube',
      Link: item?.SOCIAL_MEDIA_LINKS?.[0]?.LINK,
    },
    {
      icon: <FaFacebook />,
      label: 'Facebook',
      Link: item?.SOCIAL_MEDIA_LINKS?.[1]?.LINK,
    },
    {
      icon: <FaLinkedin />,
      label: 'Linkedin',
      Link: item?.SOCIAL_MEDIA_LINKS?.[0]?.LINK,
    },
    {
      icon: <FaInstagram />,
      label: 'Instagram',
      Link: item?.SOCIAL_MEDIA_LINKS?.[2]?.LINK,
    },
  ];

  const handleImageClick = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <div className="mx-4 md:mx-10  flex flex-col gap-6">
      <ProviderTop item={item} />
      <div className="flex flex-wrap gap-4">
        <div className=" py-8 flex-[3] border rounded-[12px]">
          <div className="px-4 pb-6">
            <p className="text-xl font-[500]">Photos</p>
            <div className="flex gap-4">
              <div className="py-4 flex-col gap-2">
                <img
                  className="max-h-[150px] object-cover min-w-[300px] max-w-[300px] rounded-[12px] cursor-pointer"
                  src={`${process.env.REACT_APP_IMAGE_URL}${item?.WORK_GALLERY?.[0].path}`}
                  alt=""
                  onClick={handleImageClick}
                />
                <div className="antialiased tracking-tight font-poppins tap-highlight-transparent cursor-pointer text-left no-underline outline-none box-border text-gray-900 font-medium text-18 leading-tight w-full overflow-hidden whitespace-nowrap overflow-ellipsis block">
                  All
                </div>
                <div className="antialiased tracking-tight font-poppins tap-highlight-transparent cursor-pointer text-left no-underline outline-none box-border text-gray-700 font-normal text-14 leading-relaxed w-full overflow-hidden whitespace-nowrap overflow-ellipsis block">
                  {item?.WORK_GALLERY?.length} Photos
                </div>
              </div>
              {/* <div className="py-4 flex flex-col gap-2">
                <img
                  className="max-h-[150px] object-cover min-w-[300px] max-w-[300px] rounded-[12px]"
                  src={`${process.env.REACT_APP_IMAGE_URL}${item?.WORK_GALLERY?.[0].path}`}
                  alt=""
                  onClick={handleImageClick}
                />
                <div className="antialiased tracking-tight font-poppins tap-highlight-transparent cursor-pointer text-left no-underline outline-none box-border text-gray-900 font-medium text-18 leading-tight w-full overflow-hidden whitespace-nowrap overflow-ellipsis block">
                  All
                </div>
                <div className="antialiased tracking-tight font-poppins tap-highlight-transparent cursor-pointer text-left no-underline outline-none box-border text-gray-700 font-normal text-14 leading-relaxed w-full overflow-hidden whitespace-nowrap overflow-ellipsis block">
                  {item?.WORK_GALLERY?.length} Photos / Videos
                </div>
              </div> */}
            </div>
            {/* <div
              role="button"
              aria-label="Upload Photos"
              tabIndex="0"
              title="Upload Photos"
              className="antialiased text-white tracking-tight text-11 font-poppins tap-highlight-transparent no-underline outline-none box-border mt-5 mb-2 inline-flex w-45 h-11 rounded-md bg-blue-600 items-center justify-center relative overflow-hidden cursor-pointer transition-all duration-800"
              style={{ width: "180px", height: "45px" }}
            >
              <span className="mr-2">
                <FaUpload />
              </span>
              <span className="text-16 font-normal text-white">
                Upload Photos
              </span>
            </div> */}
          </div>
          {/* <CatAndSubCat /> */}
          <RatingData item={item} />
          <ReviewData item={item} />
        </div>
        <ProviderAddress item={item} options={options} />
      </div>
      <ImageGalleryModal
        open={isModalOpen}
        onClose={handleCloseModal}
        images={item?.WORK_GALLERY || []}
      />
    </div>
  );
};

export default ProviderDetail;
