import React from 'react';

const UpdateProfileModal = ({
  data,
  social,
  handleInputChange,
  handleSocialChange,
  onUpdate,
  setIsModalOpen,
  loading,
}) => {
  return (
    <div className="fixed inset-0 z-50 bg-black bg-opacity-50 overflow-auto  flex items-center justify-center">
      <div className="relative p-8 bg-white w-full max-w-md m-auto flex-col flex rounded-lg shadow-lg">
        <button
          className="absolute top-0 right-0 mt-4 mr-4 text-gray-600 hover:text-gray-900"
          onClick={() => setIsModalOpen(false)}
        >
          X
        </button>
        <h2 className="text-2xl font-semibold mb-4">Update Profile</h2>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onUpdate();
          }}
        >
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Full Name:
            </label>
            <input
              type="text"
              name="FULL_NAME"
              value={data.FULL_NAME || ''}
              onChange={handleInputChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
            {data.FULL_NAMEMess && (
              <span className="text-red-500 text-xs">{data.FULL_NAMEMess}</span>
            )}
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Phone Number:
            </label>
            <input
              type="text"
              name="PHONE_NUMBER"
              value={data.PHONE_NUMBER || ''}
              onChange={handleInputChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
            {data.PHONE_NUMBERMess && (
              <span className="text-red-500 text-xs">
                {data.PHONE_NUMBERMess}
              </span>
            )}
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Gender:
            </label>
            <input
              type="text"
              name="GENDER"
              value={data.GENDER || ''}
              onChange={handleInputChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
            {data.GENDERMess && (
              <span className="text-red-500 text-xs">{data.GENDERMess}</span>
            )}
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Email Address:
            </label>
            <input
              type="text"
              name="EMAIL_ADDRESS"
              value={data.EMAIL_ADDRESS || ''}
              onChange={handleInputChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Social Media Links:
            </label>
            {social.map((socialItem, index) => (
              <div key={index} className="mb-2">
                <span className="block text-gray-700 text-xs font-bold mb-1">
                  {socialItem.name}
                </span>
                <input
                  type="text"
                  name="link"
                  value={socialItem.link}
                  onChange={(e) => handleSocialChange(index, e)}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
            ))}
          </div>
          <button
            type="submit"
            disabled={loading}
            className={`w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
          >
            {loading ? 'Updating...' : 'Update Profile'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default UpdateProfileModal;
