import React, { createContext, useContext } from 'react';

// Create a context for the scroll function
const ScrollToTopContext = createContext();

// Create a provider component
export const ScrollToTopProvider = ({ children }) => {
  console.log('abishe');
  // Function to scroll to the top
  const scrollToTop = () => {
    console.log('akf');
    window.scrollTo({ top: 10, behavior: 'smooth' });
  };

  return (
    <ScrollToTopContext.Provider value={scrollToTop}>
      {children}
    </ScrollToTopContext.Provider>
  );
};

// Custom hook to use the scroll function
export const useScrollToTop = () => {
  return useContext(ScrollToTopContext);
};
