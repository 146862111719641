// src/routes.js
export const routes = {
  home: '/',
  blogs: '/blogs',
  profile: '/profile',
  aboutUs: '/about-us',
  connects: '/connects',
  yourBusiness: '/your-business',
  notifications: '/notifications',
  contact: '/contact',
  whyMyCallBook: '/why-mycall-book',
  subCategory: '/:id/sub-categories',
  subCategory2: '/:id/:id/sub-categories2',
  providerList: '/:id/service-providers',
  planAndPricing: '/plan-and-pricing',
  providerList1: '/:id/service-providers',
  providerList2: '/:id/:id/service-providers',
  providerList3: '/:id/:id/:id/service-providers',
  providerDetail: '/:id/service-providers/:name',
  termsandconditions: '/terms',
  privacypolicy: '/privacy-policy',
  pageNotFound: '/*',
};
