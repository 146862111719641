import React from 'react';
import no_data from '../assets/images/No data-rafiki.svg';
const Nodata = () => {
  return (
    <div className="h-[calc(100vh-100px)] w-[100vw] flex justify-center items-center text-[32px] font-semibold">
      <img className="h-[80%]" src={no_data} alt="" />
    </div>
  );
};

export default Nodata;
