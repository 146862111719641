import React, { useEffect, useState } from 'react';
import { Profile, UpdateProfile, UpdateProfileImage } from '../apis/api';
import ProfileCard from '../components/profile/ProfileCard';
import ProfileDetails from '../components/profile/ProfileDetails';
import UpdateProfileImageModal from '../components/profile/UpdateProfileImageModal';
import ChangePasswordModal from '../components/profile/ChangePasswordModal';
import UpdateProfileModal from '../components/profile/UpdateProfileModal';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useLoader from '../hooks/useLoader';
import { setLoginPopup } from '../redux/slice/userSlice';

const UserProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = useSelector((state) => state.user.userData);
  const loggedIn = useSelector((state) => state.user.loggedIn);
  const setLoader = useLoader();

  const [data, setData] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const [social, setSocial] = useState([
    { name: 'Youtube', link: '' },
    { name: 'Facebook', link: '' },
    { name: 'Instagram', link: '' },
  ]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!loggedIn) {
      dispatch(setLoginPopup(true));
      navigate('/');
      return;
    }
    handleData();
  }, [loggedIn,dispatch]);

  const handleData = async () => {
    setLoader(true);
    console.log('abhishek')
    const res = await Profile();
    console.log(res)
    setLoader(false);
    setData(res);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSocialChange = (index, e) => {
    const { name, value } = e.target;
    const updatedSocial = social.map((socialItem, i) =>
      i === index ? { ...socialItem, [name]: value } : socialItem
    );
    setSocial(updatedSocial);
  };

  const onUpdate = async () => {
    if (
      !data.FULL_NAME ||
      !data.PHONE_NUMBER ||
      (data.PHONE_NUMBER && data.PHONE_NUMBER.length < 10) ||
      !data.GENDER
    ) {
      setData({
        ...data,
        FULL_NAMEMess: !data.FULL_NAME ? 'Full name is required' : '',
        GENDERMess: !data.GENDER ? 'Gender is required' : '',
        PHONE_NUMBERMess: !data.PHONE_NUMBER
          ? 'Mobile number is required'
          : data.PHONE_NUMBER && data.PHONE_NUMBER.length < 10
            ? 'Invalid Mobile number'
            : '',
      });
      return;
    }
    setLoading(true);
    const raw = JSON.stringify({
      FULL_NAME: data.FULL_NAME,
      PHONE_NUMBER: data.PHONE_NUMBER,
      GENDER: data.GENDER,
      EMAIL_ADDRESS: data.EMAIL_ADDRESS,
      SOCIAL_MEDIA_LINKS: social,
    });
    const res = await UpdateProfile(raw, userData?.USER_ID);
    setLoading(false);
    if (res.success) {
      toast.success(res.message);
      handleData(); // Refresh profile data
      setIsProfileModalOpen(false); // Close modal after successful update
    } else {
      toast.error(res.message, 'error');
    }
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleImageUpload = async () => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append('image', selectedFile);
      setLoader(true);
      const res = await UpdateProfileImage(formData);
      setLoader(false);
      if (res.success) {
        handleData(); // Re-fetch profile data to get the updated image
        setIsImageModalOpen(false);
        toast.success(res.message);
      } else toast.error(res.message);
    }
  };

  if (!loggedIn) {
    return null;
  }

  return (
    <div className="container mx-auto p-5 bg-gray-100 text-gray-800">
      <div className="main-body">
        <div className="flex flex-wrap justify-center -mx-3">
          <div className="w-full md:w-1/4 mb-6 px-3">
            <ProfileCard data={data} setIsModalOpen={setIsImageModalOpen} />
          </div>
          <div className="w-full md:w-2/3 px-3">
            <ProfileDetails
              data={data}
              setIsProfileModalOpen={setIsProfileModalOpen}
              setIsPasswordModalOpen={setIsPasswordModalOpen}
            />
          </div>
        </div>
      </div>

      {isImageModalOpen && (
        <UpdateProfileImageModal
          handleFileChange={handleFileChange}
          handleImageUpload={handleImageUpload}
          setIsModalOpen={setIsImageModalOpen}
        />
      )}

      {isPasswordModalOpen && (
        <ChangePasswordModal setIsPasswordModalOpen={setIsPasswordModalOpen} />
      )}

      {isProfileModalOpen && (
        <UpdateProfileModal
          data={data}
          social={social}
          handleInputChange={handleInputChange}
          handleSocialChange={handleSocialChange}
          onUpdate={onUpdate}
          setIsModalOpen={setIsProfileModalOpen}
          loading={loading}
        />
      )}
    </div>
  );
};

export default UserProfile;

// import React, { useEffect, useState } from "react";
// import { Profile, UpdateProfile, UpdateProfileImage } from "../apis/api";

// const UserProfile = () => {
//   const [password, setPassword] = useState("password");
//   const [flag, setFlag] = useState(false);
//   const [data, setData] = useState({});
//   const [selectedFile, setSelectedFile] = useState(null);
//   const [isModalOpen, setIsModalOpen] = useState(false);

//   const handleData = async () => {
//     const res = await Profile();
//     console.log(res);
//     setData(res);
//   };

//   const handleFileChange = (e) => {
//     setSelectedFile(e.target.files[0]);
//   };

//   const handleProfileEdit=async()=>{
//     const res=await UpdateProfile()
//   }

//   const handleImageUpload = async () => {
//     if (selectedFile) {
//       const formData = new FormData();
//       formData.append("image", selectedFile);

//       const res = await UpdateProfileImage(formData);
//       if (res.success) {
//         handleData(); // Re-fetch profile data to get the updated image
//         setIsModalOpen(false); // Close modal after successful upload
//       }
//     }
//   };

//   useEffect(() => {
//     handleData();
//   }, []);

//   return (
//     <div className="container mx-auto p-5 bg-gray-100 text-gray-800">
//       <div className="main-body">
//         <div className="flex flex-wrap -mx-3">
//           <div className="w-full md:w-1/3 mb-6 px-3">
//             <div className="bg-white rounded-lg shadow p-5">
//               <div className="text-center">
//                 <img
//                   src={
//                     data?.IMAGE_URL
//                       ? `${process.env.REACT_APP_IMAGE_URL}${data?.IMAGE_URL}`
//                       : "https://bootdey.com/img/Content/avatar/avatar7.png"
//                   }
//                   alt="Admin"
//                   className="rounded-full w-32 h-32 mx-auto"
//                 />
//                 <div className="mt-3">
//                   <h4>{data?.FULL_NAME}</h4>
//                   <p className="text-gray-600 mb-1">Full Stack Developer</p>
//                   <p className="text-gray-500 text-sm">
//                     Bay Area, San Francisco, CA
//                   </p>
//                 </div>
//                 <button
//                   onClick={() => setIsModalOpen(true)}
//                   className="btn btn-primary bg-blue-500 hover:bg-blue-700 text-white px-4 py-2 rounded mt-2"
//                 >
//                   Update Profile Image
//                 </button>
//               </div>
//             </div>
//             <div className="bg-white rounded-lg shadow mt-6 p-5">
//               <ul className="list-none">
//                 <li className="flex justify-between items-center mb-3">
//                   <h6 className="text-gray-800">Website</h6>
//                   <span className="text-gray-600">https://bootdey.com</span>
//                 </li>
//                 <li className="flex justify-between items-center mb-3">
//                   <h6 className="text-gray-800">Github</h6>
//                   <span className="text-gray-600">bootdey</span>
//                 </li>
//                 <li className="flex justify-between items-center mb-3">
//                   <h6 className="text-gray-800">Twitter</h6>
//                   <span className="text-gray-600">@bootdey</span>
//                 </li>
//                 <li className="flex justify-between items-center mb-3">
//                   <h6 className="text-gray-800">Instagram</h6>
//                   <span className="text-gray-600">bootdey</span>
//                 </li>
//                 <li className="flex justify-between items-center mb-3">
//                   <h6 className="text-gray-800">Facebook</h6>
//                   <span className="text-gray-600">bootdey</span>
//                 </li>
//               </ul>
//             </div>
//           </div>
//           <div className="w-full md:w-2/3 px-3">
//             <div className="bg-white rounded-lg shadow p-5 mb-6">
//               <div className="flex flex-wrap">
//                 <div className="w-full md:w-1/4">
//                   <h6 className="text-gray-800">Full Name</h6>
//                 </div>
//                 <div className="w-full md:w-3/4 text-gray-600">
//                   {data?.FULL_NAME}
//                 </div>
//               </div>
//               <hr className="my-4" />
//               <div className="flex flex-wrap">
//                 <div className="w-full md:w-1/4">
//                   <h6 className="text-gray-800">Email</h6>
//                 </div>
//                 <div className="w-full md:w-3/4 text-gray-600">
//                   {data?.EMAIL_ADDRESS}
//                 </div>
//               </div>
//               <hr className="my-4" />
//               <div className="flex flex-wrap">
//                 <div className="w-full md:w-1/4">
//                   <h6 className="text-gray-800">Phone</h6>
//                 </div>
//                 <div className="w-full md:w-3/4 text-gray-600">
//                   {data?.PHONE_NUMBER}
//                 </div>
//               </div>
//               <hr className="my-4" />
//               <div className="flex flex-wrap">
//                 <div className="w-full md:w-1/4">
//                   <h6 className="text-gray-800">Gender</h6>
//                 </div>
//                 <div className="w-full md:w-3/4 text-gray-600">
//                   {data?.GENDER}
//                 </div>
//               </div>
//               <hr className="my-4" />
//               <div className="flex flex-wrap">
//                 <div className="w-full md:w-1/4">
//                   <h6 className="text-gray-800">Address</h6>
//                 </div>
//                 <div className="w-full md:w-3/4 text-gray-600">
//                   Bay Area, San Francisco, CA
//                 </div>
//               </div>
//               <hr className="my-4" />
//               <div className="flex flex-wrap">
//                 <div className="w-full md:w-1/4">
//                   <h6 className="text-gray-800">Password</h6>
//                 </div>
//                 <div className="w-full md:w-3/4 text-gray-600">
//                   <input
//                     type={flag ? "text" : "password"}
//                     className="p-2 outline-none border rounded-[12px]"
//                     disabled={!flag}
//                     value={password}
//                     onChange={(e) => {
//                       setPassword(e.target.value);
//                     }}
//                   />
//                 </div>
//               </div>
//               <hr className="my-4" />
//               <div className="flex justify-end">
//                 <button className="btn btn-info bg-blue-500 hover:bg-blue-700 text-white px-4 py-2 rounded">
//                   Edit
//                 </button>
//                 <button
//                   onClick={() => setFlag(!flag)}
//                   className="btn btn-warning bg-yellow-500 hover:bg-yellow-700 text-white px-4 py-2 rounded ml-2"
//                 >
//                   {flag ? "Save" : "Change Password"}
//                 </button>
//               </div>
//             </div>

//             <div className="flex flex-wrap -mx-3">
//               <div className="w-full md:w-1/2 px-3 mb-6">
//                 <div className="bg-white rounded-lg shadow p-5 h-full">
//                   <h6 className="flex items-center text-gray-800 mb-3">
//                     <i className="material-icons text-blue-500 mr-2">
//                       assignment
//                     </i>
//                     Project Status
//                   </h6>
//                   <div className="mb-4">
//                     <small className="text-gray-600">Web Design</small>
//                     <div className="w-full bg-gray-200 h-1">
//                       <div
//                         className="bg-blue-500 h-1"
//                         style={{ width: "80%" }}
//                       ></div>
//                     </div>
//                   </div>
//                   <div className="mb-4">
//                     <small className="text-gray-600">Website Markup</small>
//                     <div className="w-full bg-gray-200 h-1">
//                       <div
//                         className="bg-blue-500 h-1"
//                         style={{ width: "72%" }}
//                       ></div>
//                     </div>
//                   </div>
//                   <div className="mb-4">
//                     <small className="text-gray-600">One Page</small>
//                     <div className="w-full bg-gray-200 h-1">
//                       <div
//                         className="bg-blue-500 h-1"
//                         style={{ width: "89%" }}
//                       ></div>
//                     </div>
//                   </div>
//                   <div className="mb-4">
//                     <small className="text-gray-600">Mobile Template</small>
//                     <div className="w-full bg-gray-200 h-1">
//                       <div
//                         className="bg-blue-500 h-1"
//                         style={{ width: "55%" }}
//                       ></div>
//                     </div>
//                   </div>
//                   <div>
//                     <small className="text-gray-600">Backend API</small>
//                     <div className="w-full bg-gray-200 h-1">
//                       <div
//                         className="bg-blue-500 h-1"
//                         style={{ width: "66%" }}
//                       ></div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               <div className="w-full md:w-1/2 px-3 mb-6">
//                 <div className="bg-white rounded-lg shadow p-5 h-full">
//                   <h6 className="flex items-center text-gray-800 mb-3">
//                     <i className="material-icons text-blue-500 mr-2">
//                       assignment
//                     </i>
//                     Project Status
//                   </h6>
//                   <div className="mb-4">
//                     <small className="text-gray-600">Web Design</small>
//                     <div className="w-full bg-gray-200 h-1">
//                       <div
//                         className="bg-blue-500 h-1"
//                         style={{ width: "80%" }}
//                       ></div>
//                     </div>
//                   </div>
//                   <div className="mb-4">
//                     <small className="text-gray-600">Website Markup</small>
//                     <div className="w-full bg-gray-200 h-1">
//                       <div
//                         className="bg-blue-500 h-1"
//                         style={{ width: "72%" }}
//                       ></div>
//                     </div>
//                   </div>
//                   <div className="mb-4">
//                     <small className="text-gray-600">One Page</small>
//                     <div className="w-full bg-gray-200 h-1">
//                       <div
//                         className="bg-blue-500 h-1"
//                         style={{ width: "89%" }}
//                       ></div>
//                     </div>
//                   </div>
//                   <div className="mb-4">
//                     <small className="text-gray-600">Mobile Template</small>
//                     <div className="w-full bg-gray-200 h-1">
//                       <div
//                         className="bg-blue-500 h-1"
//                         style={{ width: "55%" }}
//                       ></div>
//                     </div>
//                   </div>
//                   <div>
//                     <small className="text-gray-600">Backend API</small>
//                     <div className="w-full bg-gray-200 h-1">
//                       <div
//                         className="bg-blue-500 h-1"
//                         style={{ width: "66%" }}
//                       ></div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>

//       {/* Modal */}
//       {isModalOpen && (
//         <div className="fixed inset-0 flex items-center justify-center z-50">
//           <div className="bg-black bg-opacity-50 absolute inset-0"></div>
//           <div className="bg-white rounded-lg shadow-lg p-6 z-10 w-1/3">
//             <h2 className="text-lg font-semibold mb-4">Update Profile Image</h2>
//             <input type="file" onChange={handleFileChange} className="mb-4" />
//             <div className="flex justify-end">
//               <button
//                 onClick={handleImageUpload}
//                 className="btn btn-primary bg-blue-500 hover:bg-blue-700 text-white px-4 py-2 rounded mr-2"
//               >
//                 Upload
//               </button>
//               <button
//                 onClick={() => setIsModalOpen(false)}
//                 className="btn btn-secondary bg-gray-500 hover:bg-gray-700 text-white px-4 py-2 rounded"
//               >
//                 Cancel
//               </button>
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default UserProfile;
