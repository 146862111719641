import React, { useEffect } from 'react';
import { ReadNotifications } from '../apis/api';
import { Image, Modal } from 'antd';
import { useDispatch } from 'react-redux';
import { fetchNotifications } from '../redux/slice/userSlice';

const NotificationModal = ({
  isModalOpen,
  setIsModalOpen,
  title,
  imageUrl,
  desc,
  id,
}) => {
  const dispatch = useDispatch();
  console.log(id);

  useEffect(() => {
    if (isModalOpen) {
      const handleRead = async () => {
        await ReadNotifications(id);
      };
      handleRead();
    }
    dispatch(fetchNotifications());
  }, [isModalOpen, id]);

  //   if (!isOpen) return null;
  //   const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  console.log(imageUrl.split('/'));
  console.log(imageUrl);
  return (
    <Modal
      title="Notification Detail"
      footer={null}
      centered
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="p-5 flex gap-6  relative"
      >
        <div>
          {imageUrl && (
            <Image
              onClick={(e) => e.stopPropagation()}
              src={imageUrl}
              alt="Notification"
              width={100}
              //   className="w-[100px] h-auto rounded-md"
            />
          )}
        </div>

        <div>
          <h2 className="text-xl font-semibold mb-2">{title}</h2>
          <p>{desc}</p>
        </div>
      </div>
    </Modal>
    // <div onClick={()=>onClose(false)} className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
    //   <div  onClick={(e)=>e.stopPropagation()} className="bg-white p-5 flex gap-6 rounded-lg shadow-lg relative">
    //   <Image
    //  onClick={(e)=>e.stopPropagation()}
    //       src={imageUrl}
    //       alt="Notification"
    //       width={100}
    //     //   className="w-[100px] h-auto rounded-md"
    //     />
    //     <div>
    //     <h2 className="text-xl font-semibold mb-2">{title}</h2>
    //     <p>{desc}</p>
    //     </div>

    //   </div>
    // </div>
  );
};

export default NotificationModal;
