import React from 'react';
import toast from 'react-hot-toast';
import { FaCheck } from 'react-icons/fa6';
import { useSelector } from 'react-redux';

function Card({ cardData, planType }) {
  const loggedIn = useSelector((state) => state.user?.loggedIn);
  return (
    <div className="min-w-[350px] w-[400px] max-w-[94vw] border-[0.5px]  p-4 min-h-[550px] flex flex-col bg-[#F7F9F9] shadow-xl rounded-lg text-center overflow-hidden mb-4 lg:mb-0">
      <div className="bg-[#1b7ec0] w-fit text-white px-2 rounded-[16px] py-1">
        <h2 className="text-[18px] font-semibold">
          {cardData?.PLAN_NAME?.toUpperCase()}
        </h2>
      </div>
      <div className="py-4 pb-2 flex-1 flex flex-col ">
        <li className="py-2 list-none text-start  border-gray-300">
          {cardData.PLAN_DESCRIPTION}
        </li>
        <div className="bg-[#F7F9F9] py-2 text-2xl text-start font-medium">
          ₹{cardData.PLAN_PRICE}/
          <span className="text-gray-500 text-[16px]">
            {Math.floor(cardData?.PLAN_DURATION / 30)} month
          </span>
        </div>
        <ul className="space-y-4 my-4">
          <div className="flex border-t pt-4 gap-4">
            <FaCheck className="text-green-600 text-[24px] font-bold" />
            <li className="  border-gray-300 text-start">{cardData.LINE_1}</li>
          </div>
          <div className="flex gap-4">
            <FaCheck className="text-green-600 text-[24px] font-bold" />
            <li className="  border-gray-300 text-start">{cardData.LINE_2}</li>
          </div>
          <div className="flex gap-4">
            <FaCheck className="text-green-600 text-[24px] font-bold" />
            <li className="  border-gray-300 text-start">{cardData.LINE_3}</li>
          </div>
          {/* <li className={`py-2 border-t border-gray-300 ${cardData.subDomain}`}>
            <i className={`mr-2 ${cardData.subDomainIcon}`}></i>
            {cardData.domain}
          </li> */}
          {/* <li className={`py-2 border-t border-gray-300 ${cardData.monthly}`}>
            <i className={`mr-2 ${cardData.monthlyIcon}`}></i>Monthly Status Reports
          </li> */}
        </ul>
        <div className="flex justify-center mt-auto">
          <button
            onClick={() =>
              toast.success(
                `Your ${cardData?.PLAN_NAME} plan will be activated shortly.`
              )
            }
            className="btn mt-auto cursor-pointer text-black w-fit px-10 font-semibold hover:text-white py-2 rounded-[20px] border-2 border-[#3AB0CC] transition-all duration-300 ease-in-out hover:bg-[#3AB0CC]  shadow-lg hover:shadow-xl"
          >
            {!loggedIn ? 'Sign Up / Login' : 'Subscribe Now'}
          </button>
        </div>
      </div>
    </div>
  );
}

export default Card;
