import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { store } from '../redux/store';
import { fetchCatList } from '../redux/slice/dataSlice';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';

const HomeCategory = () => {
  const { cat_list } = useSelector((state) => state.alldata);
  const navigate = useNavigate();

  const handleClick = (id, item) => {
    navigate(`/${id}/sub-categories`, { state: item });
  };
  useEffect(() => {
    store.dispatch(fetchCatList());
  }, []);
  return (
    <div className="grid grid-cols-3 md:grid-cols-5 lg:grid-cols-8 gap-8 xl:grid-cols-10 2xl:grid-cols-12">
      {cat_list?.map((item, index) => (
        <div
          key={index}
          onClick={() =>
            handleClick(item.C_NAME?.trim().replace(/ /g, '-'), item)
          }
          className="flex flex-col items-center gap-2 cursor-pointer relative group"
        >
          {/* group-hover:box_shadow1 box_shadow */}
          <div className="border border-[#0474ED] h-24 w-24 rounded-lg bg-white items-center justify-center flex transition-all duration-300 ease-in-out  overflow-visible">
            <img
              src={process.env.REACT_APP_IMAGE_URL + item.WEB_URL}
              className="h-[60%] w-[60%] object-cover rounded-[15px] transition-all duration-300 ease-in-out group-hover:h-[70%] group-hover:w-[70%]"
              data-tooltip-id="pincodeTooltip"
              data-tooltip-content={item.C_NAME}
              data-tooltip-place="bottom-start"
            />
            <Tooltip
              className="!z-[51] relative right-[100%]"
              id="pincodeTooltip"
              place="bottom"
              effect="solid"
            />
          </div>
          <p className="text-center  text-[14px]">{item.C_NAME}</p>
        </div>
      ))}
    </div>
  );
};

export default HomeCategory;
