import React from 'react';

const WhyMyCallBook = () => {
  return (
    <div className="p-6 md:p-12 bg-gray-100 min-h-screen">
      <div className="max-w-2xl mx-auto bg-white p-8 rounded-lg shadow-lg">
        <h1 className="text-2xl md:text-3xl font-bold mb-4">Why MyCallBook</h1>
        <p className="text-gray-700 mb-6">
          At My Call Book, we understand the challenges of finding reliable
          service providers for everyday needs, whether it’s painting, plumbing,
          carpentry, IT services, or any other type of service. Our platform
          bridges the gap between service providers and users, offering a
          seamless and efficient way to connect with professionals in your local
          area. Here’s why you should choose My Call Book:
        </p>
        <ul className="list-disc pl-5 space-y-4">
          <li>
            <strong>Comprehensive Listings:</strong> We provide a wide range of
            service providers across various categories, ensuring that you find
            the right professional for your specific needs.
          </li>
          <li>
            <strong>Location-Based Search:</strong> Easily find service
            providers near you. Our location-based search ensures you get quick
            and convenient services.
          </li>
          <li>
            <strong>Verified Providers:</strong> We ensure that all listed
            service providers are verified and trusted, giving you peace of
            mind.
          </li>
          <li>
            <strong>Direct Communication:</strong> Contact service providers
            directly through our platform via chat or call, making it easy to
            get quotes, discuss requirements, and schedule services.
          </li>
          <li>
            <strong>User Reviews and Ratings:</strong> Read reviews and ratings
            from other users to help you make informed decisions.
          </li>
          <li>
            <strong>Ease of Use:</strong> Our user-friendly interface makes it
            simple to browse, connect, and hire professionals.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default WhyMyCallBook;
