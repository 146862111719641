import React, { useState, useEffect } from 'react';
import AddBusinessModal from '../components/AddBusinessModal';
import { useNavigate } from 'react-router-dom';
import { DeleteBusiness, GetBusinessInfo, GetBusinessList } from '../apis/api';
import { useDispatch, useSelector } from 'react-redux';
import { MdDelete, MdPermMedia } from 'react-icons/md';
import image from '../assets/images/fake.jpeg';
import useLoader from '../hooks/useLoader';
import toast from 'react-hot-toast';
import ConfirmDeleteModal from '../components/Provider/ConfirmDeleteModal';
import { FaEdit } from 'react-icons/fa';
import Button from '../components/Button';
import { setLoginPopup } from '../redux/slice/userSlice';
import Nodata from '../components/Nodata';
import UpdateBusinessModal from '../components/UpdateBusinessModal.jsx';
import ImageGalleryBusinessModal from '../components/Provider/ImageGalleryBusiness.jsx';

const BusinessList = () => {
  const [businesses, setBusinesses] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filter, setFilter] = useState('All'); // Add state for filter
  const [type, setType] = useState('Add');
  const navigate = useNavigate();
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isGalleryModalOpen, setIsGalleryModalOpen] = useState(false);
  const [selectedBusiness, setSelectedBusiness] = useState(null);
  const [deletedimg, setDeletedimg] = useState(false);
  const [updateData, setUpdateData] = useState({});
  const [WORK_GALLERY, setWORK_GALLERY] = useState([]);
  const userData = useSelector((state) => state.user.userData);
  const loggedIn = useSelector((state) => state.user.loggedIn);
  const dispatch = useDispatch();
  const setLoading = useLoader();

  useEffect(() => {
    if (!loggedIn) {
      dispatch(setLoginPopup(true));
      navigate('/');
    } else {
      handleData();
    }
  }, [loggedIn, dispatch, navigate, deletedimg]);

  const handleData = async () => {
    setLoading(true);
    const res = await GetBusinessList({ USER_ID: userData?.USER_ID });
    setBusinesses(res);
    setLoading(false);
  };

  const addBusiness = (newBusiness) => {
    setBusinesses([
      ...businesses,
      { ...newBusiness, id: businesses?.length + 1 },
    ]);
    setIsAddModalOpen(false);
  };

  const updateBusiness = (updatedBusiness) => {
    setBusinesses((prevBusinesses) =>
      prevBusinesses.map((business) =>
        business._id === updatedBusiness._id ? updatedBusiness : business
      )
    );
    setIsUpdateModalOpen(false);
  };

  const filteredBusinesses = businesses?.filter((business) => {
    return (
      business.BUSINESS_NAME.toLowerCase().includes(searchTerm.toLowerCase()) &&
      (filter === 'All' ||
        (filter === 'Approved' && business.IS_VERIFY === 1) ||
        (filter === 'Pending' && business.IS_VERIFY === 0))
    );
  });

  const confirmDelete = (business) => {
    setSelectedBusiness(business);
    setIsDeleteModalOpen(true);
  };

  const handleDelete = async () => {
    setLoading(true);
    const res = await DeleteBusiness(selectedBusiness?.ID);
    setLoading(false);
    if (res.success) {
      toast.success(res.message);
      await handleData();
    } else {
      toast.error(res.message);
    }
    setIsDeleteModalOpen(false);
  };

  const handleEdit = async (business) => {
    const res = await GetBusinessInfo(business?.ID);
    setIsUpdateModalOpen(true);
    setUpdateData(res?.data[0]);
  };

  return (
    <div className="min-h-screen bg-gray-50 p-6 md:p-12">
      <header className="bg-gradient-to-r from-blue-500 to-purple-500 shadow-lg p-6 rounded-lg mb-8">
        <div className="flex items-center justify-between">
          <h1 className="text-3xl font-bold text-white">
            Business Listings
          </h1>
          <Button
            className="ml-auto bg-white text-blue-600 shadow hover:bg-blue-100 transition duration-300"
            title="Add Business"
            onClick={() => {
              setSelectedBusiness(null);
              setType('Add');
              setIsAddModalOpen(true);
            }}
          />
        </div>
      </header>

      <div className="mb-8 flex space-x-4">
        <input
          type="text"
          placeholder="Search businesses..."
          className="w-full p-4 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <select
          className="p-4 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
        >
          <option value="All">All</option>
          <option value="Approved">Approved</option>
          <option value="Pending">Pending</option>
        </select>
      </div>

      {filteredBusinesses?.length > 0 ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {filteredBusinesses.map((business) => (
            <div
              key={business._id}
              className="bg-white cursor-pointer p-6 rounded-lg shadow-lg transform transition duration-300 hover:scale-105"
              onClick={() =>
                navigate(`/:id/service-providers/:name`, {
                  state: { item: business },
                })
              }
            >
              <img
                src={
                  process.env.REACT_APP_IMAGE_URL +
                    business.WORK_GALLERY[0]?.path || image
                }
                alt={business.BUSINESS_NAME}
                className="w-full h-56 object-cover rounded-lg mb-4"
              />
              <div className="flex justify-between items-center mb-2">
                <h2 className="text-xl font-semibold text-gray-800">
                  {business.BUSINESS_NAME}
                </h2>
                <span
                  className={`px-3 py-1 rounded-full text-sm font-medium ${
                    business.IS_VERIFY === 0
                      ? 'bg-red-100 text-red-600'
                      : 'bg-green-100 text-green-600'
                  }`}
                >
                  {business.IS_VERIFY === 0 ? 'Pending' : 'Approved'}
                </span>
              </div>
              <p className="text-gray-600">{business.DESCRIPTION}</p>
              <p className="text-gray-500 mt-2">{business.BUSINESS_ADDRESS}</p>
              <div className="flex justify-end items-center space-x-4 mt-4">
                <MdDelete
                  onClick={(e) => {
                    e.stopPropagation();
                    confirmDelete(business);
                  }}
                  className="cursor-pointer text-red-600 hover:text-red-800 text-2xl"
                />
                <FaEdit
                  onClick={(e) => {
                    e.stopPropagation();
                    handleEdit(business);
                  }}
                  className="cursor-pointer text-blue-600 hover:text-blue-800 text-2xl"
                />
                <MdPermMedia
                  onClick={(e) => {
                    e.stopPropagation();
                    setWORK_GALLERY(business?.WORK_GALLERY);
                    setIsGalleryModalOpen(true);
                    setSelectedBusiness(business);
                  }}
                  className="cursor-pointer text-green-600 hover:text-green-800 text-2xl"
                />
              </div>
            </div>
          ))}
        </div>
      ) : (
        <Nodata />
      )}

      <AddBusinessModal
        isOpen={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
        addBusiness={addBusiness}
      />
      <UpdateBusinessModal
        isOpen={isUpdateModalOpen}
        onClose={() => setIsUpdateModalOpen(false)}
        updateBusiness={updateBusiness}
        type={type}
        business={updateData}
      />
      <ConfirmDeleteModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onConfirm={handleDelete}
        businessName={selectedBusiness?.BUSINESS_NAME}
      />
      <ImageGalleryBusinessModal
        open={isGalleryModalOpen}
        onClose={() => setIsGalleryModalOpen(false)}
        images={WORK_GALLERY}
        business={selectedBusiness}
        setDeletedimg={setDeletedimg}
      />
    </div>
  );
};

export default BusinessList;
