import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { store } from '../redux/store';
import { fetchCatSubList } from '../redux/slice/dataSlice';
import { useNavigate } from 'react-router-dom';
import Button from './Button';
import useScreenSize from '../hooks/useScreenWidth';

const HomeSubCatWithCat = () => {
  const { cat_sub_list } = useSelector((state) => state.alldata);
  const navigate = useNavigate();
  const screen = useScreenSize();
  useEffect(() => {
    store.dispatch(fetchCatSubList());
  }, []);
  return (
    <div className="grid grid-cols-1 gap-6 lg:grid-cols-2 ">
      {cat_sub_list?.slice(0, 7)?.map((item, index) => (
        <div
          className="h-auto rounded-lg border-[2px] border-bg-gray-200 p-4 gap-4 bg-white"
          key={index}
        >
          <div className="flex items-center justify-between">
            <p className="font-sans text-lg font-medium text-[black]">
              {item?.C_NAME}
            </p>
            {/* <Button
              // className="border-black border-2 px-2 py-1 ml-auto"
              onClick={() =>
                navigate(`${item?.C_NAME}/sub-categories`, {
                  state: { ID: item?.ID },
                })
              }
              title={'VIEW ALL'}
            >
            </Button> */}
            {item?.SERVICE_INFO?.length >
              (screen === 'desktop' ? 5 : screen === 'mobile' ? 3 : 4) && (
              <p
                onClick={() =>
                  navigate(`/${item?.C_NAME}/sub-categories`, {
                    state: { ID: item?.ID },
                  })
                }
                className="font-sans text-lg cursor-pointer transition hover:scale-[1.03] min-w-fit  font-semibold text-[#1659a1]"
              >
                VIEW ALL
              </p>
            )}
          </div>
          <div className="flex gap-2 mt-4">
            {item?.SERVICE_INFO?.slice(
              0,
              screen === 'desktop' ? 5 : screen === 'mobile' ? 3 : 4
            )?.map((v, i) => (
              <div
                key={i}
                onClick={() =>
                  navigate(`/${item?.C_NAME}/${v.S_NAME}/sub-categories2`, {
                    state: { ID: v?.ID, S_NAME: v?.S_NAME },
                  })
                }
                className="flex flex-1 flex-col items-center gap-2 cursor-pointer relative group  border-[#57A7FF] mt-2"
              >
                <div className="h-24 w-24 rounded-lg bg-white  items-center justify-center flex transition-all duration-300 ease-in-out  overflow-visible  border border-[#0474ED]">
                  <img
                    src={process.env.REACT_APP_IMAGE_URL + v.WEB_URL}
                    className="h-[50%] w-[50%] object-cover rounded-[15px] transition-all duration-300 ease-in-out group-hover:h-[60%] group-hover:w-[60%]"
                  />
                </div>
                <p className="text-center line-clamp-2">{v.S_NAME}</p>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default HomeSubCatWithCat;
