// pages/contact.js

import ContactForm from './ContactForm';

const ContactPage = () => {
  return (
    <div className="min-h-[calc(100vh-100px)] flex flex-col py-8 items-center justify-center bg-gradient-to-br bg-white">
      {/* <p className="mb-8 text-[24px] md:text-[32px] font-semibold min-w-[350px] w-[70%]">
        Our team is happy to answer your questions. Fill out the form and we'll
        be in touch as soon as possible.
      </p> */}
      <ContactForm />
    </div>
  );
};

export default ContactPage;
