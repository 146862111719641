import React, { useState } from 'react';
import { IoClose } from 'react-icons/io5';
import InputBox from '../components/InputBox';
import Button from '../components/Button';
import { useDispatch } from 'react-redux';
import { setContactPopup } from '../redux/slice/userSlice';
import { Contact as ContactAPI } from '../apis/api';
import toast from 'react-hot-toast';

const Contact = ({ setContactPopup }) => {
  const [data, setData] = useState({
    name: '',
    email: '',
    mobile: '',
    query: '',
  });
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const validateMobile = (mobile) => {
    const re = /^[0-9]{10,15}$/;
    return re.test(mobile);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateEmail(data.email)) {
      toast.error('Please enter a valid email address.');
      return;
    }

    if (!validateMobile(data.mobile)) {
      toast.error('Please enter a valid mobile number.');
      return;
    }

    const body = new FormData();
    body.append('FULL_NAME', data.name);
    body.append('EMAIL_ADDRESS', data.email);
    body.append('MOBILE', data.mobile);
    body.append('DESCRIPTION', data.query);
    if (file) {
      body.append('FILE', file);
    }

    setIsLoading(true);

    try {
      const res = await ContactAPI(body);
      if (res.success) {
        toast.success('Your query has been submitted successfully!');
        setTimeout(() => {
          setContactPopup(false);
        }, 2000); // Close the popup after 2 seconds
      } else {
        toast.error('There was an issue submitting your query.');
      }
    } catch (error) {
      toast.error(
        'An error occurred while submitting your query. Please try again later.'
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 w-full h-full z-20 bg-black bg-opacity-50 overflow-y-auto backdrop-blur-[15px] flex items-center justify-center">
      <div className="text-black bg-white p-8 rounded-2xl flex w-[30%] flex-col items-center justify-center relative">
        <IoClose
          size={30}
          onClick={() => setContactPopup(false)}
          className="absolute top-5 right-5 cursor-pointer hover:scale-[1.1] transform transition-transform duration-300"
        />
        <h1 className="text-2xl font-semibold text-center">Contact Us</h1>
        <p className="mt-4 text-center">We would love to hear from you!</p>
        <form onSubmit={handleSubmit} className="w-full flex flex-col gap-6">
          <InputBox
            value={data.name}
            name={'name'}
            placeholder={'Enter Your Full Name'}
            label={'Full Name'}
            onChange={handleChange}
          />
          <InputBox
            value={data.email}
            name={'email'}
            placeholder={'Enter Your Email Address'}
            label={'Email Address'}
            onChange={handleChange}
          />
          <InputBox
            value={data.mobile}
            name={'mobile'}
            placeholder={'Enter Your Mobile Number'}
            label={'Mobile Number'}
            onChange={handleChange}
          />
          <InputBox
            value={data.query}
            name={'query'}
            placeholder={'Enter Your Query'}
            label={'Your Query'}
            onChange={handleChange}
          />
          <div className="w-full flex flex-col gap-1">
            <label className="text-gray-700 font-semibold">Upload File</label>
            <input
              type="file"
              name="file"
              onChange={handleFileChange}
              accept=".jpg,.jpeg,.png,.pdf"
              className="border rounded p-2"
            />
          </div>
          <div className="flex justify-center mt-4">
            <Button
              type="submit"
              title={isLoading ? 'Submitting...' : 'Submit'}
              disabled={isLoading}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default Contact;
