import React, { useEffect, useState } from 'react';
import {
  FaMapMarkerAlt,
  FaPhoneAlt,
  FaRegThumbsUp,
  FaWhatsapp,
} from 'react-icons/fa';
import { AddConnection, ConnectList, Reviews } from '../../apis/api';
import useLoader from '../../hooks/useLoader';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { setLoginPopup } from '../../redux/slice/userSlice';
const ProviderTop = ({ item }) => {
  const [reviewData, setReviewData] = useState([]);
  const [connectedList, setConnectedList] = useState(new Set());
  const [show, setShow] = useState(false);
  const setLoading = useLoader();
  const dispatch = useDispatch();
  const loggedIn = useSelector((state) => state.user?.loggedIn);
  const handleConnectedList = async () => {
    try {
      setLoading(true);
      const res = await ConnectList();
      const connects = res[0]?.CONNECTS || [];
      const newConnectedSet = new Set(
        connects.map((connect) => connect?.INFO?.ID)
      );
      setConnectedList(newConnectedSet);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching connected list:', error);
      setLoading(false);
    }
  };

  const handleReviewData = async () => {
    const body = {
      ID: item?.ID,
      USER_ID: item?.USER_ID,
    };
    const res = await Reviews(body);
    console.log(res);
    setReviewData(res);
  };

  useEffect(() => {
    handleReviewData();
    handleConnectedList();
  }, []);

  return (
    <div className="flex p-4 border rounded-[12px] w-full flex-col gap-2 ">
      <div className="flex gap-2">
        <div className="h-[26px] w-[32px] rounded-[8px] flex justify-center items-center bg-black text-white">
          <FaRegThumbsUp />
        </div>
        <div
          className="text-[22px] font-medium text-[#111] cursor-pointer overflow-hidden max-w-[calc(100%-40px)] leading-tight"
          style={{
            WebkitLineClamp: 1,
            WebkitBoxOrient: 'vertical',
            display: '-webkit-box',
          }}
        >
          {item?.BUSINESS_NAME}
        </div>
        {/* <span
          role="button"
          title="Add to Favorites"
          aria-label="Add to Favorites"
          className=" right-0 ml-auto top-0 w-[28px] h-[28px] bg-center bg-no-repeat bg-contain cursor-pointer"
          style={{
            backgroundImage:
              "url('https://akam.cdn.jdmagicbox.com/images/icontent/newwap/web2022/heart-component.svg')",
          }}
        >
        </span> */}
        <div className="ml-auto">
          {item?.CATEGORY_INFO?.map((e, i) => (
            <div
              key={i}
              className="inline-flex items-center justify-start text-[12px] font-medium text-[#111] border border-[#eaeaea] bg-[#f7f7f7] rounded-md h-[22px] min-h-[23px] px-[7px] mr-2 cursor-pointer"
            >
              {e?.C_NAME}
            </div>
          ))}
        </div>
      </div>
      {/* <div className="flex items-center gap-4 text-[18px]">
        <div className="mr-1.5 px-2 text-[14px] font-semibold text-white bg-[#058a07] rounded-md min-w-fit h-6 flex items-center justify-center leading-none cursor-pointer">
          {reviewData?.TOTAL_RATING_AVERAGE}
        </div>

        <div>
          <div
            title="Ratings for Nri Group Of Institutions Opposite Patel Nagar Raisen Road, Bhopal"
            className="relative w-[110px] h-[21px] top-[-2px] cursor-pointer"
          >
            <svg
              role="none"
              width="100%"
              height="100%"
              viewBox="0 0 1000 200"
              className="fill-gray-300"
            >
              <polygon
                id="0_star"
                points="100,10 40,198 190,78 10,78 160,198"
                fill="#FFE372"
              />
              <defs>
                <clipPath id="stars0">
                  <use xlinkHref="#0_star" />
                  <use xlinkHref="#0_star" x="20%" />
                  <use xlinkHref="#0_star" x="40%" />
                  <use xlinkHref="#0_star" x="60%" />
                  <use xlinkHref="#0_star" x="80%" />
                </clipPath>
              </defs>
              <rect
                width="88%"
                height="100%"
                clipPath="url(#stars0)"
                className="fill-gray-300 stroke-red-500 stroke-[1px]"
              />
              <rect
                width="88%"
                height="100%"
                clipPath="url(#stars0)"
                className="fill-orange-500"
              />
            </svg>
          </div>
        </div>
        <div className="ml-3 mr-3 text-[14px] font-normal text-[#717171] min-h-[21px] cursor-pointer">
          {reviewData?.REVIEW_INFO?.length} Rating
        </div>
      </div> */}
      <div className="flex items-center gap-4 text-[18px]">
        <div className="mr-1.5 px-2 text-[14px] font-semibold text-white bg-[#058a07] rounded-md min-w-fit h-6 flex items-center justify-center leading-none cursor-pointer">
          {reviewData?.TOTAL_RATING_AVERAGE
            ? reviewData?.TOTAL_RATING_AVERAGE
            : 0}
        </div>

        <div>
          <div
            title="Ratings for Nri Group Of Institutions Opposite Patel Nagar Raisen Road, Bhopal"
            className="relative w-[110px] h-[21px] top-[-2px] cursor-pointer"
          >
            <svg
              role="none"
              width="100%"
              height="100%"
              viewBox="0 0 1000 200"
              className="fill-gray-300"
            >
              <polygon
                id="0_star"
                points="100,10 40,198 190,78 10,78 160,198"
                fill="#FFE372"
              />
              <defs>
                <clipPath id="stars0">
                  <use xlinkHref="#0_star" />
                  <use xlinkHref="#0_star" x="20%" />
                  <use xlinkHref="#0_star" x="40%" />
                  <use xlinkHref="#0_star" x="60%" />
                  <use xlinkHref="#0_star" x="80%" />
                </clipPath>
              </defs>
              <rect
                width="100%"
                height="100%"
                clipPath="url(#stars0)"
                className="fill-gray-300"
              />
              <rect
                width={`${(reviewData?.TOTAL_RATING_AVERAGE / 5) * 100}%`}
                height="100%"
                clipPath="url(#stars0)"
                className="fill-orange-500"
              />
            </svg>
          </div>
        </div>
        <div className="ml-3 mr-3 text-[14px] font-normal text-[#717171] min-h-[21px] cursor-pointer">
          {reviewData?.REVIEW_INFO ? reviewData?.REVIEW_INFO?.length : 0} Rating
        </div>
      </div>

      <div className="flex items-center gap-4">
        <FaMapMarkerAlt />
        <div className="text-[15px] font-normal text-[#111] cursor-pointer min-h-[24px] leading-[1.55]">
          {item?.BUSINESS_ADDRESS}
        </div>
      </div>
      <div className="flex items-center gap-4">
        {item?.CATEGORY_INFO?.map((e, i) => (
          <div
            key={i}
            className="inline-flex items-center justify-start text-[12px] font-medium text-[#111] border border-[#eaeaea] bg-[#f7f7f7] rounded-md h-[22px] min-h-[23px] px-[7px] mr-2 cursor-pointer"
          >
            {e?.C_NAME}
          </div>
        ))}
      </div>
      <div className="flex flex-wrap items-center gap-4">
        <div className="inline-flex items-center justify-center text-white font-medium text-[15px] bg-[#058a07] border border-[#027c0a] rounded-md h-[35px] min-w-[125px] px-[20px] cursor-pointer">
          <div className="callNowAnchor">
            <FaPhoneAlt size={16} color="#fff" />
          </div>
          <span className="min-h-[23px] flex items-center">
            <span
              onClick={(e) => {
                setShow(!show);
                e.stopPropagation();
              }}
              className="min-h-[23px] flex items-center"
            >
              <span className="whitecall_icon jdicon mr-2"></span>
              {show ? item?.BUSINESS_NUMBER : 'Show Number'}
            </span>
          </span>
        </div>

        <button
          id="bestdeal_btn_cta_0755P7480.7480.120225134202.F3E8"
          className="inline-flex items-center justify-center text-white font-medium text-[15px] bg-[#0076d7] border border-[#0076d7] rounded-md h-[35px] min-w-[125px] px-[15px] mr-4 cursor-pointer min-h-[35px]"
          onClick={async (e) => {
            e.stopPropagation();
            if (!loggedIn) {
              dispatch(setLoginPopup(true));
              return;
            }
            if (!connectedList.has(item?.ID)) {
              const res = await AddConnection({
                SERVICE_PROVIDER_ID: item?.ID,
              });
              handleConnectedList();
              if (res.success) toast.success('connection added successfully');
            }
          }}
        >
          {connectedList.has(item?.ID) ? 'Connected' : 'Connect'}
        </button>
        <a
          href={`https://wa.me/${item?.BUSINESS_NUMBER}`} // Replace with the actual phone number, including the country code, without any + or dashes
          target="_blank"
          role="button"
          tabIndex="0"
          className="inline-flex items-center justify-center text-[#111] gap-2 font-medium text-[15px] bg-white border border-[#c3c3c3] rounded-md h-[35px] min-w-[80px] px-[10px] mr-4 cursor-pointer min-h-[35px]"
        >
          <span className="chat_icon jdicon">
            <FaWhatsapp size={16} color="#25D366" />
          </span>
          <span id="chat_txt" onClick={() => {}}>
            Chat
          </span>
        </a>
        {/* <div className="font-medium ml-auto text-[#111] text-[12px] antialiased box-border text-right cursor-pointer">
          <button
            type="button"
            id="dp_best_deal"
            className=" no-underline outline-none box-border font-poppins tap-highlight-transparent text-white font-medium text-18 w-full h-fit rounded-[12px] border text-lg px-4 !py-1 border-solid border-blue-600 cursor-pointer bg-blue-600 flex items-center justify-center transition-all mb-0  flex-col leading-7"
          >
            Enquire Now
            <div className="hidden">
              <div>
                <div className="text-lg font-normal text-white">
                  Click here to Enquire Now
                  <span
                    role="button"
                    aria-label="Click here to Enquire Now"
                    className="inline-block"
                  ></span>
                </div>
              </div>
            </div>
            <div className="text-center text-sm font-normal mt-1">
              Get free details instantly via SMS
            </div>
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default ProviderTop;
