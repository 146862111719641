import React, { useState } from 'react';

const ProfileDetails = ({
  data,
  setIsPasswordModalOpen,
  setIsProfileModalOpen,
}) => {
  const [password, setPassword] = useState('password');
  const [flag, setFlag] = useState(false);

  return (
    <div className="bg-white rounded-lg shadow p-5 mb-6">
      <div className="flex flex-wrap">
        <div className="w-full md:w-1/4">
          <h6 className="text-gray-800">Full Name</h6>
        </div>
        <div className="w-full md:w-3/4 text-gray-600">{data?.FULL_NAME}</div>
      </div>
      <hr className="my-4" />
      <div className="flex flex-wrap">
        <div className="w-full md:w-1/4">
          <h6 className="text-gray-800">Email</h6>
        </div>
        <div className="w-full md:w-3/4 text-gray-600">
          {data?.EMAIL_ADDRESS}
        </div>
      </div>
      <hr className="my-4" />
      <div className="flex flex-wrap">
        <div className="w-full md:w-1/4">
          <h6 className="text-gray-800">Phone</h6>
        </div>
        <div className="w-full md:w-3/4 text-gray-600">
          {data?.PHONE_NUMBER}
        </div>
      </div>
      <hr className="my-4" />
      <div className="flex flex-wrap">
        <div className="w-full md:w-1/4">
          <h6 className="text-gray-800">Gender</h6>
        </div>
        <div className="w-full md:w-3/4 text-gray-600">{data?.GENDER}</div>
      </div>
      {/* <hr className="my-4" /> */}
      {/* <div className="flex flex-wrap">
        <div className="w-full md:w-1/4">
          <h6 className="text-gray-800">Address</h6>
        </div>
        <div className="w-full md:w-3/4 text-gray-600">
          Bay Area, San Francisco, CA
        </div>
      </div> */}
      <hr className="my-4" />
      <div className="flex justify-end">
        <button
          onClick={() => setIsProfileModalOpen(true)}
          className="btn btn-info bg-blue-500 hover:bg-blue-700 text-white px-4 py-2 rounded"
        >
          Edit Profile
        </button>
        <button
          onClick={() => setIsPasswordModalOpen(true)}
          className="btn btn-warning bg-yellow-500 hover:bg-yellow-700 text-white px-4 py-2 rounded ml-2"
        >
          Change Password
        </button>
      </div>
    </div>
  );
};

export default ProfileDetails;
