import React, { useEffect, useState } from 'react';
import PostCard from '../components/Blogs/PostCard';
import Modal from '../components/Blogs/Modal';
import { useSelector } from 'react-redux';

const fakePosts = [
  {
    id: '1',
    slug: 'first-post',
    title: 'First Post',
    metadata: {
      hero: {
        imgix_url:
          'https://starwalk.space/gallery/images/what-is-space/1920x1080.jpg',
      },
      teaser: '<p>This is the teaser for the first post.</p>',
      categories: [{ title: 'Category1' }, { title: 'Category2' }],
      author: {
        title: 'Abhishek Sharma',
      },
      published_date: Date.now(),
    },
  },
  {
    id: '2',
    slug: 'second-post',
    title: 'Second Post',
    metadata: {
      hero: {
        imgix_url:
          'https://starwalk.space/gallery/images/what-is-space/1920x1080.jpg',
      },
      teaser: '<p>This is the teaser for the second post.</p>',
      categories: [{ title: 'Category3' }, { title: 'Category4' }],
      author: {
        title: 'Abhishek Sharma',
      },
      published_date: Date.now(),
    },
  },
  {
    id: '1',
    slug: 'first-post',
    title: 'First Post',
    metadata: {
      hero: {
        imgix_url:
          'https://starwalk.space/gallery/images/what-is-space/1920x1080.jpg',
      },
      teaser: '<p>This is the teaser for the first post.</p>',
      categories: [{ title: 'Category1' }, { title: 'Category2' }],
      author: {
        title: 'Abhishek Sharma',
      },
      published_date: Date.now(),
    },
  },
  {
    id: '2',
    slug: 'second-post',
    title: 'Second Post',
    metadata: {
      hero: {
        imgix_url:
          'https://starwalk.space/gallery/images/what-is-space/1920x1080.jpg',
      },
      teaser: '<p>This is the teaser for the second post.</p>',
      categories: [{ title: 'Category3' }, { title: 'Category4' }],
      author: {
        title: 'Abhishek Sharma',
      },
      published_date: Date.now(),
    },
  },
  {
    id: '1',
    slug: 'first-post',
    title: 'First Post',
    metadata: {
      hero: {
        imgix_url:
          'https://starwalk.space/gallery/images/what-is-space/1920x1080.jpg',
      },
      teaser: '<p>This is the teaser for the first post.</p>',
      categories: [{ title: 'Category1' }, { title: 'Category2' }],
      author: {
        title: 'Abhishek Sharma',
      },
      published_date: Date.now(),
    },
  },
  {
    id: '2',
    slug: 'second-post',
    title: 'Second Post',
    metadata: {
      hero: {
        imgix_url:
          'https://starwalk.space/gallery/images/what-is-space/1920x1080.jpg',
      },
      teaser: '<p>This is the teaser for the second post.</p>',
      categories: [{ title: 'Category3' }, { title: 'Category4' }],
      author: {
        title: 'Abhishek Sharma',
      },
      published_date: Date.now(),
    },
  },
  {
    id: '1',
    slug: 'first-post',
    title: 'First Post',
    metadata: {
      hero: {
        imgix_url:
          'https://starwalk.space/gallery/images/what-is-space/1920x1080.jpg',
      },
      teaser: '<p>This is the teaser for the first post.</p>',
      categories: [{ title: 'Category1' }, { title: 'Category2' }],
      author: {
        title: 'Abhishek Sharma',
      },
      published_date: Date.now(),
    },
  },
  {
    id: '2',
    slug: 'second-post',
    title: 'Second Post',
    metadata: {
      hero: {
        imgix_url:
          'https://starwalk.space/gallery/images/what-is-space/1920x1080.jpg',
      },
      teaser: '<p>This is the teaser for the second post.</p>',
      categories: [{ title: 'Category3' }, { title: 'Category4' }],
      author: {
        title: 'Abhishek Sharma',
      },
      published_date: Date.now(),
    },
  },
  // ... other posts
];

const token =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVU0VSX0lEIjoiYTJjMDJiOGUtNTJiOS00ZDYxLTk2YmItNzYzN2E5Zjc1YzQyIiwiRlVMTF9OQU1FIjoiU2h1YmhhbSBDYXJwZW50ZXIiLCJST0xFIjoiU0VSVklDRS1QUk9WSURFUiIsImlhdCI6MTcyMDYwODIxOH0.WdKxDGO-2o1L-jniZa0T5aocW0B_WvkoWc8dTPRQ_9g';

function Blogs() {
  const [posts, setPosts] = useState(fakePosts);
  const [selectedPost, setSelectedPost] = useState(null);
  const [blogsData, setBlogsData] = useState([]);

  const handleBlogsData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}blogs/list`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`, // Replace with the actual token
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log(data.data);
      setBlogsData(data.data);
      console.log(data.data);
    } catch (error) {
      console.log('Error fetching blogs:', error);
    }
  };

  useEffect(() => {
    handleBlogsData();
  }, []);

  const openModal = (post) => setSelectedPost(post);
  const closeModal = () => setSelectedPost(null);

  return (
    <>
      <p className="font-sans text-2xl font-semibold py-3 text-[#0474ED] px-4 md:px-10">
        Popular Blogs
      </p>
      <main className="px-4 mx-0 md:mx-8 md:px-10 mt-4 w-full grid gap-10 md:grid-cols-2 grid-cols-1 lg:grid-cols-3 xl:grid-cols-4 space-y-16">
        {!blogsData.length && 'You must add at least one Post to your Bucket'}
        {blogsData?.length > 0 &&
          blogsData.map((post, ind) => (
            <div
              key={ind}
              className="max-w-full border-gray-500 rounded-xl bg-white shadow-xl  border !mt-0 cursor-pointer"
              onClick={() => openModal(post)}
            >
              <PostCard post={post} />
            </div>
          ))}

        {selectedPost && (
          <Modal
            isOpen={!!selectedPost}
            onClose={closeModal}
            title={selectedPost?.TITLE}
            description={selectedPost?.DESCRIPTION}
          />
        )}
      </main>
    </>
  );
}

export default Blogs;
