import React from 'react';

const UpdateProfileImageModal = ({
  handleFileChange,
  handleImageUpload,
  setIsModalOpen,
}) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-5 rounded-lg shadow-lg">
        <h4 className="text-xl mb-4">Update Profile Image</h4>
        <input type="file" onChange={handleFileChange} />
        <div className="flex justify-end mt-4">
          <button
            onClick={handleImageUpload}
            className="btn btn-primary bg-blue-500 hover:bg-blue-700 text-white px-4 py-2 rounded mr-2"
          >
            Upload
          </button>
          <button
            onClick={() => setIsModalOpen(false)}
            className="btn btn-secondary bg-gray-500 hover:bg-gray-700 text-white px-4 py-2 rounded"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default UpdateProfileImageModal;
