import React from 'react';
import toast from 'react-hot-toast';

const ProviderAddress = ({ item, options }) => {
  console.log(item, options);
  return (
    <div className="flex-1 flex flex-col gap-4">
      <div className=" border rounded-[12px]">
        <p className="text-xl font-[500] p-4">Address</p>
        <div className="">
          <address className="antialiased px-4 tracking-tight font-poppins tap-highlight-transparent text-gray-900 font-normal text-[18px] pr-5 mb-2.5 not-italic leading-[1.63] box-border">
            {item?.BUSINESS_ADDRESS}
          </address>
        </div>
        <div>
          {options.map((option, index) => (
            <div className="border-t" key={index}>
              {option?.Link ? (
                <a
                  key={index}
                  role="link"
                  className="address_link no-underline tap-highlight-transparent box-border border-0 outline-none cursor-pointer inline-flex items-center justify-start w-full text-left h-10 rounded bg-transparent transition-all duration-800 px-2.5"
                  rel="nofollow"
                  aria-label={option.label}
                  title={option.label}
                  target="_blank"
                  href={option?.Link ? option?.Link : '#'}
                >
                  <div className="address_link_icon mr-2.5 text-blue-400 text-[24px]">
                    {option.icon}
                  </div>
                  <div className="font-poppins text-gray-900 text-[18px] font-normal">
                    {option.label}
                  </div>
                </a>
              ) : (
                <p
                  key={index}
                  role="link"
                  className="address_link no-underline tap-highlight-transparent box-border border-0 outline-none cursor-pointer inline-flex items-center justify-start w-full text-left h-10 rounded bg-transparent transition-all duration-800 px-2.5"
                  rel="nofollow"
                  aria-label={option.label}
                  title={option.label}
                  onClick={() => toast.error('Link Not Available')}
                >
                  <div className="address_link_icon mr-2.5 text-blue-400 text-[24px]">
                    {option.icon}
                  </div>
                  <div className="font-poppins text-gray-900 text-[18px] font-normal">
                    {option.label}
                  </div>
                </p>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="border rounded-[12px] p-4">
        <p className="text-xl font-[500]">Also Listed in</p>
        <div className="flex flex-wrap gap-2 mt-5">
          {item?.CATEGORY_INFO?.length > 0 &&
            item?.CATEGORY_INFO[0]?.C_NAME.length > 0 && (
              <a
                className="listed_item_anchor antialiased tracking-tighter font-poppins no-underline tap-highlight-transparent outline-none box-border list-none m-0 p-0 bg-transparent inline-block mb-2.5 mr-2.5 py-2 px-4 rounded-full shadow-lg bg-white text-gray-600 text-sm font-normal cursor-pointer"
                href="/Bhopal/Colleges-in-Raisen-Road/nct-10106380"
              >
                {item?.CATEGORY_INFO[0]?.C_NAME}
              </a>
            )}
          {item?.SERVICE_INFO?.map(
            (e, i) =>
              e?.MAIN_SERVICE?.S_NAME?.length > 0 && (
                <a
                  className="listed_item_anchor antialiased tracking-tighter font-poppins no-underline tap-highlight-transparent outline-none box-border list-none m-0 p-0 bg-transparent inline-block mb-2.5 mr-2.5 py-2 px-4 rounded-full shadow-lg bg-white text-gray-600 text-sm font-normal cursor-pointer"
                  href="/Bhopal/Colleges-in-Raisen-Road/nct-10106380"
                  key={i}
                >
                  {e?.MAIN_SERVICE?.S_NAME}
                </a>
              )
          )}
        </div>
      </div>
    </div>
  );
};

export default ProviderAddress;
