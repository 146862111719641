// src/components/AboutUs.js
import React from 'react';

const AboutUs = () => {
  return (
    <div className="p-6 md:p-12 bg-white min-h-screen">
      <div className="max-w-2xl bg-white shadow-lg mx-auto p-4">
        <h1 className="text-2xl font-bold mb-4">About Us</h1>
        <p className="mb-4">
          My Call Book is dedicated to making your life easier by connecting you
          with reliable service providers in your area. Whether you need a
          painter, plumber, carpenter, IT specialist, or any other service, we
          have you covered. Our mission is to simplify the process of finding
          and hiring professionals by providing a platform that is easy to use,
          reliable, and efficient.
        </p>
        <p className="mb-4">
          We believe in building a community where trust and quality are
          paramount. Our team works tirelessly to verify service providers,
          gather user feedback, and ensure that you have access to the best
          professionals available. At My Call Book, your satisfaction is our top
          priority.
        </p>
      </div>
    </div>
  );
};

export default AboutUs;
