import React, { useState, useEffect } from 'react';
import { FaTrash, FaPlus } from 'react-icons/fa6';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import ConfirmDeleteModal from './ConfirmDeleteModal';
import AddImageModal from './AddImageModal';
import { AddBusinessImage, DeleteBusinessImage } from '../../apis/api';
import toast from 'react-hot-toast';

const ImageGalleryBusinessModal = ({
  open,
  onClose,
  images,
  business,
  setDeletedimg,
}) => {
  const [selectedImage, setSelectedImage] = useState(images[0]?.path || '');
  const [deleteImage, setDeleteImage] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const [imageList, setImageList] = useState(images || []);
  const [addImageModalOpen, setAddImageModalOpen] = useState(false);

  useEffect(() => {
    setImageList(images || []);
    setSelectedImage(images[0]?.path || '');
  }, [images]);

  const handleDelete = async () => {
    const body = new FormData();
    body.append('ID', business?.ID);
    body.append('PATH_ID', deleteId);
    const newImageList = imageList.filter((img) => img.path_id !== deleteId);
    setImageList(newImageList);
    setDeleteImage(false);
    setDeletedimg(true);

    const res = await DeleteBusinessImage(body);
    if (res.success) {
      toast.success(res.message);
    } else {
      // Revert the image list if the API call fails
      setImageList(images);
      toast.error(res.message);
    }
  };

  const handleAddImage = async (files) => {
    if (files && files.length > 0) {
      const newImages = [];
      for (const file of files) {
        const tempImage = URL.createObjectURL(file);
        const tempImageObj = {
          path: tempImage,
          path_id: 'temp_' + new Date().getTime(),
          priority: 0,
          isTemp: true,
        };
        newImages.push(tempImageObj);
        setImageList((prevList) => [...prevList, tempImageObj]);
      }

      setAddImageModalOpen(false);

      for (const file of files) {
        const body = new FormData();
        body.append('ID', business?.ID);
        body.append('PHOTOS', file);

        const res = await AddBusinessImage(body);
        if (res.success) {
          toast.success('Image added successfully');
        } else {
          toast.error(res.message);
        }
      }
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      center
      classNames={{
        overlay:
          'fixed inset-0 bg-black bg-opacity-70 flex justify-center items-center',
        modal:
          'bg-white max-w-[90%] sm:max-w-[300px] md:max-w-[500px] rounded-lg p-4 md:p-6 w-full shadow-xl',
      }}
      showCloseIcon={false}
    >
      <div className="flex flex-col md:flex-row max-w-full max-h-[70vh] md:max-h-[500px] gap-2 md:gap-4">
        {selectedImage && (
          <div className="relative flex-1 md:flex-[2]">
            <img
              className="max-h-[40vh] md:max-h-[500px] w-full rounded-lg object-cover"
              src={selectedImage?.isTemp?selectedImage?.path:process.env.REACT_APP_IMAGE_URL + selectedImage}
              alt="Selected"
            />
            <button
              className="absolute top-2 right-2 bg-white bg-opacity-70 p-2 rounded-full hover:bg-opacity-100"
              onClick={onClose}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="currentColor"
                className="w-6 h-6 text-gray-700"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        )}
        <div className="flex min-w-[100px] md:min-w-[200px] max-h-[20vh] md:max-h-full flex-col gap-2 overflow-x-hidden no_scroll overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300">
          {imageList?.map((img, index) => (
            <div className="relative" key={index}>
              <img
                className={`h-auto w-full md:h-24 max-w-[200px] object-cover cursor-pointer rounded-lg transition-transform duration-200 transform hover:scale-105 ${
                  selectedImage === img.path ? 'border-2 border-blue-500' : ''
                }`}
                src={img?.isTemp?img?.path:process.env.REACT_APP_IMAGE_URL + img.path}
                alt={`Thumbnail ${index}`}
                onClick={() => setSelectedImage(img.path)}
              />
              <FaTrash
                className="absolute top-2 text-[24px] right-2 bg-white bg-opacity-70 p-1 rounded-full hover:bg-opacity-100 cursor-pointer"
                color="red"
                onClick={() => {
                  setDeleteImage(true);
                  setDeleteId(img?.path_id);
                }}
              />
            </div>
          ))}
          <div
            className="flex justify-center items-center h-auto w-full md:h-24 max-w-[200px] bg-gray-200 rounded-lg cursor-pointer hover:bg-gray-300 transition-colors duration-200"
            onClick={() => setAddImageModalOpen(true)}
          >
            <FaPlus className="text-gray-500 text-2xl" />
          </div>
        </div>
      </div>
      <ConfirmDeleteModal
        isOpen={deleteImage}
        onClose={() => setDeleteImage(false)}
        onConfirm={handleDelete}
        businessName="this"
      />
      <AddImageModal
        isOpen={addImageModalOpen}
        onClose={() => setAddImageModalOpen(false)}
        onAddImage={handleAddImage}
      />
    </Modal>
  );
};

export default ImageGalleryBusinessModal;
