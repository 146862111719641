import React, { useEffect, useState } from 'react';
import { GetPlanDetails } from '../apis/api';
import Carousel from './fakeCarousel/Carousel';
// import Card from './fakeCarousel/Card';
import './fakeCarousel/styles.scss';
import Card from '../components/PlanAndPrice/Card';
const CARDS = 10;

const PlanAndPricing = () => {
  const [planData, setPlanData] = useState([]);
  let data = [
    {
      title: 'FREE',
      price: '$0',
      user: 'Single User',
      storage: '5GB Storage',
      domain: 'Free Subdomain',
      project: 'text-muted',
      projectIcon: 'fas fa-times',
      phone: 'text-muted',
      phoneIcon: 'fas fa-times',
      subDomain: 'text-muted',
      subDomainIcon: 'fas fa-times',
      monthly: 'text-muted',
      monthlyIcon: 'fas fa-times',
    },
    {
      title: 'PLUS',
      price: '$9',
      user: '5 Users',
      storage: '50GB Storage',
      domain: 'Free Subdomain',
      project: '',
      projectIcon: 'fas fa-check',
      phone: '',
      phoneIcon: 'fas fa-check',
      subDomain: '',
      subDomainIcon: 'fas fa-check',
      monthly: 'text-muted',
      monthlyIcon: 'fas fa-times',
    },
    {
      title: 'PRO',
      price: '$49',
      user: 'Unlimited Users',
      storage: '150GB Storage',
      domain: 'Unlimited Free Subdomain',
      project: '',
      projectIcon: 'fas fa-check',
      phone: '',
      phoneIcon: 'fas fa-check',
      subDomain: '',
      subDomainIcon: 'fas fa-check',
      monthly: '',
      monthlyIcon: 'fas fa-check',
    },
  ];

  const handleData = async () => {
    const res = await GetPlanDetails();
    console.log(res);
    setPlanData(res);
  };

  useEffect(() => {
    handleData();
  }, []);
  return (
    <div className="px-4 bg-[#fff] min-h-fit py-10 flex flex-col justify-start items-center md:px-10 ">
      <div className="relative bottom-6">
        <p className="text-[32px] border-black mb-4 text-black font-semibold text-center">
          Transparent Plans for Every Need
        </p>
        <p className="text-[18px] pb-2 max-w-[800px]  text-black text-center">
          Our pricing is straightforward and easy to understand, with no hidden
          fees or surprises. Choose the plan that fits your needs and budget.
        </p>
      </div>
      <div className="flex flex-col gap-4 md:hidden">
        {planData?.map((e, i) => (
          <Card cardData={e} key={i} />
        ))}
      </div>
      <div className="hidden min-h-[550px] md:block">
        <Carousel>
          {planData?.map((e, i) => (
            <Card
              cardData={e}
              key={i}
              title={'Card ' + (i + 1)}
              content="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
            />
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default PlanAndPricing;
