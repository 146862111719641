import React, { useEffect, useState } from 'react';
import { GoBell } from 'react-icons/go';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { setLoginPopup, fetchNotifications } from '../redux/slice/userSlice';
import NotificationModal from './NotificationModal';
import { store } from '../redux/store';

const Notification = () => {
  const [flag, setFlag] = useState(false);
  const [detail, setDetail] = useState({
    title: '',
    desc: '',
    image: '',
    id: '',
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loggedIn = useSelector((state) => state.user.loggedIn);
  const notifications = useSelector((state) => state.user.notifications);
  console.log(notifications);

  useEffect(() => {
    if (loggedIn) {
      dispatch(fetchNotifications());
    }
  }, [loggedIn, dispatch]);

  return (
    <div className="relative group block">
      <div className="py-3 flex">
        <div
          onClick={() => {
            if (!loggedIn) store.dispatch(setLoginPopup(true));
          }}
          className="relative h-[40px] cursor-pointer w-[40px] flex items-center justify-center"
        >
          <GoBell size={30} color="#333333" />
          {notifications?.unreadCount > 0 && (
            <span className="absolute top-0 right-0 inline-flex items-center justify-center px-1 py-1 text-xs font-bold leading-none text-red-100 bg-red-600 rounded-full">
              {notifications?.unreadCount}
            </span>
          )}
        </div>
      </div>
      {loggedIn && notifications?.data?.length>0&&(
        <div
          className="absolute right-[-40px] z-10 w-80 rounded-md border border-gray-100 bg-white shadow-lg hidden group-hover:block"
          role="menu"
        >
          <div className="p-2">
            {notifications?.data?.slice(0, 4).map((item, index) => (
              <p
                key={index}
                onClick={() => {
                  setDetail({
                    ...detail,
                    title: item.TITLE,
                    desc: item.MESSAGE,
                    image: item.FILE_PATH,
                    id: item.NOTIFICATION,
                  });
                  setFlag(true);
                  dispatch(fetchNotifications());
                }}
                className={`rounded-lg px-4 flex ${
                  !item.IS_READ ? 'text-black font-semibold' : 'text-gray-500'
                } justify-between  items-center py-2 text-sm text-gray-500 hover:bg-[#57A7FF] hover:text-black`}
                role="menuitem"
              >
                {item.TITLE.substring(0, 50)}
                {item.TITLE.length > 50 ? '...' : ''}
                {!item.IS_READ && (
                  <p className="bg-green-600 w-[10px] h-[10px] rounded-[50%]"></p>
                )}
              </p>
            ))}
            <div className="flex items-center justify-end">
              <button
                className="flex self-center justify-center items-center gap-2 rounded-full px-4 py-1 text-sm text-[#0474ED] hover:bg-[#f1f1f1]"
                onClick={() => navigate('/notifications')}
              >
                See All
              </button>
            </div>
          </div>
          <NotificationModal
            id={detail.id}
            isModalOpen={flag}
            setIsModalOpen={setFlag}
            title={detail.title}
            imageUrl={detail.image}
            desc={detail.desc}
          />
        </div>
      )}
    </div>
  );
};

export default Notification;
