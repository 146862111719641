import React from 'react';
import { IoIosArrowForward } from 'react-icons/io';

const ArrowRight = () => {
  return (
    <div>
      <IoIosArrowForward />
    </div>
  );
};

export default ArrowRight;
