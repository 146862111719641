import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';

const domain = 'https://api.mycallbook.com/';

const CategoryCard = ({ item }) => {
  const navigate = useNavigate();
  return (
    // <div className="bg-[#0474ED] max-h-[200px] p-1 rounded-lg min-w-[140px] max-w-[140px]  gap-1 flex flex-col ">
    //   <div
    //     className="bg-white p-2 flex flex-col rounded-tl-md items-center rounded-tr-md h-[100px] md:h-[120px]"
    //     // style={{
    //     //   backgroundImage: `url('${domain + item?.LOGO_URL}')`,
    //     //   backgroundSize: "100% 100%",
    //     // }}
    //   >
    //     <p
    //       data-tooltip-id="C_NAME_TOOLTIP"
    //       data-tooltip-content={item.C_NAME}
    //       data-tooltip-place="bottom-start"
    //       className="font-sans line-clamp-2 font-medium text-[#0474ED] bg-white text-center"
    //     >
    //       {item?.C_NAME}
    //     </p>
    //     <Tooltip
    //       className="!z-[51] relative right-[100%]"
    //       id="C_NAME_TOOLTIP"
    //       place="bottom"
    //       effect="solid"
    //     />
    //     <div className="flex flex-1 overflow-hidden ">
    //       <img
    //         src={domain + item?.WEB_URL}
    //         className="w-14 object-contain"
    //         alt=""
    //       />
    //     </div>
    //   </div>
    //   <button
    //     onClick={() =>
    //       navigate(`/${item?.C_NAME}/service-providers`, {
    //         state: { name: 'category', id: item?.ID },
    //       })
    //     }
    //     className="font-sans font-medium hover:text-white hover:bg-[#57A7FF] text-[#0474ED] text-center bg-white w-full rounded-bl-md rounded-br-md py-1"
    //   >
    //     Enquire Now
    //   </button>
    // </div>

    <div
      onClick={() =>
        navigate(`/${item?.C_NAME}/service-providers`, {
          state: { name: 'category', id: item?.ID },
        })
      }
      className="flex flex-col cursor-pointer items-center space-y-2 transition-transform duration-300 hover:scale-105"
    >
      <div className="relative w-16 min-h-fit h-16 rounded-full p-1 bg-white">
        <div className="w-full h-full rounded-full overflow-hidden border-2 p-2 border-[#57A7FF] shadow-lg">
          <img
            src={domain + item?.WEB_URL}
            alt={item?.C_NAME}
            className="w-full h-full object-cover"
          />
        </div>
        <div className="absolute inset-0 rounded-full border-2  border-[#57A7FF] opacity-0 hover:opacity-100 transition-opacity duration-300"></div>
      </div>
      <p className="text-xs font-semibold text-gray-800">{item?.C_NAME}</p>
    </div>
  );
};

export default CategoryCard;
