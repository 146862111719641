import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Toaster, toast, useToasterStore } from 'react-hot-toast';
import { ScrollToTopProvider } from './context/ScrollToTopProvider';

const TOAST_LIMIT = 1; // Set the toast limit

const root = ReactDOM.createRoot(document.getElementById('root'));

const ToastManager = () => {
  const { toasts } = useToasterStore();

  useEffect(() => {
    toasts
      .filter((t) => t.visible) // Only consider visible toasts
      .filter((_, i) => i >= TOAST_LIMIT) // Is toast index over limit?
      .forEach((t) => toast.dismiss(t.id)); // Dismiss – Use toast.remove(t.id) for no exit animation
  }, [toasts]);

  return null;
};

root.render(
  <React.StrictMode>
    <ScrollToTopProvider>
      <BrowserRouter>
        <App />
        <Toaster
          position="top-center"
          toastOptions={{
            style: {
              border: '1px solid #713200',
              padding: '16px',
              color: '#713200',
            },
            success: {
              style: {
                border: '1px solid #4CAF50',
                padding: '16px',
                color: '#4CAF50',
              },
              iconTheme: {
                primary: '#4CAF50',
                secondary: '#FFFFFF',
              },
            },
            error: {
              style: {
                border: '1px solid #F44336',
                padding: '16px',
                color: '#F44336',
              },
              iconTheme: {
                primary: '#F44336',
                secondary: '#FFFFFF',
              },
            },
          }}
        />
        <ToastManager /> {/* Add the ToastManager component */}
      </BrowserRouter>
    </ScrollToTopProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
