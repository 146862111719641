import React, { useState } from 'react';
import { UpdatePassword } from '../../apis/api';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import useLoader from '../../hooks/useLoader';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';

const ChangePasswordModal = ({ setIsPasswordModalOpen }) => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const userData = useSelector((state) => state.user.userData);
  const setLoader = useLoader();

  const handlePasswordChange = async () => {
    const body = {
      USER_ID: userData?.USER_ID,
      OLD_PASSWORD: newPassword,
      NEW_PASSWORD: confirmPassword,
    };
    setLoader(true);
    const res = await UpdatePassword(body);
    console.log(res);
    setLoader(false);
    if (res.success) {
      toast.success(res.message);
      setIsPasswordModalOpen(false);
    } else {
      toast.error(res.message);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-5 rounded-lg shadow-lg">
        <h4 className="text-xl mb-4">Change Password</h4>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Old Password</label>
          <div className="relative">
            <input
              type={showNewPassword ? 'text' : 'password'}
              className="border rounded px-3 py-2 w-full"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <button
              type="button"
              className="absolute inset-y-0 right-0 px-3 flex items-center text-gray-700"
              onClick={() => setShowNewPassword(!showNewPassword)}
            >
              {showNewPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
            </button>
          </div>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">New Password</label>
          <div className="relative">
            <input
              type={showConfirmPassword ? 'text' : 'password'}
              className="border rounded px-3 py-2 w-full"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <button
              type="button"
              className="absolute inset-y-0 right-0 px-3 flex items-center text-gray-700"
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
            >
              {showConfirmPassword ? (
                <AiOutlineEyeInvisible />
              ) : (
                <AiOutlineEye />
              )}
            </button>
          </div>
        </div>
        <div className="flex justify-end mt-4">
          <button
            onClick={handlePasswordChange}
            className="btn btn-primary bg-blue-500 hover:bg-blue-700 text-white px-4 py-2 rounded mr-2"
          >
            Change
          </button>
          <button
            onClick={() => setIsPasswordModalOpen(false)}
            className="btn btn-secondary bg-gray-500 hover:bg-gray-700 text-white px-4 py-2 rounded"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChangePasswordModal;
