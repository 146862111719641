import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

const API_URL = 'https://api.mycallbook.com/api/';

export const fetchCatList = createAsyncThunk('cal/list', async () => {
  const response = await fetch(API_URL + 'categories/list');
  const data = await response.json();
  return data;
});

export const fetchCatSubList = createAsyncThunk('cal-sub/list', async () => {
  const response = await fetch(API_URL + 'categories/list-with-sub-cat');
  const data = await response.json();
  return data;
});

export const fetchSubCatList = createAsyncThunk('subcal/list', async (id) => {
  const response = await fetch(API_URL + `services/list/cat_id/${id}`);
  const data = await response.json();
  return data;
});

export const fetchPlanList = createAsyncThunk('plan/list', async (id) => {
  const response = await fetch(API_URL + `subcription-plan/list`, {
    method: 'GET',
  });
  const data = await response.json();

  return data;
});
export const fetchConnectsList = createAsyncThunk(
  'connect/list',
  async (id) => {
    const { token } = useSelector((state) => state.user);

    const response = await fetch(API_URL + `connects/list`, {
      method: 'GET',
      headers: {
        // Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();

    return data;
  }
);

export const fetchSliderImg = createAsyncThunk('slider/list', async () => {
  const response = await fetch(
    'https://api.mycallbook.com/api/slider-info/all-list',
    { method: 'GET' }
  );
  const data = await response.json();
  return data;
});

export const fetchNotifications = createAsyncThunk(
  'notification/list',
  async (token) => {
    const response = await fetch(
      'https://api.mycallbook.com/api/notification/list',
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await response.json();
    return data;
  }
);

export const fetchConnects = createAsyncThunk(
  'connects/list',
  async (token) => {
    const response = await fetch(
      'https://api.mycallbook.com/api/connects/list',
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await response.json();
    return data;
  }
);

export const fetchBlogStatus = createAsyncThunk(
  'blog-status',
  async (token) => {
    const response = await fetch(
      'https://api.mycallbook.com/api/blog-status/check-status',
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await response.json();

    return data;
  }
);

export const fetchLogo = createAsyncThunk('logo/list', async (token) => {
  const response = await fetch(
    'https://api.mycallbook.com/api/blogs/logo-list',
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  const data = await response.json();
  return data;
});

export const fetchBlogList = createAsyncThunk('blog/list', async (token) => {
  console.log('abhishek');
  const response = await fetch('https://api.mycallbook.com/api/blogs/list', {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  const data = await response.json();
  console.log(data);
  return data;
});

const initialState = {
  cat_list: [],
  cat_loading: false,
  cat_error: '',

  cat_sub_list: [],
  cat_sub_loading: false,
  cat_sub_error: '',

  sub_cat_list: [],
  sub_cat_loading: false,
  sub_cat_error: '',

  plan_list: [],
  plan_loading: false,
  plan_error: '',

  connects_list: [],
  connects_loading: false,
  connects_error: '',

  slider_img_list: [],
  slider_img_loading: false,
  slider_img_error: '',

  notification_list: [],
  unread_count: 0,
  notification_loading: false,
  notification_error: '',

  connects_list: [],
  connects_count: 0,
  connects_loading: false,
  connects_error: '',

  logo_list: {},
  logo_loading: false,
  logo_error: '',

  blog_status: false,
  blog_loading: false,

  blogs_list: [],
  b_list_loading: false,
  blog_error: '',
};

const dataSlice = createSlice({
  name: 'data',
  initialState: initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchCatList.pending, (state) => {
        state.cat_loading = true;
      })
      .addCase(fetchCatList.fulfilled, (state, action) => {
        state.cat_loading = false;
        const data = action.payload;
        state.cat_list = data.data;
      })
      .addCase(fetchCatList.rejected, (state, action) => {
        state.cat_loading = false;
        state.cat_error = action.error.message;
      });

    builder
      .addCase(fetchCatSubList.pending, (state) => {
        state.cat_sub_loading = true;
      })
      .addCase(fetchCatSubList.fulfilled, (state, action) => {
        state.cat_sub_loading = false;
        const data = action.payload;
        state.cat_sub_list = data.data;
      })
      .addCase(fetchCatSubList.rejected, (state, action) => {
        state.cat_sub_loading = false;
        state.cat_sub_error = action.error.message;
      });

    builder
      .addCase(fetchSubCatList.pending, (state) => {
        state.sub_cat_loading = true;
      })
      .addCase(fetchSubCatList.fulfilled, (state, action) => {
        state.sub_cat_loading = false;
        const data = action.payload;
        state.sub_cat_list = data.data;
      })
      .addCase(fetchSubCatList.rejected, (state, action) => {
        state.sub_cat_loading = false;
        state.sub_cat_error = action.error.message;
      });

    builder
      .addCase(fetchPlanList.pending, (state) => {
        state.plan_loading = true;
      })
      .addCase(fetchPlanList.fulfilled, (state, action) => {
        state.plan_loading = false;
        const data = action.payload;
        state.plan_list = data.data;
      })
      .addCase(fetchPlanList.rejected, (state, action) => {
        state.plan_loading = false;
        state.plan_error = action.error.message;
      });

    builder
      .addCase(fetchConnectsList.pending, (state) => {
        state.connects_loading = true;
      })
      .addCase(fetchConnectsList.fulfilled, (state, action) => {
        state.connects_loading = false;
        const data = action.payload;
        state.connects_list = data.data;
      })
      .addCase(fetchConnectsList.rejected, (state, action) => {
        state.connects_loading = false;
        state.connects_error = action.error.message;
      });

    builder
      .addCase(fetchSliderImg.pending, (state) => {
        state.slider_img_loading = true;
      })
      .addCase(fetchSliderImg.fulfilled, (state, action) => {
        state.slider_img_loading = false;
        const data = action.payload;
        state.slider_img_list = data.data;
      })
      .addCase(fetchSliderImg.rejected, (state, action) => {
        state.slider_img_loading = false;
        state.slider_img_error = action.error.message;
      });

    builder
      .addCase(fetchNotifications.pending, (state) => {
        state.notification_loading = true;
      })
      .addCase(fetchNotifications.fulfilled, (state, action) => {
        state.notification_loading = false;
        const data = action.payload;
        state.notification_list = data.data;
        state.unread_count = data.unreadCount;
      })
      .addCase(fetchNotifications.rejected, (state, action) => {
        state.notification_loading = false;
        state.notification_error = action.error.message;
      });

    builder
      .addCase(fetchConnects.pending, (state) => {
        state.connects_loading = true;
      })
      .addCase(fetchConnects.fulfilled, (state, action) => {
        state.connects_loading = false;
        const data = action.payload;
        state.connects_list = data.data[0]?.CONNECTS;
        state.connects_count = data.count;
      })
      .addCase(fetchConnects.rejected, (state, action) => {
        state.connects_loading = false;
        state.connects_error = action.error.message;
      });

    builder
      .addCase(fetchLogo.pending, (state) => {
        state.logo_loading = true;
      })
      .addCase(fetchLogo.fulfilled, (state, action) => {
        state.logo_loading = false;
        const data = action.payload;
        state.logo_list = data.data[0];
      })
      .addCase(fetchLogo.rejected, (state, action) => {
        state.logo_loading = false;
        state.connects_error = action.error.message;
      });

    builder
      .addCase(fetchBlogStatus.pending, (state) => {
        state.blog_loading = true;
      })
      .addCase(fetchBlogStatus.fulfilled, (state, action) => {
        state.blog_loading = false;
        const data = action.payload;
        state.blog_status = data.data.ACTIVE_FOR_APP;
      })
      .addCase(fetchBlogStatus.rejected, (state, action) => {
        state.blog_loading = false;
        state.blog_status = false;
      });

    builder
      .addCase(fetchBlogList.pending, (state) => {
        state.b_list_loading = true;
      })
      .addCase(fetchBlogList.fulfilled, (state, action) => {
        state.b_list_loading = false;
        const data = action.payload;
        state.blogs_list = data.data;
      })
      .addCase(fetchBlogList.rejected, (state, action) => {
        state.b_list_loading = false;
        state.blogs_list = [];
        state.blog_error = action.error.message;
      });
  },
});

export default dataSlice.reducer;
