import React from 'react';

const TermsAndConditions = () => {
  return (
    <div className="bg-gray-100 min-h-screen flex flex-col items-center p-4">
      <div className="bg-white shadow-md rounded-lg p-6 max-w-4xl w-full">
        <h1 className="text-2xl font-bold mb-4">Terms and Conditions</h1>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">1. Introduction</h2>
          <p className="text-gray-700">
            Welcome to MyCallBook. These terms and conditions outline the rules
            and regulations for the use of our website.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">2. User Agreement</h2>
          <p className="text-gray-700">
            By accessing this website we assume you accept these terms and
            conditions in full. Do not continue to use MyCallBook's website if
            you do not accept all of the terms and conditions stated on this
            page.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">3. License</h2>
          <p className="text-gray-700">
            Unless otherwise stated, MyCallBook and/or its licensors own the
            intellectual property rights for all material on MyCallBook. All
            intellectual property rights are reserved.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">4. Restrictions</h2>
          <ul className="list-disc list-inside text-gray-700">
            <li>Republish material from MyCallBook</li>
            <li>Sell, rent or sub-license material from MyCallBook</li>
            <li>Reproduce, duplicate or copy material from MyCallBook</li>
            <li>Redistribute content from MyCallBook</li>
          </ul>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">5. User Comments</h2>
          <p className="text-gray-700">
            Parts of this website offer an opportunity for users to post and
            exchange opinions, information, material, and data ('Comments').
            MyCallBook does not screen, edit, publish, or review Comments prior
            to their appearance on the website.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">6. Governing Law</h2>
          <p className="text-gray-700">
            These terms and conditions are governed by and construed in
            accordance with the laws of [Your State/Country] and you irrevocably
            submit to the exclusive jurisdiction of the courts in that State or
            location.
          </p>
        </section>

        <p className="text-gray-700">
          If you have any questions about our Terms and Conditions, please
          contact us at [Your Contact Information].
        </p>
      </div>
    </div>
  );
};

export default TermsAndConditions;
